<template>
  <div class="news-feed px-2">
    <el-row type="flex" justify="start">
      <template v-for="(article, i) in ARTICLES" :key="i">
        <news-preview :article="article" :layout="dlayout[i]" :show-category="true"></news-preview>
        <el-col v-if="i === 0" :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
          <news-lef-topt-bar :mostRecently="mostRecentlyData" :lastYear="lastYearData"></news-lef-topt-bar>
        </el-col>
        <el-col v-if="i === 4" :xs="24" :sm="24" :md="8" :lg="12" :xl="8">
          <div class="article-ad-container">
            <single-advertiser></single-advertiser>
          </div>
        </el-col>
        <el-col v-if="i === ARTICLES.length-1" :xs="24" :sm="{'span':8, 'offset': 16}" :md="{'span':8, 'offset': 16}"
                :lg="12" :xl="{'span':8, 'offset': 16}">
          <news-left-bottom-bar :lastViews="LAST_WEEK"></news-left-bottom-bar>
        </el-col>
      </template>
    </el-row>
    <ad-sense :slotter="'3900262111'" :timeout="200" :className="adClass"></ad-sense>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import NewsPreview from "@/components/feed/news/NewsPreview";
import NewsLefToptBar from "@/components/feed/news/NewsLefToptBar";
import NewsLeftBottomBar from "@/components/feed/news/NewsLeftBottomBar";
import AdSense from '@/components/feed/news/AdSense';
import SingleAdvertiser from '@/components/feed/news/SingleAdvertiser';

export default {
  name: "news-feed",
  components: {
    NewsPreview,
    NewsLefToptBar,
    NewsLeftBottomBar,
    AdSense,
    SingleAdvertiser
  },
  data() {
    return {
      adClass: 'ad-sense-container',
      //TODO -- Need optimize
      dlayout: [
        {col_lg: 16, col_xl: 16},
        {col_lg: 8, col_xl: 8, title_class: 'out', img_class: 'middle'},
        {col_lg: 8, col_xl: 8, title_class: 'out', img_class: 'middle'},
        {col_lg: 8, col_xl: 8, title_class: 'out', img_class: 'middle'},
        {col_lg: 16, col_xl: 16, container_class: ''},
        {col_lg: 8, col_xl: 8, title_class: 'sl'},
        {col_lg: 16, col_xl: 16, title_class: ''},
        {col_xl: 24, container_class: 'md-container', title_class: 't-right'},
        {col_lg: 8, col_xl: 8, title_class: 'out', img_class: 'middle'},
        {col_lg: 8, col_xl: 8, title_class: 'out', img_class: 'middle'},
        {col_lg: 8, col_xl: 8, title_class: 'out', img_class: 'middle'},
        {col_lg: 16, col_xl: 16},
      ]
    }
  },
  computed: {
    ...mapGetters('article', [
      'ARTICLES',
      'ARTICLES_PROMISE',
      'MOST_RECENTLY',
      'LAST_YEAR',
      'LAST_WEEK'
    ]),

    mostRecentlyData() {
      return _.map(this.MOST_RECENTLY, function (recently) {
        let date = new Date(recently.published_at.iso);
        let published_at = ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-1);

        return {
          title: published_at + ' ' + recently.translation.title,
          category_slug: recently.category.translation.slug,
          slug: recently.translation.slug,
          is_external: recently.is_external,
          external_link: recently.translation.external_link
        };
      });
    },

    lastYearData() {
      return _.map(this.LAST_YEAR, function (mostread) {
        let date = new Date(mostread.published_at.iso);
        let published_at = ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-1);

        return {
          title: published_at + ' ' + mostread.translation.title,
          category_slug: mostread.category.translation.slug,
          slug: mostread.translation.slug,
          is_external: mostread.is_external,
          external_link: mostread.translation.external_link
        };
      });
    },
  },
  mounted() {
    this.FETCH_ARTICLES();
  },
  methods: {
    ...mapActions('article', [
      'FETCH_ARTICLES'
    ]),
  },
}
</script>
