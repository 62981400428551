<template>
  <div>
    <div class="main-container-wrapper d-flex justify-content-center align-items-center">
      <div class="font-size-40 text-danger" v-loading="true">
        VERIFICATING...
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VerifyAccountView",
  mounted() {
    this.verify();
  },
  methods: {
    verify() {
      this.$http.get('/verify?token=' + this.$route.query.token).then((response) => {
        this.$router.push({name: 'home'}).then(() => {
          this.verificationSuccessNotification();
          this.emitter.emit("show-login-dialog", 'login');
        });
      }).catch((e) => {
        this.$router.push({name: 'home'}).then(() => {
          this.verificationErrorNotification();
        });
      });
    },
    verificationSuccessNotification() {
      this.$notify.success({
        title: this.$t('notifications.verification.success.title'),
        message: this.$t('notifications.verification.success.message'),
        position: this.notificationPosition,
        duration: this.notificationDuration,
      });
    },
    verificationErrorNotification() {
      this.$notify.error({
        title: this.$t('notifications.verification.error.title'),
        message: this.$t('notifications.verification.error.message'),
        position: this.notificationPosition,
        duration: this.notificationDuration,
      });
    }
  }
}
</script>
