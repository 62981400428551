<template>
  <div class="folder-preview">

    <div class="folder-header" ref="folder_header">
      <div class="folder-actions">
        <div class="folder-favourite">
          <div class="position-relative">
            <i class="cursor-pointer"
               :class="{'active icon-heart-2':folder.is_favourite, 'icon-heart':!folder.is_favourite}"
               @click="processFavourite"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="folder-content">

      <div class="folder-image-container">
        <img :src="folder.image_url"
             class="folder-image"
             :style="{'max-width':imageWidth+`px`}"
             @click="goFolder">
      </div>

      <div class="mt-1">
        <div class="store-title font-weight-bold w-100 mt-0">
          {{ folder.store.translation.title }}
        </div>

        <div class="expire-text w-100">
          <span>{{ dateFrom }}</span>
          <span>-</span>
          <span>{{ dateTo }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {needAuth} from "@/mixins/auth/needAuth";

export default {
  name: "folder-preview",
  components: {},
  mixins: [needAuth],
  props: {
    folder: Object,
    vuexModule: {
      type: String,
      default: 'folder',
    },
  },
  data() {
    return {
      imageWidth: 0,
      targetTop: 0,
    }
  },
  computed: {
    dateFrom() {
      let date = new Date(this.folder.valid_from);
      return ('0' + date.getDate()).slice(-2) + '/'
          + ('0' + (date.getMonth() + 1)).slice(-2) + '/’'
          + ('' + date.getFullYear()).slice(-2);
    },
    dateTo() {
      let date = new Date(this.folder.valid_to);
      return ('0' + date.getDate()).slice(-2) + '/'
          + ('0' + (date.getMonth() + 1)).slice(-2) + '/’'
          + ('' + date.getFullYear()).slice(-2);
    },
  },
  mounted() {
    let thisElem = this;
    new ResizeObserver(function () {
      thisElem.calculateHeight();
    }).observe(this.$refs.folder_header);
  },
  methods: {
    calculateHeight() {
      let thisElem = this;

      if (!thisElem.$refs.folder_header) {
        return 0;
      }
      thisElem.imageWidth = thisElem.$refs.folder_header.clientWidth - 20;
    },
    processFavourite() {
      if (this.triggerLoginDialog()) {
        return;
      }
      if (this.folder.is_favourite) {
        this.$store.dispatch(this.vuexModule + '/REMOVE_FAVORITE', this.folder.id)
      } else {
        this.$store.dispatch(this.vuexModule + '/SET_FAVORITE', this.folder.id)
      }
    },
    processHit(value) {
      if (this.triggerLoginDialog()) {
        return;
      }
      if (value) {
        this.$store.dispatch(this.vuexModule + '/SET_HIT', this.folder.id)
      } else {
        this.$store.dispatch(this.vuexModule + '/SET_DISLIKE', this.folder.id)
      }
    },
    goFolder() {
      this.routerPush({
        name: 'folder.view',
        params: {
          storeSlug: this.folder.store.translation.route,
          folderDate: this.folder.valid_from,
        },
        query: {
          id: this.folder.id
        }
      });
    }
  }
}
</script>
