<template>
  <el-popover
      placement="bottom"
      trigger="click"
      popper-class="p-0"
      content="this is content, this is content, this is content">
    <template #default>
      <picker v-if="showPicker" :data="emojiIndex" @select="selectEmoji"/>
    </template>
    <template #reference>
      <div @mouseover="showPicker = true">
        <slot name="click-to-show">
          <i class="emoji-btn icon-emoji font-size-20 cursor-pointer"></i>
        </slot>
      </div>
    </template>
  </el-popover>
</template>

<script>
import data from 'emoji-mart-vue-fast/data/all.json';
import "emoji-mart-vue-fast/css/emoji-mart.css";
import {Picker, EmojiIndex} from "emoji-mart-vue-fast/src";

export default {
  name: "emoji-picker",
  components: {Picker},
  emits: ['select'],
  data() {
    return {
      showPicker: false,
      emojiIndex: new EmojiIndex(data)
    };
  },

  methods: {
    selectEmoji(emoji) {
      this.$emit('select', emoji);
    },
  }
}
</script>
