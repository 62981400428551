<template>
  <folder-pager></folder-pager>
</template>

<script>
import FolderPager from "@/components/feed/folder/FolderPager";
import {mapActions} from "vuex";

export default {
  name: "FolderView",
  components: {FolderPager},
  beforeMount() {
    this.RESET_PROMOS();
  },
  methods: {
    ...mapActions('promo', [
      'RESET_PROMOS',
    ]),
  }
}
</script>
