<template>
  <base-filter ref="filter"
               :filter-label="$t('filters.stores')"
               :filter-options="filterOptions"
               :default-checked="[]"
               filter-key="stores_filter"
               @filter="filter">
  </base-filter>
</template>

<script>
import BaseFilter from "@/components/left_bar/filters/BaseFilter";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "stores-filter",
  components: {BaseFilter},
  computed: {
    ...mapGetters('promo', [
      'PROMOS_STORES',
      'PROMOS_TOTAL',
    ]),

    ...mapGetters('store', [
      'STORES',
    ]),

    ...mapGetters('folder-popular', [
      'FOLDERS_STORES',
    ]),

    filterOptions() {
      let optionsSource = this.PROMOS_STORES;
      if (this.PROMOS_TOTAL === 10000 && this.STORES.length) {
        optionsSource = this.STORES;
      }
      if (this.$route.name === 'folders') {
        optionsSource = this.FOLDERS_STORES;
      }
      let resultOptions = {};
      _.forEach(optionsSource, function (store) {
        resultOptions[store.id] = store.translation.title;
      });
      return resultOptions;
    }
  },
  mounted() {
    let defaultFilterValue = this.$route.query['stores_filter[]']
    if (!_.isEmpty(defaultFilterValue)) {
      this.$refs.filter.setCheckedList(_.castArray(defaultFilterValue));
    }
    if (!this.STORES.length) {
      this.FETCH_STORES();
    }
  },
  methods: {
    ...mapActions('filter', [
      'SET_FILTER_VALUE',
    ]),
    ...mapActions('store', [
      'FETCH_STORES',
    ]),

    filter(checked) {
      this.SET_FILTER_VALUE({stores_filter: checked});
    },
  }
}
</script>
