import store from '@/store';

export default async (to) => {
    await store.dispatch('product/RESET_PRODUCTS');
    await store.dispatch('product/FETCH_CURRENT_PRODUCT', to.params.productSlug);

    if (_.isNil(store.getters['product/CURRENT_PRODUCT'])) {
        return {name: 'home'};
    }
};
