<template>
  <div>
    <div class="w-100 login-label" v-t="'auth.register_chose_of'"></div>

    <div class="class-register-icons">
      <i class="icon-Facebook cursor-pointer" @click="externalRegister('facebook')"></i>
      <div class="font-size-20 p-2" v-t="'auth.or'"></div>
      <i class="icon-Google-Chrome cursor-pointer" @click="externalRegister('google')"><span class="path1"></span><span class="path2"></span><span
          class="path3"></span><span class="path4"></span><span class="path5"></span></i>
      <div class="font-size-20 p-2" v-t="'auth.or'"></div>
      <i class="icon-Apple cursor-pointer" @click="externalRegister('apple')"></i>
    </div>

    <div class="w-100 login-label" v-t="'auth.or_register_by_email'"></div>

    <el-form :model="form"
             ref="form"
             :rules="rules">

      <el-form-item prop="email">
        <el-input
            class="rounded-input"
            :placeholder="$t('auth.email')"
            :size="inputsSize"
            v-model="form.email">
        </el-input>
      </el-form-item>

      <el-row>
        <el-col :span="24">
          <el-form-item prop="fname">
            <el-input
                class="rounded-input"
                v-model="form.fname"
                :size="inputsSize"
                :placeholder="'Nombre'">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item prop="lname">
            <el-input
                class="rounded-input me-2"
                v-model="form.lname"
                :size="inputsSize"
                :placeholder="$t('auth.lname')">
            </el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item prop="sname">
            <el-input
                class="rounded-input ms-2"
                v-model="form.sname"
                :size="inputsSize"
                :placeholder="$t('auth.sname')">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item prop="password">
            <el-input
                class="rounded-input me-2"
                :placeholder="$t('auth.password')"
                :size="inputsSize"
                v-model="form.password"
                show-password>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="password_confirmation">
            <el-input
                class="rounded-input ms-2"
                :placeholder="$t('auth.confirm_password')"
                :size="inputsSize"
                v-model="form.password_confirmation"
                show-password>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <div class="text-center mb-1">
        <span v-t="'auth.upload_avatar'"></span>:
      </div>
      <div class="d-flex align-items-center justify-content-center mb-2">
        <el-upload
            class="avatar-uploader"
            accept="image/png,image/jpg,image/jpeg"
            :action="uploadImageUrl"
            :headers="{'Accept':'application/json'}"
            :on-remove="handleImageRemove"
            :on-error="handleImageError"
            :on-change="handleImageChange"
            :show-file-list="false"
        >
          <img v-if="form.avatar" :src="avatarPreview" class="avatar">
          <div v-else class="avatar-uploader-icon">
            <i class="el-icon-s-custom"></i>
          </div>
        </el-upload>
      </div>

      <el-button
        type="primary"
        class="w-100"
        @click="validate"
      >
        <span v-t="'auth.register_step.next_step'"></span>
      </el-button>
    </el-form>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {validator} from "@/mixins/validator";
import {facebook} from "@/mixins/auth/facebook";
import {socialLogin} from "@/mixins/auth/socialLogin";
import {google} from "@/mixins/auth/google";
import {apple} from "@/mixins/auth/apple";
import {webviewLogin} from "@/mixins/auth/webviewLogin";
import validateStep from "@/mixins/auth/validateStep";

export default {
  name: "register-step",
  mixins: [validator, validateStep, facebook, google, apple, socialLogin, webviewLogin],
  props: ['tryRegisterData'],
  data() {
    return {
      step: 1,
      webviewEventName: 'register',
      avatarPreview: '',
      form: {
        email: '',
        fname: '',
        lname: '',
        sname: '',
        password: '',
        password_confirmation: '',
        avatar: ''
      },
      rules: {
        email: [
          {validator: this.backendValidator}
        ],
        fname: [
          {validator: this.backendValidator}
        ],
        lname: [
          {validator: this.backendValidator}
        ],
        password: [
          {validator: this.backendValidator}
        ],
        avatar: [
          {validator: this.backendValidator}
        ]
      }
    }
  },
  computed: {
    uploadImageUrl() {
      return process.env.VUE_APP_API_URL + 'register/upload-image';
    }
  },
  mounted() {
    if (_.get(this.tryRegisterData, 'provider') !== undefined && _.get(this.tryRegisterData, 'options') !== undefined) {
      this.checkUser(this.tryRegisterData.provider, this.tryRegisterData.options);
    }
  },
  methods: {
    handleImageError(err, file, files) {
      const e = JSON.parse(err.message);

      this.$notify.error({
        title: this.$t('notifications.default.title'),
        message: _.get(e, 'errors.file.0', this.$t('notifications.default.message')),
        position: this.notificationPosition,
        duration: this.notificationDuration,
      });
    },

    handleImageChange(file) {
      this.avatarPreview = URL.createObjectURL(file.raw);
      this.form.avatar = '';

      if (_.get(file, 'response.file')) {
        this.form.avatar = _.get(file, 'response.file');
      }
    },

    handleImageRemove() {
      this.avatarPreview = '';
      this.form.avatar = '';
    },

    externalRegister(provider) {
      switch (provider) {
        case 'apple':
          this.appleRegister(); // from apple mixin
          break;
        case 'facebook':
          this.facebookRegister(); // from facebook mixin
          break;
        case 'google':
          this.googleRegister(); // from google mixin
          break;
      }
    }
  }
}
</script>
