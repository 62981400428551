<template>
  <el-dialog
      title="Settings"
      v-model="show"
      :show-close="false"
      :close-on-click-modal="true"
      :close-on-press-escape="true"
      :destroy-on-close="true"
      :lock-scroll="false"
      width="100vw"
      class="settings-dialog danger-header-dialog"
      @close="closeDialog"
      center>

    <el-form :model="form"
             ref="form"
             :rules="rules"
             label-position="top">

      <el-form-item prop="receive_replies_on_email">
        <el-checkbox v-model="form.receive_replies_on_email" class="big"
                     :label="$t('menu.user_settings.subscribe_checkbox')">
        </el-checkbox>
      </el-form-item>

     <el-form-item v-if="false"> <!-- need verification callback-->
        <el-checkbox v-model="changeEmailCheckbox" class="big"
                     :label="$t('menu.user_settings.change_email_checkbox')">
        </el-checkbox>
      </el-form-item>

      <el-form-item v-if="!hasSocialAccount">
        <el-checkbox v-model="changePasswordCheckbox" class="big"
                     :label="$t('menu.user_settings.change_password_checkbox')">
        </el-checkbox>
      </el-form-item>

      <el-form-item v-if="changeEmailCheckbox">
        <el-form-item prop="email" :label="$t('menu.user_settings.fields.email.label')">
          <el-input class="rounded-input" type="email"
                    v-model="form.email"
                    autocomplete="off"></el-input>
        </el-form-item>
      </el-form-item>

      <template v-if="changePasswordCheckbox">
        <el-form-item prop="password" :label="$t('menu.user_settings.fields.password.label')">
          <el-input class="rounded-input" type="password"
                    v-model="form.password"
                    autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item prop="password_confirmation" :label="$t('menu.user_settings.fields.password_confirmation.label')">
          <el-input class="rounded-input" type="password"
                    v-model="form.password_confirmation"
                    autocomplete="off"></el-input>
        </el-form-item>
      </template>

      <el-form-item prop="gender" :label="$t('menu.user_settings.fields.gender.label')">
        <el-select v-model="form.gender" class="rounded-select">
          <el-option
              :key="1"
              :label="$t('menu.user_settings.fields.gender.options.male')"
              :value="1">
          </el-option>
          <el-option
              :key="0"
              :label="$t('menu.user_settings.fields.gender.options.female')"
              :value="0">
          </el-option>
        </el-select>
      </el-form-item>

      <birthday-picker :date="form.birthday" @input="birthdayChanged"></birthday-picker>

      <el-form-item prop="postal_code" :label="$t('menu.user_settings.fields.postal_code.label')">
        <el-input class="rounded-input"
                  v-model="form.postal_code"
                  :placeholder="$t('menu.user_settings.fields.postal_code.placeholder')"></el-input>
      </el-form-item>

      <template v-if="!hasSocialAccount">
        <div class="text-center mb-1">
          <span v-t="'auth.upload_avatar'"></span>:
        </div>
        <div class="d-flex align-items-center justify-content-center mb-2">
          <el-upload
              class="avatar-uploader"
              accept="image/png,image/jpg,image/jpeg"
              :action="uploadImageUrl"
              :headers="{'Accept':'application/json'}"
              :on-remove="handleImageRemove"
              :on-error="handleImageError"
              :on-change="handleImageChange"
              :show-file-list="false"
          >
            <img v-if="form.avatar" :src="avatarPreview" class="avatar">
            <div v-else class="avatar-uploader-icon">
              <i class="el-icon-s-custom"></i>
            </div>
          </el-upload>
        </div>
      </template>

      <el-button type="primary"
                 class="w-100"
                 @click="submit">
        <span v-t="'menu.user_settings.submit_settings_btn'"></span>
      </el-button>
    </el-form>

    <template v-slot:header>
      <div>
        <i class="icon-arrow_back float-start cursor-pointer" @click="closeDialog"></i>
        <span v-t="'menu.user_settings.header'"></span>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import {validator} from "@/mixins/validator";
import {mapActions, mapGetters} from "vuex";
import BirthdayPicker from "@/components/header/auth/Fields/BirthdayPicker.vue";

export default {
  name: "settings-dialog",
  components: {BirthdayPicker},
  props: ['showDialog'],
  mixins: [validator],
  data() {
    return {
      show: this.showDialog,
      changeEmailCheckbox: false,
      changePasswordCheckbox: false,
      avatarPreview: '',
      hasSocialAccount: true,
      form: {
        avatar: null,
        email: null,
        password: null,
        password_confirmation: null,
        gender: 0,
        birthday: '',
        postal_code: '',
        receive_replies_on_email: false,
      },
      rules: {
        email: [{required: true, validator: this.backendValidator}],
        password: [{required: true, validator: this.backendValidator}],
        password_confirmation: [{required: true, validator: this.backendValidator}],
        gender: [{required: true, validator: this.backendValidator}],
        birthday: [{required: true, validator: this.backendValidator}],
        postal_code: [{required: true, validator: this.backendValidator}],
        receive_replies_on_email: [{validator: this.backendValidator}],
      }
    }
  },
  computed: {
    ...mapGetters('auth', [
      'USER',
    ]),

    uploadImageUrl() {
      return process.env.VUE_APP_API_URL + 'register/upload-image';
    }
  },
  methods: {
    ...mapActions('auth', [
      'UPDATE_SETTINGS',
    ]),
    ...mapActions('loader', [
      'SET_LOADER',
    ]),
    closeDialog() {
      this.$emit('update:show-dialog', false);
    },
    birthdayChanged(value) {
      this.form.birthday = value;
    },
    submit() {
      this.SET_LOADER(true);
      this.UPDATE_SETTINGS(this.form).then((response) => {
        this.errors = {};
      }).catch((e) => {
        this.errors = e.response.data.errors;
      }).then(() => {
        this.SET_LOADER(false);
        this.$refs['form'].validate((valid) => {
          if (valid) {

            this.$notify.success({
              title: this.$t('notifications.settings_updated.title'),
              message: this.$t('notifications.settings_updated.message'),
              position: this.notificationPosition,
              duration: this.notificationDuration
            });
          }
          return false;
        });
      });
    },

    handleImageError(err, file, files) {
      const e = JSON.parse(err.message);

      this.$notify.error({
        title: this.$t('notifications.default.title'),
        message: _.get(e, 'errors.file.0', this.$t('notifications.default.message')),
        position: this.notificationPosition,
        duration: this.notificationDuration,
      });
    },

    handleImageChange(file) {
      console.log(file);
      console.log(URL.createObjectURL(file.raw));
      this.avatarPreview = URL.createObjectURL(file.raw);
      this.form.avatar = '';

      if (_.get(file, 'response.file')) {
        this.form.avatar = _.get(file, 'response.file');
      }
    },

    handleImageRemove() {
      this.avatarPreview = '';
      this.form.avatar = '';
    }
  },
  watch: {
    showDialog(newShowDialog) {
      this.show = newShowDialog;
    },
    USER(value) {
      this.hasSocialAccount = _.size(value.social_accounts) > 0;
      this.form.gender = value.gender;
      this.form.postal_code = value.postal_code;
      this.form.receive_replies_on_email = Boolean(value.receive_replies_on_email);
      this.form.birthday = value.birthday;
    },
    changeEmailCheckbox(value) {
      if (value) {
        this.form.email = this.USER.email;
      } else {
        this.form.email = null;
      }
    },
    changePasswordCheckbox(value) {
      if (!value) {
        this.form.password = null;
        this.form.password_confirmation = null;
      }
    }
  },
}
</script>
