import store from "@/store";

export const webviewLogin = {
    mounted() {
        switch (this.webviewEventName){
            case "login":
                if (this.webview === 'android') {
                    document.addEventListener("message", this.handleWebviewLogin);
                } else {
                    window.addEventListener("message", this.handleWebviewLogin);
                }
                break;
            case "register":
                if (this.webview === 'android') {
                    document.addEventListener("message", this.handleWebviewRegister);
                } else {
                    window.addEventListener("message", this.handleWebviewRegister);
                }
                break;
        }
    },
    unmounted() {
        switch (this.webviewEventName){
            case "login":
                if (this.webview === 'android') {
                    document.removeEventListener("message", this.handleWebviewLogin);
                } else {
                    window.removeEventListener("message", this.handleWebviewLogin);
                }
                break;
            case "register":
                if (this.webview === 'android') {
                    document.removeEventListener("message", this.handleWebviewRegister);
                } else {
                    window.removeEventListener("message", this.handleWebviewRegister);
                }
                break;
        }
    },
    methods: {
        handleWebviewLogin(event) {
            if (typeof event.data == 'string') {
                let data = {};
                try {
                    data = JSON.parse(event.data);
                } catch (e) {
                    return false;
                }

                if (data.actionType === 'fbLogin' && _.get(data, 'data.accessToken')) {
                    this.socialLogin('facebook', {access_token: data.data.accessToken});
                }

                if (data.actionType === 'googleLogin' && _.get(data, 'data.accessToken')) {
                    this.socialLogin("google", {googleAuthToken: data.data.accessToken});
                }
            }
        },
        handleWebviewRegister(event) {
            if (typeof event.data == 'string') {
                let data = {};
                try {
                    data = JSON.parse(event.data);
                } catch (e) {
                    return false;
                }

                if (data.actionType === 'fbLogin' && _.get(data, 'data.accessToken')) {
                    this.checkUser('facebook', {access_token: data.data.accessToken});
                }

                if (data.actionType === 'googleLogin' && _.get(data, 'data.accessToken')) {
                    this.checkUser("google", {googleAuthToken: data.data.accessToken});
                }
            }
        },
    }
}
