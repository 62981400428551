<template>
  <div>
    <div class="comment-form mt-3">
      <div class="comment-form-avatar-div">
        <el-avatar :src="avatarImage" shape="circle"
                   class="comment-form-avatar"></el-avatar>
      </div>

      <div class="position-relative">
        <div class="actions-container">
          <div class="emoji-image-btn">
            <i class="icon-emoji font-size-20" v-if="GUEST" @click="triggerLoginDialog"></i>

            <emoji-picker @select="selectEmoji($event)" v-else>
              <template v-slot:click-to-show>
                <i class="icon-emoji font-size-20"></i>
              </template>
            </emoji-picker>
          </div>
          <div class="upload-image-btn">
            <i class="icon-camera font-size-26" @click="uploadImage"></i>
          </div>
        </div>
      </div>

      <at-ta :members="atMembers" name-key="name" @at="at" :filterMatch="atFilterMatch">
        <template #item="s">
          <img :src="s.item.avatar_url">
          <span v-text="s.item.full_name" />
        </template>

        <el-input
            class="rounded-textarea comment-input"
            type="textarea"
            v-model="content"
            placeholder="Schrijf een reactie"
            :autosize="{ minRows: 1, maxRows: 5}"
            ref="content"
            @blur="inputFocused = false"
            @focus="inputFocusEvent"
            @paste="pasteEvent">
        </el-input>
      </at-ta>

      <div class="comment-submit-btn-div">
        <el-button type="primary" icon="icon-arrow-4" class="comment-submit-btn" circle
                   @click="submitComment"></el-button>
      </div>
    </div>

    <el-upload
        ref="upload"
        class="upload-image-container"
        :action="imageAction"
        :headers="{'Accept':'application/json','Authorization':'Bearer ' + AUTH_TOKEN}"
        :on-remove="handleImageRemove"
        :on-error="handleImageError"
        :on-change="handleImageOnChange"
        :limit="1"
        list-type="picture"
        :file-list="fileList">
      <span ref="upload_element"></span>
    </el-upload>
  </div>
</template>

<script>
import AtTa from 'vue-at/dist/vue-at-textarea'
import {mapGetters} from "vuex";
import EmojiPicker from "@/components/feed/post/EmojiPicker";
import {needAuth} from "@/mixins/auth/needAuth";

export default {
  name: "comment-form",
  components: {EmojiPicker, AtTa},
  mixins: [needAuth],
  emits: ["submit"],
  data() {
    return {
      imageAction: process.env.VUE_APP_API_URL + 'comments/upload-image',

      content: '',
      image: '',

      inputFocused: false,
      fileList: [],

      atMembers: [],
      inputDebounce: _.debounce((q) => {
        this.axios.get('/users/search?q=' + q).then((response) => {
          this.atMembers = response.data.data;
        });
      }, 500),
    }
  },
  computed: {
    ...mapGetters('auth', [
      'USER',
      'GUEST',
      'AUTH_TOKEN',
    ]),

    avatarImage() {
      if (this.GUEST) {
        return require('@/assets/images/avatar.jpg');
      }
      return this.USER.avatar_url;
    }
  },
  methods: {
    at(q) {
      this.inputDebounce(q);
    },
    atFilterMatch(name, chunk, at) {
      name = name.toLowerCase().replaceAll(' ', '.');
      chunk = chunk.toLowerCase().replaceAll(' ', '.');
      // match at lower-case
      return name.indexOf(chunk) > -1
    },
    submitComment() {
      this.webshopLoginDialog();
      if (this.triggerLoginDialog()) {
        return;
      }
      this.$emit('submit', {content: this.content, image: this.image});
      this.resetForm();
    },

    handleImageError(err, file, files) {
      let e = JSON.parse(err.message);

      this.$notify.error({
        title: this.$t('notifications.default.title'),
        message: _.get(e, 'errors.file.0', this.$t('notifications.default.message')),
        position: this.notificationPosition,
        duration: this.notificationDuration,
      });
    },

    handleImageOnChange(file, files) {
      this.image = '';

      if (_.get(file, 'response.file')) {
        this.image = _.get(file, 'response.file');
      }
    },

    handleImageRemove() {
      this.image = '';
      this.fileList = [];
    },

    uploadImage() {
      this.webshopLoginDialog();
      if (this.triggerLoginDialog()) {
        return;
      }
      this.$refs.upload_element.click();
    },

    resetForm() {
      this.content = '';
      this.image = '';
      this.fileList = [];
      this.$refs.upload.clearFiles();
    },

    selectEmoji(emoji) {
      const insertText = emoji.native;
      if (!insertText.length) return;

      const textarea = this.$refs.content.$refs.textarea;
      const sentence = textarea.value;
      const len = sentence.length;
      let pos = textarea.selectionStart;
      if (pos === undefined) {
        pos = 0;
      }

      const before = sentence.substr(0, pos);
      const after = sentence.substr(pos, len);

      this.content = before + insertText + after;

      this.$nextTick().then(() => {
        textarea.selectionStart = pos + insertText.length;
      });
    },

    pasteEvent(event) {
      this.webshopLoginDialog();
      if (this.triggerLoginDialog()) {
        return;
      }
      let items = event.clipboardData.items;
      for (let index in items) {
        let item = items[index];
        if (item.kind === 'file') {
          let imageFromClipboard = item.getAsFile();
          let formData = new FormData();
          formData.append("file", imageFromClipboard);

          axios.post('/comments/upload-image', formData).then((response) => {
            this.image = response.data.file;
            this.fileList = [{url: URL.createObjectURL(imageFromClipboard)}];
          }).catch((e) => {
            switch (e.response.status) {
              case 401:
                this.triggerLoginDialog();
                break;
              default:
                break;
            }
          });
          break;
        }
      }
    },

    inputFocusEvent() {
      this.webshopLoginDialog();
      if (this.triggerLoginDialog()) {
        this.$refs.content.blur();
        return;
      }
      this.inputFocused = true;
    }
  }
}
</script>
