<template>
  <div class="folders-feed">
    <h1 class="text-danger font-size-26 my-3" v-t="'folder.folders_header'"></h1>

    <div class="me-3">
      <div class="mb-3" v-if="POPULAR_FOLDERS.length || POPULAR_FOLDERS_PROMISE">
        <folders-carousel :title="$t('folder.popular_folders')"
                          :folders="POPULAR_FOLDERS"
                          :is-loading="!!POPULAR_FOLDERS_PROMISE"
                          vuex-module="folder-popular"
                          @load="loadMore('folder-popular')"
                          child-class="bg-folder-light"></folders-carousel>
      </div>
      <div class="mb-3" v-if="FOOD_FOLDERS.length || FOOD_FOLDERS_PROMISE">
        <folders-carousel :title="$t('folder.food_folders')"
                          :folders="FOOD_FOLDERS"
                          :is-loading="!!FOOD_FOLDERS_PROMISE"
                          vuex-module="folder-food"
                          @load="loadMore('folder-food')"
                          child-class="bg-folder-secondary"></folders-carousel>
      </div>
      <div class="mb-3" v-if="GARDEN_FOLDERS.length || GARDEN_FOLDERS_PROMISE">
        <folders-carousel :title="$t('folder.garden_folders')"
                          :folders="GARDEN_FOLDERS"
                          :is-loading="!!GARDEN_FOLDERS_PROMISE"
                          vuex-module="folder-garden"
                          @load="loadMore('folder-garden')"
                          child-class="bg-folder-secondary"></folders-carousel>
      </div>
      <div class="mb-3" v-if="MEDIA_FOLDERS.length || MEDIA_FOLDERS_PROMISE">
        <folders-carousel :title="$t('folder.media_folders')"
                          :folders="MEDIA_FOLDERS"
                          :is-loading="!!MEDIA_FOLDERS_PROMISE"
                          vuex-module="folder-media"
                          @load="loadMore('folder-media')"
                          child-class="bg-folder-secondary"></folders-carousel>
      </div>
      <div class="mb-3" v-if="FOLDERS.length || FOLDERS_PROMISE">
        <folders-carousel :title="$t('folder.filtered_folders')"
                          :folders="FOLDERS"
                          :is-loading="!!FOLDERS_PROMISE"
                          @load="loadMore('folder')"
                          child-class="bg-folder-secondary"></folders-carousel>
      </div>
    </div>

    <div class="text-danger font-size-26 my-3" v-t="'folder.view_folders_for_stores'"></div>

    <div class="d-flex justify-content-center align-items-center flex-wrap">
      <div class="store-image-item" v-for="(store,i) in storesWithFolders" :key="i">
        <router-localized-link :to="{name: 'folders.store', params: {storeSlug: String(store.translation.route)}}">
          <img :src="store.image_url" alt="" :title="store.translation.title">
        </router-localized-link>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import FoldersCarousel from "@/components/feed/folder/FoldersCarousel";
import RouterLocalizedLink from "@/components/common/RouterLocalizedLink";

export default {
  name: "folders-feed",
  components: {RouterLocalizedLink, FoldersCarousel},
  data() {
    return {
      fetchOptions: {
        categories: [],
        stores: [],
      }
    }
  },
  computed: {
    ...mapGetters('folder', {
      'FOLDERS': 'FOLDERS',
      'FOLDERS_PROMISE': 'FOLDERS_PROMISE',
    }),
    ...mapGetters('folder-popular', {
      'POPULAR_FOLDERS': 'FOLDERS',
      'POPULAR_FOLDERS_PROMISE': 'FOLDERS_PROMISE',
    }),
    ...mapGetters('folder-food', {
      'FOOD_FOLDERS': 'FOLDERS',
      'FOOD_FOLDERS_PROMISE': 'FOLDERS_PROMISE',
    }),
    ...mapGetters('folder-garden', {
      'GARDEN_FOLDERS': 'FOLDERS',
      'GARDEN_FOLDERS_PROMISE': 'FOLDERS_PROMISE',
    }),
    ...mapGetters('folder-media', {
      'MEDIA_FOLDERS': 'FOLDERS',
      'MEDIA_FOLDERS_PROMISE': 'FOLDERS_PROMISE',
    }),
    ...mapGetters('filter', [
      'FILTERS_VALUES',
    ]),
    ...mapGetters('store', [
      'STORES_WITH_FOLDERS',
    ]),

    storesWithFolders() {
      return _.sortBy(this.STORES_WITH_FOLDERS, 'translation.title');
    }
  },
  beforeMount() {
    this.resetFolders();
    this.prepareFetchOptions();
  },
  mounted() {
    this.FETCH_STORES();
    this.loadFoldersData();
  },
  methods: {
    ...mapActions('store', [
      'FETCH_STORES',
    ]),
    prepareFetchOptions() {
      this.fetchOptions.categories = this.FILTERS_VALUES.categories_filter;
      this.fetchOptions.stores = this.FILTERS_VALUES.stores_filter;
    },
    async loadFoldersData() {
      if (this.fetchOptions.categories.length || this.fetchOptions.stores.length) {
        await this.loadFoldersDataItem('folder');
      } else {
        await this.loadFoldersDataItem('folder-popular');
        await this.loadFoldersDataItem('folder-food');
        await this.loadFoldersDataItem('folder-garden');
        await this.loadFoldersDataItem('folder-media');
      }
    },
    loadFoldersDataItem(module) {
      let fetchOptions = _.assign({}, this.fetchOptions);
      let moduleParams = {
        'folder': {},
        'folder-popular': {
          folder_client_types: ['1'],
          include_filters: 1,
        },
        'folder-food': {
          categories: ['3', '15'],
        },
        'folder-garden': {
          categories: ['8'],
        },
        'folder-media': {
          categories: ['4'],
        },
      };
      let params = moduleParams[module];
      if (params.categories !== undefined) {
        if (fetchOptions.categories.length && _.difference(fetchOptions.categories, params.categories).length !== 0) {
          return Promise.resolve();
        }
        _.unset(fetchOptions, 'categories');
      }
      params = _.assign(params, fetchOptions);

      return this.$store.dispatch(module + '/FETCH_FOLDERS', params);
    },
    loadMore(module) {
      this.loadFoldersDataItem(module);
    },
    resetFolders() {
      this.$store.dispatch('folder/RESET_FOLDERS');
      this.$store.dispatch('folder-popular/RESET_FOLDERS');
      this.$store.dispatch('folder-food/RESET_FOLDERS');
      this.$store.dispatch('folder-garden/RESET_FOLDERS');
      this.$store.dispatch('folder-media/RESET_FOLDERS');
    }
  },
  watch: {
    FILTERS_VALUES: {
      // the callback will be called immediately after the start of the observation
      deep: true,
      handler(newVal, oldVal) {
        this.prepareFetchOptions();
        this.resetFolders();
        this.loadFoldersData();
      }
    }
  }
}
</script>
