<template>
  <el-form-item prop="birthday" class="birthday-control-wrapper" :label="$t('auth.register_step.birthday')">
    <el-select class="day-picker rounded-select me-1"
               v-model="birthdayDay"
               :placeholder="$t('auth.register_step.day')"
               @change="saveFormatted">
      <el-option
          v-for="day in 31"
          :key="day"
          :label="day"
          :value="day">
      </el-option>
    </el-select>

    <el-date-picker
        class="month-picker rounded-select me-1"
        popper-class="month-picker-dropdown"
        v-model="birthdayMonth"
        type="month"
        format="MMM"
        :placeholder="$t('auth.register_step.month')"
        @change="saveFormatted">
    </el-date-picker>

    <el-select class="year-picker rounded-select"
               v-model="birthdayYear"
               :placeholder="$t('auth.register_step.year')" @change="saveFormatted">
      <el-option
          v-for="i in 80"
          :key="currentYear - i + 1"
          :label="currentYear - i + 1"
          :value="currentYear - i + 1">
      </el-option>
    </el-select>
  </el-form-item>
</template>

<script>
export default {
  name: "birthday-picker",

  props: ['date'],
  data() {
    return {
      currentYear: '',
      birthdayDay: '',
      birthdayMonth: '',
      birthdayYear: '',
    }
  },
  mounted() {
    this.currentYear = (new Date()).getFullYear();
    this.updateBirthday(this.date);
  },
  methods: {
    updateBirthday(value) {
      if (!value) {
        return;
      }
      let birthday = new Date(value);
      this.birthdayYear = birthday.getFullYear();
      this.birthdayDay = birthday.getDate();
      this.birthdayMonth = birthday;
      this.saveFormatted();
    },
    saveFormatted() {
      if (!this.birthdayYear || !this.birthdayMonth || !this.birthdayDay) {
        return;
      }
      this.$emit('input', [this.birthdayYear, this.birthdayMonth.getMonth() + 1, this.birthdayDay].join('-'))
    }
  },
  watch: {
    date: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(newVal, oldVal) {
        this.updateBirthday(newVal);
      }
    }
  }
}
</script>

<style>
.day-picker,
.year-picker,
.month-picker {
  max-width: 90px !important;
}

.month-picker .el-input__prefix {
  display: none;
}

.month-picker input {
  padding: 20px !important;
}

.month-picker-dropdown .el-date-picker__header {
  display: none;
}
</style>
