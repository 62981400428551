<template>
  <base-filter ref="filter"
               :filter-label="$t('filters.categories')"
               :filter-options="filterOptions"
               :default-checked="[]"
               filter-key="categories_filter"
               @filter="filter">
  </base-filter>
</template>

<script>
import BaseFilter from "@/components/left_bar/filters/BaseFilter";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "categories-filter",
  components: {BaseFilter},
  computed: {
    ...mapGetters('promo', [
      'PROMOS_CATEGORIES',
    ]),
    ...mapGetters('folder-popular', [
      'FOLDERS_CATEGORIES',
    ]),
    filterOptions() {
      let optionsSource = this.PROMOS_CATEGORIES;
      if (this.$route.name === 'folders') {
        optionsSource = this.FOLDERS_CATEGORIES;
      }

      let resultOptions = {};
      _.forEach(optionsSource, function (category) {
        resultOptions[category.id] = category.translation.title;
      });
      return resultOptions;
    }
  },
  mounted() {
    let defaultFilterValue = this.$route.query['categories_filter[]']
    if (!_.isEmpty(defaultFilterValue)) {
      this.$refs.filter.setCheckedList(_.castArray(defaultFilterValue));
    }
  },
  methods: {
    ...mapActions('filter', [
      'SET_FILTER_VALUE',
    ]),

    filter(checked) {
      this.SET_FILTER_VALUE({categories_filter: checked});
    },
  }
}
</script>
