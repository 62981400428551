import { mapActions } from "vuex";

export default {
    /**
     * Methods
     */
    methods: {
        ...mapActions('loader', [
            'SET_LOADER',
        ]),

        /**
         * Validate
         */
        validate() {
            this.SET_LOADER(true);

            if (this.step === 1 && cookies.get('campaign_tag')) {
                this.form.campaign_tag = cookies.get('campaign_tag');
            }

            this.$http.post(`/register/step-${this.step}`, this.form)
                .then((response) => {  this.errors = {}; })
                .catch((e) => {
                    switch (e.response.status) {
                        case 422:
                            this.errors = _.assign({}, e.response.data.errors);
                            break;

                        default:
                            this.errors.password = [e.response.data.error];
                            break;
                    }
                })
                .then(() => {
                    this.SET_LOADER(false);
                    this.$refs.form.validate((valid) => {
                        if (valid) {
                            return this.$emit('validated', this.form)
                        }
                    });
                });
        }
    }
}
