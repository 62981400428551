const getDefaultState = () => {
    return {
        articles: {
            params: {
                page: 1,
                per_page: 12,
            },
            promise: null,
            all: [],
        },
        currentArticle: {
            data: {},
            promise: null,
        },
        additionalData: {
            most_recently: [],
            last_week: [],
            last_month: [],
            last_year: [],
            most_views: [],
        }
    }
};

export default {
    namespaced: true,
    state: () => (getDefaultState()),

    getters: {
        CURRENT_ARTICLE(state) {
            return state.currentArticle.data;
        },

        ARTICLES(state) {
            return state.articles.all;
        },

        ARTICLES_PROMISE(state) {
            return state.articles.promise;
        },

        MOST_RECENTLY(state) {
            return state.additionalData.most_recently;
        },

        LAST_WEEK(state) {
            return state.additionalData.last_week;
        },

        LAST_YEAR(state) {
            return state.additionalData.last_year;
        },

        LAST_MONTH(state) {
            return state.additionalData.last_month;
        },

        MOST_VIEWS(state) {
            return state.additionalData.most_views;
        },
    },

    actions: {
        FETCH_ARTICLES({state, commit}, fetchParams) {
            if (state.articles.promise) {
                return state.articles.promise;
            }

            let params = _.assign(state.articles.params, fetchParams);
            params = _.pickBy(params, function (elem) {
                if (undefined === elem) {
                    return false;
                }
                if (Array.isArray(elem)) {
                    return elem.length;
                }
                return elem.toString().length;
            });
            let promise = axios.get('/news?', {params}).then((response) => {
                commit('UPDATE_ARTICLES', _.get(response,'data.data',[]));
                commit('UPDATE_MOST_RECENTLY', _.get(response,'data.most_recently',[]));
                commit('UPDATE_LAST_WEEK', _.get(response,'data.last_week',[]));
                commit('UPDATE_LAST_YEAR', _.get(response,'data.last_year',[]));
            }).catch((e) => {
                console.log(e);
            }).then(() => {
                commit('UPDATE_PROMISE_ARTICLE', null);
            });

            commit('UPDATE_PROMISE_ARTICLE', promise);
            return promise;
        },

        FETCH_CURRENT_ARTICLE({state, commit}, slug) {
            if (state.currentArticle.promise) {
                return state.currentArticle.promise;
            }

            let promise = axios.get('/news/' + slug).then((response) => {
                commit('UPDATE_CURRENT_ARTICLE', response.data.data);
                commit('UPDATE_LAST_MONTH', _.get(response,'data.last_month',[]));
                commit('UPDATE_MOST_VIEWS', _.get(response,'data.most_views_category',[]));
            }).catch((e) => {
                console.log(e);
            }).then(() => {
                commit('UPDATE_PROMISE_CURRENT_ARTICLE', null);
            });
            commit('UPDATE_PROMISE_CURRENT_ARTICLE', promise);
            return promise;
        },

        RESET_ARTICLE({commit}) {
            commit('SET_DEFAULT_DATA');
        },
    },

    mutations: {
        UPDATE_ARTICLES(state, data) {
            return state.articles.all = data;
        },

        UPDATE_CURRENT_ARTICLE(state, data) {
            return state.currentArticle.data = data;
        },

        UPDATE_MOST_RECENTLY(state, most_recently) {
            return state.additionalData.most_recently = most_recently;
        },

        UPDATE_LAST_WEEK(state, last_week) {
            return state.additionalData.last_week = last_week;
        },

        UPDATE_LAST_MONTH(state, last_month) {
            return state.additionalData.last_month = last_month;
        },

        UPDATE_LAST_YEAR(state, last_year) {
            return state.additionalData.last_year = last_year;
        },

        UPDATE_MOST_VIEWS(state, most_views) {
            return state.additionalData.most_views = most_views;
        },

        UPDATE_PROMISE_ARTICLE(state, promise) {
            return state.articles.promise = promise;
        },

        UPDATE_PROMISE_CURRENT_ARTICLE(state, promise) {
            return state.currentArticle.promise = promise;
        },

        SET_DEFAULT_DATA(state) {
            let defaultState = getDefaultState();
            _.unset(defaultState, 'additionalData');
            return _.assign(state, defaultState);
        }
    }
}
