export const comments = {
    data() {
        return {
            comments: [],
            commentsCount: 0,
            commentsFullLoaded: false,
            visibleComments: 0,
            commentsLoading: false,
            commentsPushing: false,
        }
    },
    computed: {
        hiddenComments() {
            return this.commentsCount - this.visibleComments;
        },
        visibleCommentsData() {
            return _.takeRight(this.comments, this.visibleComments);
        },
    },
    methods: {
        showMoreCommentsProcess(commentable_type, commentable_id) {
            if (this.commentsFullLoaded) {
                this.visibleComments += _.min([10, this.hiddenComments]);
                return;
            }

            let args = [
                `commentable_id=${commentable_id}`,
                `commentable_type=${commentable_type}`,
            ];

            this.commentsLoading = true;
            axios.get('/comments?' + args.join('&')).then((response) => {
                this.comments = response.data.data;
                this.visibleComments += _.min([10, this.hiddenComments]);
                this.commentsFullLoaded = true;
                this.commentsLoading = false;
            });
        },
        pushCommentProcess(commentable_type, commentable_id, form) {
            let params = _.assign({
                commentable_type: commentable_type,
                commentable_id: commentable_id,
            }, form);
            this.commentsPushing = true;
            return axios.post(`/comments/store`, params).then((response) => {
                this.comments = response.data.data;
                this.commentsFullLoaded = true;
                this.visibleComments++;
                this.commentsCount++;
                this.commentsPushing = false;
            });
        },
        hitCommentProcess(commentable_id) {
            return axios.post(`/comments/${commentable_id}/hit`).then((response) => {
                this.comments = response.data.data;
                this.commentsFullLoaded = true;
            });
        },
    },
};
