<template>
    <el-dropdown
      :placement="'bottom-end'"
      trigger="click"
      :disabled="GUEST"
      @visible-change="showNotifications"
      class="menu-dropdown"
    >
      <div class="dropdown-label-wrapper">
        <el-badge :value="freshNotificationsCount">
          <svg viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M60.5418 47.6638C60.5418 30.6354 45.4281 34.4195 45.4281 21.1752C45.4281 20.0778 45.3223 19.1393 45.1296 18.3409C44.1359 11.6582 39.6887 6.29613 33.9266 4.6614C33.9795 4.42679 34.0059 4.18839 34.0059 3.93864C34.0059 1.97091 32.3056 0.362671 30.2275 0.362671C28.1494 0.362671 26.4491 1.97091 26.4491 3.93864C26.4491 4.1846 26.4755 4.42679 26.5284 4.6614C20.0673 6.4929 15.2574 13.0167 15.1176 20.8308C15.1138 20.9443 15.1138 21.0579 15.1138 21.1752C15.1138 34.4195 0.000121998 30.6354 0.000121958 47.6638C0.000121947 52.1707 10.0696 55.9396 23.5548 56.8932C24.8244 59.281 27.337 60.9081 30.2275 60.9081C33.118 60.9081 35.6307 59.281 36.9002 56.8932C50.3816 55.9434 60.4549 52.1707 60.4549 47.6638C60.4549 47.6487 60.4549 47.6373 60.4549 47.6222L60.5418 47.6638ZM48.7796 50.8614C45.5792 51.7166 41.8046 52.3334 37.7541 52.6739C37.4178 48.8066 34.176 45.7718 30.2275 45.7718C26.2791 45.7718 23.0372 48.8066 22.7009 52.6739C18.6504 52.3334 14.8758 51.7166 11.6754 50.8614C7.26981 49.6845 5.16145 48.3676 4.39065 47.6638C5.16145 46.96 7.26981 45.6431 11.6754 44.4663C16.799 43.0964 23.3886 42.3434 30.2275 42.3434C37.0665 42.3434 43.656 43.0964 48.7796 44.4663C53.1852 45.6431 55.2936 46.96 56.0644 47.6638C55.2936 48.3676 53.1852 49.6845 48.7796 50.8614Z" fill="white"/>
          </svg>
        </el-badge>
      </div>

      <template v-slot:dropdown>
        <el-dropdown-menu class="notifications-container py-2">
          <div class="w-100">
            <div class="read-notifications mt-2">
              <div class="notifications-header" v-t="'header.menu.notifications'"></div>

              <div class="read-notifications-btn" @click="readNotification">
                <div v-t="'header.read_notifications'"></div>
                <div class="notification-read-action d-flex justify-content-center align-items-center">
                  <i class="el-icon-delete font-size-20"></i>
                </div>
              </div>
            </div>

            <el-divider class="my-2"></el-divider>

            <template v-for="(notification, i) in NOTIFICATIONS" :key="i">
              <div class="d-flex justify-content-between">
                <div class="notification-container d-flex justify-content-start align-items-center py-2" @click="clickNotification(notification)">
                  <div class="notification-avatar">
                  <span class="el-avatar el-avatar--circle" data-v-d0ae47b8="" style="--el-avatar-size:50px !important;">
                    <img src="@/assets/images/avatar.jpg" style="object-fit: cover;">
                  </span>
                  </div>

                  <div class="notification-text">
                    <div class="notification-content" v-text="notification.content"></div>
                    <div class="notification-time mt-2" v-text="notification.time_ago"></div>
                  </div>
                </div>

                <div class="notification-read-action d-flex justify-content-center align-items-center">
                  <i class="el-icon-delete font-size-20" @click="readNotification({notification_id: notification.id})"></i>
                </div>
              </div>

              <el-divider class="my-2"/>
            </template>
          </div>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {needAuth} from "@/mixins/auth/needAuth";

export default {
  name: "notifications",
  mixins: [needAuth],
  data() {
    return {
      shownNotifications: []
    }
  },
  computed: {
    ...mapGetters('auth', [
      'USER',
      'GUEST',
      'NOTIFICATIONS',
    ]),

    freshNotificationsCount() {
      let thisElem = this;
      return _.size(_.filter(this.NOTIFICATIONS, (o) => !thisElem.shownNotifications.includes(o.id)));
    },
  },
  mounted(){
    let shownNotifications = cookies.get('shown-notifications');
    if (_.isEmpty(shownNotifications)) {
      shownNotifications = [];
    } else {
      shownNotifications = JSON.parse(shownNotifications);
    }
    this.shownNotifications = shownNotifications;
  },
  methods: {
    ...mapActions('auth', [
      'READ_NOTIFICATION',
    ]),

    showNotifications(isVisible) {
      if(!isVisible){
        return;
      }
      if (this.triggerLoginDialog()) {
        return;
      }
      let shownNotifications = this.shownNotifications;

      _.forEach(this.NOTIFICATIONS, (notification) => {
        shownNotifications.push(notification.id);
      });

      this.shownNotifications = _.uniq(shownNotifications);
      cookies.set('shown-notifications', JSON.stringify(this.shownNotifications), '1y');
    },

    readNotification(params = {}) {
      this.READ_NOTIFICATION(params);
    },

    clickNotification(notification) {
      if (notification.is_link) {
        window.open(notification.url, '_self');
      }
    }
  }
}
</script>
