<template>
  <div class="online-promo-preview">
    <div class="online-promo-header" ref="online_promo_header">
      <div class="online-promo-actions">
        <div class="online-promo-favourite">
          <div style="position: relative">
            <i class="cursor-pointer"
               :class="{'active icon-heart-2':onlinePromo.is_favourite, 'icon-heart':!onlinePromo.is_favourite}"
               @click="processFavourite"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="online-promo-content">

      <div class="position-relative">
        <div class="online-promo-image-container">
          <img :src="!imageLoadError ? onlinePromo.image_url : require('@/assets/images/promo.png')"
               class="online-promo-image"
               :style="`max-width:`+imageWidth+`px`"
               @click="showFullPromo = true"
               @error="imageLoadError = true"/>
        </div>

        <hit :container-width="imageWidth"
             :top="targetTop"
             :hits-count="onlinePromo.hits.count"
             :is-like="onlinePromo.is_hit"
             :is-dislike="onlinePromo.is_dislike"
             @hit="processHit"
             size="small"></hit>
      </div>

      <div class="mt-2">
        <div class="store-title font-weight-bold w-100">{{ onlinePromo.webshop.translation.title }}</div>

        <div class="promo-text w-100">
          <el-popover
              popper-class="rounded-popover"
              placement="bottom-start"
              :width="imageWidth"
              trigger="hover"
              :content="onlinePromo.title">
            <template #reference>
              <div class="text-nowrap text-truncate overflow-hidden">
                {{ onlinePromo.title }}
              </div>
            </template>
          </el-popover>
        </div>

        <div class="expire-text w-100">
          <div class="float-start font-size-13"
               :class="{'text-secondary':onlinePromo.is_valid, 'text-danger': !onlinePromo.is_valid}">
            {{ onlinePromo.valid_text }}
          </div>
          <div class="float-end">
            <del v-if="onlinePromo.old_price">{{ onlinePromo.old_price }} €</del>
          </div>
        </div>
      </div>
    </div>
    <div class="online-promo-footer mt-3">
      <div class="online-promo-price">
        {{ onlinePromo.new_price }} € <i class="icon-arrow-3"></i>
      </div>
    </div>

  </div>
  <el-dialog
      v-if="!xsOnly"
      v-model="showFullPromo"
      :show-close="false"
      :close-on-click-modal="true"
      :close-on-press-escape="true"
      :lock-scroll="false"
      class="danger-header-dialog online-promo-dialog"
      center>
    <online-promo :online-promo="onlinePromo"></online-promo>

    <template v-slot:header>
      <div>
        <i class="icon-arrow_back float-start cursor-pointer" @click="showFullPromo = false"></i>
        {{ onlinePromo.title }}
      </div>
    </template>
  </el-dialog>
</template>

<script>
import Hit from '@/components/feed/Hit';
import OnlinePromo from '@/components/feed/promo/OnlinePromo';
import {needAuth} from "@/mixins/auth/needAuth";

export default {
  name: "online-promo-preview",
  components: {Hit, OnlinePromo},
  mixins: [needAuth],
  props: {
    onlinePromo: Object,
    vuexModule: {
      type: String,
      default: 'promo',
    },
  },
  data() {
    return {
      imageWidth: 0,
      showFullPromo: false,
      imageLoadError: false,
    }
  },
  computed: {
    targetTop() {
      return this.xsOnly ? 150 : 180;
    }
  },
  mounted() {
    let thisElem = this;
    new ResizeObserver(function () {
      thisElem.calculateHeight();
    }).observe(this.$refs.online_promo_header);
  },
  methods: {
    calculateHeight() {
      let thisElem = this;

      if (!thisElem.$refs.online_promo_header) {
        return 0;
      }
      thisElem.imageWidth = thisElem.$refs.online_promo_header.clientWidth - 20;
    },
    processHit(value) {
      if (this.triggerLoginDialog()) {
        return;
      }
      let data = {
        morphable_type: 'OnlinePromo',
        morphable_id: this.onlinePromo.id,
      };

      if (value) {
        this.$store.dispatch(this.vuexModule + '/SET_HIT', data);
      } else {
        this.$store.dispatch(this.vuexModule + '/SET_DISLIKE', data);
      }
    },
    processFavourite() {
      if (this.triggerLoginDialog()) {
        return;
      }
      let data = {
        morphable_type: 'OnlinePromo',
        morphable_id: this.onlinePromo.id,
      };

      if (this.onlinePromo.is_favourite) {
        this.$store.dispatch(this.vuexModule + '/REMOVE_FAVORITE', data);
      } else {
        this.$store.dispatch(this.vuexModule + '/SET_FAVORITE', data);
      }
    }
  }
}
</script>
