<template>
  <default-grid>
    <template v-slot:left-container>
      <left-bar show-user-menu></left-bar>
    </template>
    <template v-slot:main-container>
      <div class="feed-container">
        <tabs :default="'favourites'" :tabs="tabs" @change="tabChange"></tabs>

        <div class="home-feed">

          <el-row type="flex" justify="start">
            <template v-for="(promo, i) in PROMOS" :key="i">
              <el-col :span="24">
                <folder-promo v-if="'FolderItem' === promo.morphable_type"
                              :folder-promo="promo.data"></folder-promo>
                <online-promo v-if="'OnlinePromo' === promo.morphable_type"
                              :online-promo="promo.data"></online-promo>
                <post v-if="'Post' === promo.morphable_type"
                                :post="promo.data"></post>
              </el-col>
            </template>
          </el-row>

          <infinite-loading @infinite="load" :key="currentTab">
            <template #spinner>
              <div class="spinner-container" v-loading="true"></div>
            </template>
            <template #complete>
              <div></div>
            </template>
            <template #error>
              <div></div>
            </template>
          </infinite-loading>
        </div>
      </div>
    </template>
  </default-grid>
</template>

<script>
import DefaultGrid from '@/views/DefaultGrid';
import LeftBar from '@/components/left_bar/LeftBar';
import {mapActions, mapGetters} from "vuex";
import Post from "@/components/feed/post/Post";
import OnlinePromo from "@/components/feed/promo/OnlinePromo";
import FolderPromo from "@/components/feed/promo/FolderPromo";
import Tabs from "@/components/feed/Tabs";

export default {
  name: "FavouritesView",
  components: {
    Tabs,
    FolderPromo,
    OnlinePromo,
    Post,
    DefaultGrid,
    LeftBar
  },
  data() {
    return {
      currentTab: 'default',
      fetchOptions: {
        include_filters: [],
        morphable_types: [],

        only_favourites: 1,
        order: 'favourite_at'
      }
    }
  },
  computed: {
    ...mapGetters('promo', [
      'PROMOS',
      'PROMOS_IS_COMPLETE',
    ]),
    ...mapGetters('filter', [
      'FILTERS_VALUES',
    ]),

    tabs() {
      let tabs = {};
      tabs["favourites"] = this.$t('search.favourites-tabs.favourites');
      tabs["online-promos"] = this.$t('search.favourites-tabs.online-promos');
      tabs["folder-promos"] = this.$t('search.favourites-tabs.folder-promos');
      return tabs;
    },
  },
  methods: {
    ...mapActions('promo', [
      'FETCH_PROMOS',
      'RESET_PROMOS',
    ]),

    prepareFetchOptions() {
      this.fetchOptions.morphable_types = [];
      switch (this.currentTab) {
        case 'favourites':
          break;
        case 'folder-promos':
          this.fetchOptions.morphable_types = ['FolderItem'];
          break;
        case 'online-promos':
          this.fetchOptions.morphable_types = ['OnlinePromo'];
          break;
      }
    },

    tabChange(tabKey){
      this.currentTab = tabKey;
      this.prepareFetchOptions();
      this.RESET_PROMOS();
      this.loadPromos();
    },

    loadPromos() {
      return this.FETCH_PROMOS(this.fetchOptions);
    },

    load(state) {
      this.loadPromos().then(() => {
        if (this.PROMOS_IS_COMPLETE) {
          state.complete();
        } else {
          state.loaded();
        }
      });
    }
  },
}
</script>
