<template>
  <div class="p-3">
    <div class="most-recently-container" v-if="!xsOnly">
      <div class="most-recently-header">{{ $t('news.most_recently') }}</div>
      <div v-for="recently in mostRecently" :key="recently" class="py-2">
        <a v-if="recently.is_external" v-bind:href="recently.external_link" target="_blank" rel="noopener noreferrer"
           v-html="recently.title"></a>
        <router-localized-link
            :to="{name: 'news.article', params: {categorySlug: String(recently.category_slug), articleSlug: String(recently.slug)}}"
            v-html="recently.title" v-else></router-localized-link>
      </div>
    </div>

    <div class="most-recently-container py-4">
      <div class="most-recently-header">{{ $t('news.most_read') }}</div>
      <div v-for="mostRead in lastYear" :key="mostRead" class="py-2">
        <a v-if="mostRead.is_external" v-bind:href="mostRead.external_link" target="_blank" rel="noopener noreferrer"
           v-html="mostRead.title"></a>
        <router-localized-link
            :to="{name: 'news.article', params: {categorySlug: String(mostRead.category_slug), articleSlug: String(mostRead.slug)}}"
            v-html="mostRead.title" v-else>
        </router-localized-link>
      </div>
    </div>
  </div>
</template>

<script>
import RouterLocalizedLink from "@/components/common/RouterLocalizedLink";

export default {
  name: "news-lef-topt-bar",
  components: {RouterLocalizedLink},
  props: {
    mostRecently: Object,
    lastYear: Object
  },
}
</script>
