<template>
  <default-grid :show-right-container="false">
    <template v-slot:main-container>
      <div class="promos-feed">
        <h1 class="text-danger font-size-26 my-3" v-loading="promosLoading">
          <span v-if="promosLoading" v-t="'search.loading.label'"></span>
          <span v-else>{{ $t('promo.promos_page_h1', {total: this.totalPromos}) }}</span>
        </h1>

        <tabs :tabs="tabs" :default="currentTab" @change="tabChange" :change-query="true"></tabs>

        <el-row type="flex" justify="start" class="secondary-feed">
          <template v-for="(promo, i) in PROMOS" :key="i">
            <el-col :xs="12" :sm="6" :md="6" :lg="8" :xl="6">
              <folder-promo-preview v-if="'FolderItem' === promo.morphable_type"
                                    :folder-promo="promo.data"></folder-promo-preview>
              <online-promo-preview v-if="'OnlinePromo' === promo.morphable_type"
                                    :online-promo="promo.data"></online-promo-preview>
              <discount-code v-if="'Post' === promo.morphable_type" :post="promo.data"></discount-code>
            </el-col>
          </template>
        </el-row>

        <infinite-loading @infinite="load" :key="currentTab">
          <template #spinner>
            <div class="spinner-container" v-loading="true"></div>
          </template>
          <template #complete>
            <div class="text-danger font-size-18 my-3">
              <div v-if="!$store.getters['auth/GUEST']">
                <span v-t="'search.loading.no_more'" v-if="PROMOS_TOTAL > 0"></span>
                <span v-t="'search.loading.no_results'" v-else></span>
              </div>
            </div>
          </template>
          <template #error>
            <div></div>
          </template>
        </infinite-loading>
      </div>
    </template>
  </default-grid>
</template>

<script>
import DefaultGrid from '@/views/DefaultGrid';
import {mapActions, mapGetters} from "vuex";
import DiscountCode from "@/components/feed/post/DiscountCode";
import OnlinePromoPreview from "@/components/feed/promo/OnlinePromoPreview";
import FolderPromoPreview from "@/components/feed/promo/FolderPromoPreview";
import Tabs from "@/components/feed/Tabs";
import {useHead} from "@vueuse/head";
import {needAuth} from "@/mixins/auth/needAuth";

export default {
  name: 'PromosView',
  components: {
    DefaultGrid,
    DiscountCode,
    OnlinePromoPreview,
    FolderPromoPreview,
    Tabs
  },
  mixins: [needAuth],
  data() {
    return {
      currentTab: _.get(this.$route.query, 'tab', 'default'),
      fetchOptions: {
        per_page: 12,
        include_filters: ['types', 'morphable_types', 'categories', 'webshops', 'stores', 'brands', 'prices'],
        q: '',
        type_kind: '2',
        only_valid_promos: 1,
        only_online_promos: '',
        only_day_deals: '',
        only_paid_client: '',
        only_recently_created: '',
        only_expires_soon: '',
        morphable_types: [],
        categories: [],
        webshops: [],
        stores: [],
        brands: [],
        prices: [],

        order: '',
        random_salt: Math.floor(Math.random() * 1000000000),
      }
    }
  },
  computed: {
    ...mapGetters('promo', [
      'PROMOS',
      'PROMOS_PROMISE',
      'PROMOS_TOTAL',
      'PROMOS_IS_COMPLETE',
      'PROMOS_CATEGORIES',
      'PROMOS_WEBSHOPS',
      'PROMOS_STORES',
      'PROMOS_BRANDS',
      'PROMOS_PRICES',
      'PROMOS_MORPHABLE_TYPES',
    ]),

    ...mapGetters('filter', [
      'FILTERS_VALUES',
    ]),

    totalPromos() {
      if (this.PROMOS_TOTAL < 10000) {
        return this.PROMOS_TOTAL.toLocaleString();
      }

      return '10.000+';
    },

    promosLoading() {
      return !!this.PROMOS_PROMISE;
    },

    tabs() {
      let tabs = {};
      tabs["default"] = this.$t('promo.tabs.default');
      tabs["more-hits"] = this.$t('promo.tabs.more-hits');
      tabs["recently-created"] = this.$t('promo.tabs.recently-created');
      tabs["expires-soon"] = this.$t('promo.tabs.expires-soon');
      return tabs;
    },
  },
  created() {
    useHead({
      // Can be static or computed
      link: [
        {
          rel: "canonical",
          href: new URL(this.$route.path, window.location.origin).href
        },
      ],
    })
  },
  beforeMount() {
    this.RESET_PROMOS();
    this.RESET_FILTERS();

    this.SET_FILTER_OPENED({categories_filter: false});
    this.SET_FILTER_OPENED({webshops_filter: false});
    this.SET_FILTER_OPENED({stores_filter: false});
    this.SET_FILTER_OPENED({brands_filter: false});

    this.prepareFetchOptions();
  },
  methods: {
    ...mapActions('filter', [
      'SET_FILTER_ACTIVE',
      'SET_FILTER_OPENED',
      'RESET_FILTERS',
    ]),
    ...mapActions('promo', [
      'FETCH_PROMOS',
      'RESET_PROMOS',
    ]),

    prepareFetchOptions() {
      this.SET_FILTER_ACTIVE('search_filter');
      this.SET_FILTER_ACTIVE('promos_filter');
      this.SET_FILTER_ACTIVE('categories_filter');
      this.SET_FILTER_ACTIVE('webshops_filter');
      this.SET_FILTER_ACTIVE('stores_filter');
      this.SET_FILTER_ACTIVE('brands_filter');
      this.SET_FILTER_ACTIVE('price_filter');

      this.fetchOptions.morphable_types = [];
      this.fetchOptions.only_online_promos = '';
      this.fetchOptions.only_day_deals = '';

      let promos_filter = this.FILTERS_VALUES.promos_filter;
      if (promos_filter.includes('discount_code')) {
        this.fetchOptions.morphable_types.push('Post');
      }
      if (promos_filter.includes('folder_promos')) {
        this.fetchOptions.morphable_types.push('FolderItem');
      }
      if (promos_filter.includes('online_promos') || promos_filter.includes('day_deals')) {
        this.fetchOptions.morphable_types.push('OnlinePromo');

        if (promos_filter.includes('online_promos')) {
          this.fetchOptions.only_online_promos = 1;
        }
        if (promos_filter.includes('day_deals')) {
          this.fetchOptions.only_day_deals = 1;
        }
        if (this.fetchOptions.only_online_promos === 1 && this.fetchOptions.only_day_deals === 1) {
          this.fetchOptions.only_online_promos = this.fetchOptions.only_day_deals = '';
        }
      }

      this.fetchOptions.categories = this.FILTERS_VALUES.categories_filter;
      this.fetchOptions.webshops = this.FILTERS_VALUES.webshops_filter;
      this.fetchOptions.stores = this.FILTERS_VALUES.stores_filter;
      this.fetchOptions.brands = this.FILTERS_VALUES.brands_filter;
      this.fetchOptions.q = this.FILTERS_VALUES.search_filter;

      let prices = this.FILTERS_VALUES.price_filter;
      this.fetchOptions.prices = prices;
      if (prices[0] == null || prices[1] == null) {
        this.fetchOptions.prices = [];
      }

      this.fetchOptions.only_paid_client = '';
      this.fetchOptions.only_recently_created = '';
      this.fetchOptions.only_expires_soon = '';
      this.fetchOptions.order = '';
      switch (this.currentTab) {
        case 'more-hits':
          this.fetchOptions.order = 'hits_count';
          break;
        case 'recently-created':
          this.fetchOptions.only_recently_created = 1;
          this.fetchOptions.order = 'recent_popular_random';
          break;
        case 'expires-soon':
          this.fetchOptions.only_expires_soon = 1;
          this.fetchOptions.order = 'recent_popular_random';
          break;
        case 'default':
        default:
          if (_.isEmpty(this.fetchOptions.q)) {
            this.fetchOptions.only_paid_client = 1;
          }
          this.fetchOptions.order = 'recent_popular_random';
          break;
      }
    },

    tabChange(tabKey) {
      this.currentTab = tabKey;
      this.RESET_PROMOS();
      this.prepareFetchOptions();
      this.loadPromos();
    },

    loadPromos() {
      return this.FETCH_PROMOS(this.fetchOptions);
    },

    load(state) {
      //Show login dialog modal instead of loading
      if (_.size(this.PROMOS) && this.triggerLoginDialog()) {
        state.complete();
        return;
      }

      this.loadPromos().then(() => {
        if (this.PROMOS_IS_COMPLETE) {
          state.complete();
        } else {
          state.loaded();
        }
      });
    },
  },
  watch: {
    FILTERS_VALUES: {
      // the callback will be called immediately after the start of the observation
      deep: true,
      handler(newVal, oldVal) {
        if (this.PROMOS_PROMISE) {
          this.PROMOS_PROMISE.then(() => {
            this.RESET_PROMOS();
            this.prepareFetchOptions();
            this.loadPromos();
          });
        } else {
          this.RESET_PROMOS();
          this.prepareFetchOptions();
          this.loadPromos();
        }
      }
    }
  }
}
</script>
