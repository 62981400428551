<template>
  <div class="most-views-container p-3">
    <div class="most-recently-header mb-2">{{ $t('news.recommended') }}</div>
    <el-row v-for="mostView in lastViews"
            :key="mostView"
            type="flex"
            justify="start"
            class="py-2">
        <a  v-if="mostView.is_external" class="most-views-article-block"
           v-bind:href="mostView.translation.external_link" target="_blank" rel="noopener noreferrer">
          <el-col :xs="12" :xl="10">
            <div class="most-views-image-container">
              <img v-if="mostView.translation.cover_image"
                   :src="mostView.translation.cover_image"
                   :alt="mostView.translation.title"
                   :title="mostView.translation.title"
                   class="most-views-image"
              >
              <img v-else src="@/assets/images/promo-blog.png"
                   class="most-views-image"
              >
            </div>
          </el-col>
          <el-col :xs="12" :xl="14">
            <div class="most-views-title">
              {{ mostView.translation.title }}
            </div>
          </el-col>
        </a>
      <router-localized-link class="most-views-article-block" v-else
                             :to="{name: 'news.article', params: {categorySlug: mostView.category.translation.slug, articleSlug: mostView.translation.slug}}">
        <el-col :xs="12" :xl="10">
          <div class="most-views-image-container">
            <img v-if="mostView.translation.cover_image"
                 :src="mostView.translation.cover_image"
                 :alt="mostView.translation.title"
                 :title="mostView.translation.title"
                 class="most-views-image"
            >
            <img v-else src="@/assets/images/promo-blog.png"
                 class="most-views-image"
            >
          </div>
        </el-col>
        <el-col :xs="12" :xl="14">
          <div class="most-views-title">
            {{ mostView.translation.title }}
          </div>
        </el-col>
      </router-localized-link>
    </el-row>
  </div>
</template>

<script>
import RouterLocalizedLink from "@/components/common/RouterLocalizedLink";

export default {
  name: "news-left-bottom-bar",
  components: {
    RouterLocalizedLink
  },
  props: {
    lastViews: Object
  },
}
</script>
