<template>
  <div>
    <p class="el-dialog__body__title" v-t="'auth.default_auth_step_label'"/>

    <div class="additional-info-icon">
      <i class="icon-check"/>
    </div>

    <div class="additional-info-text" v-html="$t('auth.info_step.point1')"/>

    <div class="additional-info-icon">
      <i class="icon-check"/>
    </div>

    <div class="additional-info-text" v-html="$t('auth.info_step.point2')"/>

    <div class="additional-info-icon">
      <i class="icon-check"/>
    </div>

    <div class="additional-info-text" v-html="$t('auth.info_step.point3')"/>

    <div class="additional-info-icon">
      <i class="icon-check"/>
    </div>

    <div class="additional-info-text" v-html="$t('auth.info_step.point4')"/>

    <el-button
      type="primary"
      class="w-100"
      @click="$emit('submit')"
    >
      {{ $t('auth.register_for_free') }}
    </el-button>

    <div class="d-flex justify-content-center align-items-center mt-2">
      <span
        class="cursor-pointer text-decoration-underline font-size-16"
        v-t="'auth.or_login'"
        @click="$emit('goLogin')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "default-step"
}
</script>
