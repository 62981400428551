<template>
  <default-grid>
    <template v-slot:main-container>
      <char-sorted-entities :header="$t('webshop.all_webshops')"
                            :data="WEBSHOPS"
                            route-name="webshop.show"
                            route-param-name="webshopSlug">
      </char-sorted-entities>
    </template>
  </default-grid>
</template>

<script>
import DefaultGrid from "@/views/DefaultGrid";
import CharSortedEntities from "@/components/feed/CharSortedEntities";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "WebshopsView",
  components: {CharSortedEntities, DefaultGrid},
  computed: {
    ...mapGetters('webshop', [
      'WEBSHOPS',
    ]),
  },
  mounted() {
    this.FETCH_WEBSHOPS();
  },
  methods: {
    ...mapActions('webshop', [
      'FETCH_WEBSHOPS',
    ])
  }
}
</script>
