<template>
  <div class="folder-promo-preview">
    <div class="folder-promo-header" ref="folder_promo_header">
      <div class="folder-promo-actions">
        <div class="folder-promo-favourite">
          <div style="position: relative">
            <i class="cursor-pointer"
               :class="{'active icon-heart-2':folderPromo.is_favourite, 'icon-heart':!folderPromo.is_favourite}"
               @click="processFavourite"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="folder-promo-content">

      <div class="position-relative">
        <div class="folder-promo-image-container">
          <img :src="folderPromo.image_url"
               class="folder-promo-image"
               :style="`max-width:`+imageWidth+`px`"
               @click="showFullPromo = true">
        </div>

        <hit :container-width="imageWidth"
             :top="targetTop"
             :hits-count="folderPromo.hits.count"
             :is-like="folderPromo.is_hit"
             :is-dislike="folderPromo.is_dislike"
             @hit="processHit"
             size="small"></hit>
      </div>

      <div class="mt-2">
        <div class="store-title font-weight-bold w-100">
          {{ folderPromo.folderPage.folder.store.translation.title }}
        </div>

        <div class="promo-text w-100">
          <el-popover
              popper-class="rounded-popover"
              placement="bottom-start"
              :width="imageWidth"
              trigger="hover"
              :content="folderPromo.title">
            <template #reference>
              <div class="text-nowrap text-truncate overflow-hidden">
                {{ folderPromo.title }}
              </div>
            </template>
          </el-popover>
        </div>

        <div class="expire-text w-100">
          <div class="float-start font-size-13"
               :class="{'text-secondary':folderPromo.is_valid, 'text-danger': !folderPromo.is_valid}">
            {{ folderPromo.valid_text }}
          </div>
          <div class="float-end">
            <del v-if="folderPromo.old_price">{{ folderPromo.old_price }} €</del>
          </div>
        </div>
      </div>
    </div>
    <div class="folder-promo-footer mt-3">
      <div class="folder-promo-price">
        <span v-if="folderPromo.new_price">
          {{ folderPromo.new_price }} € <i class="icon-arrow-3"></i>
        </span>
        <span v-else>
          {{ folderPromo.discount }} % <i class="icon-arrow-3"></i>
        </span>
      </div>
    </div>
  </div>

  <el-dialog
      v-if="!xsOnly"
      v-model="showFullPromo"
      :show-close="false"
      :close-on-click-modal="true"
      :close-on-press-escape="true"
      :lock-scroll="false"
      class="danger-header-dialog online-promo-dialog"
      center>
    <folder-promo :folder-promo="folderPromo"></folder-promo>

    <template v-slot:header>
      <div>
        <i class="icon-arrow_back float-start cursor-pointer" @click="showFullPromo = false"></i>
        {{ folderPromo.title }}
      </div>
    </template>
  </el-dialog>
</template>

<script>
import Hit from '@/components/feed/Hit';
import {needAuth} from "@/mixins/auth/needAuth";
import FolderPromo from "@/components/feed/promo/FolderPromo";

export default {
  name: "folder-promo-preview",
  components: {FolderPromo, Hit},
  mixins: [needAuth],
  props: {
    folderPromo: Object,
    vuexModule: {
      type: String,
      default: 'promo',
    },
  },
  data() {
    return {
      imageWidth: 0,
      showFullPromo: false,
    }
  },
  computed: {
    targetTop() {
      return this.xsOnly ? 150 : 180;
    }
  },
  mounted() {
    let thisElem = this;
    new ResizeObserver(function () {
      thisElem.calculateHeight();
    }).observe(this.$refs.folder_promo_header);
  },
  methods: {
    calculateHeight() {
      let thisElem = this;

      if (!thisElem.$refs.folder_promo_header) {
        return 0;
      }
      thisElem.imageWidth = thisElem.$refs.folder_promo_header.clientWidth - 20;
    },
    processHit(value) {
      if (this.triggerLoginDialog()) {
        return;
      }
      let data = {
        morphable_type: 'FolderItem',
        morphable_id: this.folderPromo.id,
      };

      if (value) {
        this.$store.dispatch(this.vuexModule + '/SET_HIT', data);
      } else {
        this.$store.dispatch(this.vuexModule + '/SET_DISLIKE', data);
      }
    },
    processFavourite() {
      if (this.triggerLoginDialog()) {
        return;
      }
      let data = {
        morphable_type: 'FolderItem',
        morphable_id: this.folderPromo.id,
      };

      if (this.folderPromo.is_favourite) {
        this.$store.dispatch(this.vuexModule + '/REMOVE_FAVORITE', data);
      } else {
        this.$store.dispatch(this.vuexModule + '/SET_FAVORITE', data);
      }
    }
  }
}
</script>
