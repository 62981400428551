export const randomHits = {
    data() {
        return {
            hitsRandomBonus: 0,
        }
    },
    computed: {
        hitsCount() {
            return this.post.hits.count + this.hitsRandomBonus;
        },
    },
    mounted() {
        this.hitsRandomBonus = this.generateRandomLikesCount();
    },
    methods: {
        generateRandomLikesCount() { // need random bonus from 1 to 6
            let percentagesLib = {
                10: 1, // 1 -> 10
                30: 2, // 11 -> 30
                55: 3, // 31 -> 55
                80: 4, // 56 -> 80
                95: 5, // 81 -> 95
                100: 6,// 96 -> 100
            };
            // 0 to 99
            let num = this.post.created_at.timestamp % 100 + 1;

            let result = 3;
            _.forEach(percentagesLib, function (value, key) {
                if (num <= key) {
                    result = value;
                    return false;
                }
            });

            return result;
        }
    }
}
