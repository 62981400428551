<template>
  <div>
    <template v-if="1 === step">
      <register-step-first @validated="validated" :try-register-data="tryRegisterData"/>
    </template>

    <template v-if="2 === step">
      <register-step-second @validated="validated"/>
    </template>

    <template v-if="3 === step">
      <register-step-third :default-email="data.email" @validated="validated"/>
    </template>

    <template v-if="4 === step">
      <register-step-fourth @validated="validated"/>
    </template>
  </div>
</template>

<script>
import RegisterStepFirst from "@/components/header/auth/Steps/RegisterStepFirst.vue";
import RegisterStepSecond from "@/components/header/auth/Steps/RegisterStepSecond.vue";
import RegisterStepThird from "@/components/header/auth/Steps/RegisterStepThird.vue";
import RegisterStepFourth from "@/components/header/auth/Steps/RegisterStepFourth.vue";
import {mapActions} from "vuex";
import {socialLogin} from "@/mixins/auth/socialLogin";

export default {
  name: "register-step",
  components: {RegisterStepFirst, RegisterStepSecond, RegisterStepThird, RegisterStepFourth},
  mixins: [socialLogin],
  props: ['tryRegisterData'],
  data() {
    return {
      data: {
        provider: 'email',
      },
      step: 1,
    }
  },
  computed: {
    registerUri() {
      switch (this.data.provider) {
        case 'email':
          return 'register'

        default:
          return `register/social/${this.data.provider}`
      }
    },
  },
  methods: {
    ...mapActions('loader', [
      'SET_LOADER',
    ]),
    ...mapActions('auth', [
      'UPDATE_TOKEN',
      'FETCH_USER',
    ]),
    validated(formData) {
      if (cookies.get('invited_by')) {
        this.data.invited_by = cookies.get('invited_by');
      }

      this.data = _.assign({}, _.merge(this.data, formData));
      if (4 > this.step) {
        this.step++;
        return;
      }
      this.storeUser(this.data);
    },
    storeUser(formData) {
      this.SET_LOADER(true);
      this.$http.post(this.registerUri, formData).then((response) => {
        this.SET_LOADER(false);

        // this.$notify.success({
        //   title: this.$t('notifications.registration_completed.title'),
        //   message: this.$t('notifications.registration_completed.message'),
        //   position: this.notificationPosition,
        //   duration: this.notificationDuration,
        // });

        this.$emit('registered');

        if (this.data.provider === 'email') {
          //
        } else {
          this.getUserByToken(response.data.data.token);
        }
      });
    }
  }
}
</script>
