import store from "@/store";

export const needAuth = {
  methods: {
    triggerLoginDialog() {
      if (store.getters["auth/GUEST"]) {
        this.emitter.emit("show-login-dialog");
      }
      return store.getters["auth/GUEST"];
    },
    webshopLoginDialog(){
      let webshopRoute = _.get(this.$router.currentRoute.value, 'name', 'webshop.show')

      if (!_.isEmpty(webshopRoute) && this.$store.getters['auth/GUEST']) {
        this.emitter.emit('copy-discount-code', {
          discount_url: _.get(this.$store.getters['webshop/CURRENT_WEBSHOP'], 'translation.affiliate_url'),
          discount_code: 'webshop'
        })
      }
    }
  },
};
