import store from '@/store';

export default async (to, from, next) => {
    let campaign = _.get(to, 'query.campaign', false);

    if (campaign && store.getters['auth/GUEST']) {
        cookies.set('campaign_tag', campaign, '1y');
    }
    next();
}
