<template>
  <div class="left-bar-container">
    <el-scrollbar height="calc(100vh - 70px - 50px)">
      <store-logo v-if="showStoreLogo"></store-logo>
      <webshop-logo v-if="showWebshopLogo"></webshop-logo>
      <brand-logo v-if="showBrandLogo"></brand-logo>
      <product-logo v-if="showProductLogo"></product-logo>

      <store-mailer v-if="showStoreMailer"></store-mailer>
      <left-bar-user-menu v-if="showUserMenu"></left-bar-user-menu>
      <filters-list></filters-list>
      <folders-stores v-if="showFoldersStores"></folders-stores>

      <bookmarks></bookmarks>

      <div style="min-height: 210px"></div>
    </el-scrollbar>
  </div>
</template>

<script>
import LeftBarUserMenu from "@/components/left_bar/LeftBarUserMenu";
import FiltersList from "@/components/left_bar/filters/FiltersList";
import FoldersStores from "@/components/left_bar/store/FoldersStores";
import StoreLogo from "@/components/left_bar/store/StoreLogo";
import StoreMailer from "@/components/left_bar/store/StoreMailer";
import WebshopLogo from "@/components/left_bar/webshop/WebshopLogo";
import BrandLogo from "@/components/left_bar/brand/BrandLogo";
import ProductLogo from "@/components/left_bar/product/ProductLogo";
import Bookmarks from "@/components/left_bar/Bookmarks";

export default {
  name: "left-bar",
  props: {
    showUserMenu: {
      type: Boolean,
      default: false,
    },
    showFoldersStores: {
      type: Boolean,
      default: false,
    },
    showStoreLogo: {
      type: Boolean,
      default: false,
    },
    showWebshopLogo: {
      type: Boolean,
      default: false,
    },
    showBrandLogo: {
      type: Boolean,
      default: false,
    },
    showProductLogo: {
      type: Boolean,
      default: false,
    },
    showStoreMailer: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Bookmarks,
    ProductLogo,
    BrandLogo,
    StoreMailer,
    StoreLogo,
    WebshopLogo,
    FoldersStores,
    FiltersList,
    LeftBarUserMenu,
  }
}
</script>
