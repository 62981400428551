import store from '@/store';

export default async (to) => {
    await store.dispatch('brand/RESET_BRANDS');
    await store.dispatch('brand/FETCH_CURRENT_BRAND', to.params.brandSlug);

    if (_.isNil(store.getters['brand/CURRENT_BRAND'])) {
        return {name: 'brands'};
    }
    let parentBrand = _.get(store.getters['brand/CURRENT_BRAND'], 'parent_brand');
    if (!_.isEmpty(parentBrand)) {
        return {name: 'brand.show', params: {brandSlug: String(parentBrand.translation.route)}};
    }
};
