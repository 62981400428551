<template>
  <div>
    <div class="w-100 login-label" v-t="'auth.chose_of'"></div>

    <div class="class-register-icons">
      <i class="icon-Facebook cursor-pointer" @click="externalLogin('facebook')"></i>
      <div class="font-size-20 p-2" v-t="'auth.or'"></div>
      <i class="icon-Google-Chrome cursor-pointer" @click="externalLogin('google')"><span class="path1"></span><span
          class="path2"></span><span
          class="path3"></span><span class="path4"></span><span class="path5"></span></i>
      <div class="font-size-20 p-2" v-t="'auth.or'"></div>
      <i class="icon-Apple cursor-pointer" @click="externalLogin('apple')"></i>
    </div>

    <div class="w-100 login-label" v-t="'auth.or_email'"></div>

    <el-form :model="resetPasswordForm" ref="reset_password_form" :rules="resetPasswordRules" v-if="showResetPassword">
      <el-form-item prop="email">
        <el-input
            class="rounded-input"
            :placeholder="$t('auth.email')"
            :size="inputsSize"
            v-model="resetPasswordForm.email">
        </el-input>
      </el-form-item>
    </el-form>

    <el-form :model="form" ref="form" v-else>

      <el-form-item prop="email">
        <el-input
            class="rounded-input"
            :placeholder="$t('auth.email')"
            :size="inputsSize"
            v-model="form.email">
        </el-input>
      </el-form-item>

      <el-form-item prop="password">
        <el-input
            class="rounded-input"
            :placeholder="$t('auth.password')"
            :size="inputsSize"
            v-model="form.password"
            show-password
            @keydown.enter="submitLoginForm">
        </el-input>
      </el-form-item>

    </el-form>

    <div class="text-center cursor-pointer text-danger mb-3">
      <span v-t="'auth.reset_password.i_know_my_password'" v-if="showResetPassword"
            @click="showResetPassword = false"></span>
      <span v-t="'auth.reset_password.forgot_password_link'" v-else @click="showResetPassword=true"></span>
    </div>

    <el-button type="primary"
               class="w-100"
               @click="submitForm">
      <span v-t="'auth.reset_password.send_confirmation'" v-if="showResetPassword"></span>
      <span v-t="'auth.login'" v-else></span>
    </el-button>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {useReCaptcha} from 'vue-recaptcha-v3';
import {facebook} from "@/mixins/auth/facebook";
import {google} from "@/mixins/auth/google";
import {apple} from "@/mixins/auth/apple";
import {socialLogin} from "@/mixins/auth/socialLogin";
import {validator} from "@/mixins/validator";
import store from "@/store";
import {webviewLogin} from "@/mixins/auth/webviewLogin";

export default {
  name: "login-step",
  mixins: [facebook, google, apple, socialLogin, validator, webviewLogin],
  emits: ['passwordReset', 'loggedIn'],
  props: ['tryLoginData'],
  setup() {
    const {executeRecaptcha, recaptchaLoaded} = useReCaptcha();

    const recaptcha = async () => {
      // (optional) Wait until recaptcha has been loaded.
      await recaptchaLoaded();

      // Execute reCAPTCHA with action "login".
      return await executeRecaptcha("chollofinal_login");
    };

    return {
      recaptcha,
    };
  },
  data() {
    return {
      webviewEventName: 'login',
      showResetPassword: false,
      resetPasswordForm: {
        email: '',
      },
      resetPasswordRules: {
        email: [
          {validator: this.backendValidator}
        ]
      },
      form: {
        email: '',
        password: '',
        g_recaptcha: '',
      }
    }
  },
  computed: {
    ...mapGetters('auth', [
      'USER',
    ]),
  },
  mounted() {
    if (_.get(this.tryLoginData, 'provider') !== undefined && _.get(this.tryLoginData, 'options') !== undefined) {
      this.socialLogin(this.tryLoginData.provider, this.tryLoginData.options);
    }
  },
  methods: {
    ...mapActions('auth', [
      'UPDATE_TOKEN',
      'FETCH_USER',
    ]),

    submitForm() {
      if (this.showResetPassword) {
        this.submitResetPasswordForm();
      } else {
        this.submitLoginForm();
      }
    },

    submitResetPasswordForm() {
      this.$http.post('/password/email', this.resetPasswordForm).then((response) => {
        this.errors = {};
      }).catch((e) => {
        switch (_.get(e.response, 'status')) {
          case 422:
            this.errors = _.assign({}, e.response.data.errors);
            break;

          default:
            this.$notify.error({
              title: this.$t('notifications.default.title'),
              message: _.get(e.response, 'data.error', this.$t('notifications.default.message')),
              position: this.notificationPosition,
              duration: this.notificationDuration,
            });
            break;
        }
      }).then(() => {
        this.$refs.reset_password_form.validate((valid) => {
          if (valid) {
            this.$notify.success({
              title: this.$t('auth.reset_password.send_confirmation'),
              message: this.$t('auth.reset_password.notifications.sent'),
              position: this.notificationPosition,
              duration: this.notificationDuration,
            });
            this.showResetPassword = false;
            this.resetPasswordForm.email = '';
            this.$emit('passwordReset');
          }
        });
      });
    },

    // Login with Email and Password
    submitLoginForm() {
      store.dispatch('loader/SET_LOADER', true);
      this.recaptcha().then((recaptchaToken) => {
        this.form.g_recaptcha = recaptchaToken;
        this.$http.post('/token', this.form).then((response) => {
          this.getUserByToken(response.data.data.token); // from socialLogin mixin
        }).catch((e) => {
          store.dispatch('loader/SET_LOADER', false);
          switch (_.get(e.response, 'status')) {
            case 422:
              if (!_.isEmpty(e.response.data.errors.g_recaptcha)) {
                this.$notify.error({
                  title: this.$t('notifications.recaptcha.title'),
                  message: this.$t('notifications.recaptcha.message'),
                  position: this.notificationPosition,
                  duration: this.notificationDuration,
                });
              } else {
                this.errors = _.assign({}, e.response.data.errors);
              }
              break;

            default:
              this.$notify.error({
                title: this.$t('notifications.default.title'),
                message: _.get(e.response, 'data.error', this.$t('notifications.default.message')),
                position: this.notificationPosition,
                duration: this.notificationDuration,
                dangerouslyUseHTMLString: true,
              });
              break;
          }
        });
      })
    },

    externalLogin(provider) {
      switch (provider) {
        case 'apple':
          this.appleLogin(); // from apple mixin
          break;
        case 'facebook':
          this.facebookLogin(); // from facebook mixin
          break;
        case 'google':
          this.googleLogin(); // from google mixin
          break;
      }
    }
  }
}
</script>
