import {createI18n} from 'vue-i18n';

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages () {
    // const locales = require.context('@/_locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const locales = require.context('@/_locales', true, /[a-z0-9-_]+\.json$/i)
    const messages = {}
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        const segments = key.split('/');
        if (matched && matched.length > 1) {
            const locale = segments[1];
            if (!messages[locale]) {
                messages[locale] = {};
            }
            messages[locale][segments[2].split('.')[0]] = locales(key);
        }
    });

    return messages
}

const i18n = createI18n({
    locale: cookies.get('locale') || process.env.VUE_APP_FALLBACK_LOCALE,
    messages: loadLocaleMessages(),
    fallbackLocale: process.env.VUE_APP_FALLBACK_LOCALE,
    warnHtmlInMessage: 'off',
    warnHtmlMessage: false
});

export default i18n;
