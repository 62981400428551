export const display = {
    mounted() {
        window.addEventListener('resize', this.updateDisplaySize);
        this.updateDisplaySize();

        let prevScrollPos = window.pageYOffset;
        let thisElem = this;
        document.addEventListener('scroll', function () {
            let currentScrollPos = window.pageYOffset;
            thisElem.currentScrollTop = currentScrollPos;
            thisElem.isScrollingUp = prevScrollPos > currentScrollPos;
            prevScrollPos = currentScrollPos;
        });
    },
    data() {
        return {
            clientWidth: 360,
            displayBreakpoint: 'xs',
            xsOnly: true,
            lgAndUp: false,
            notificationPosition: 'bottom-right',
            notificationDuration: 0,
            isScrollingUp: false,
            currentScrollTop: 0,
        };
    },
    computed: {
        tagSize() {
            return this.xsOnly ? 'default' : 'small';
        },
        inputsSize() {
            return this.xsOnly ? 'default' : 'small';
        },
        webview() {
            let standalone = window.navigator.standalone,
                userAgent = window.navigator.userAgent.toLowerCase(),
                safari = /safari/.test(userAgent),
                ios = /iphone|ipod|ipad/.test(userAgent);

            if (ios) {
                if (!standalone && safari) {
                    // Safari
                    return false;
                } else if (!standalone && !safari) {
                    // iOS webview
                    return 'ios';
                }
            } else {
                if (userAgent.includes('wv')) {
                    // Android webview
                    return 'android';
                } else {
                    // Chrome
                    return false;
                }
            }
        }
    },
    methods: {
        updateDisplaySize() {
            let width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
            this.clientWidth = width;
            let size = 'xs';

            if (width >= 1400) {
                size = 'xl';
            } else if (width >= 1200) {
                size = 'lg';
            } else if (width >= 992) {
                size = 'md';
            } else if (width >= 768) {
                size = 'sm';
            } else if (width < 768) {
                size = 'xs';
            }

            this.displayBreakpoint = size;
            this.xsOnly = ['xs'].includes(size);
            this.lgAndUp = ['xl', 'lg'].includes(size);
        }
    },
};
