<template>
  <div>
    <el-scrollbar :native="false">
      <div class="feed-filter">
        <div class="filter-icon" v-if="xsOnly" @click="filterDialogVisible = true">
          <i class="icon-filter"></i>
        </div>

        <el-tag v-for="(tabName, tabKey) in tabs"
                :key="tabKey"
                :class="{'cursor-pointer': tabKey !== feedTab}"
                :effect="tabKey === feedTab ? 'dark' : 'plain'"
                :size="tagSize"
                @click="changeTab(tabKey)">
          {{ tabName }}
        </el-tag>
      </div>
    </el-scrollbar>

    <el-dialog
        class="feed-filter-dialog"
        width="100vw"
        :show-close="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        v-model="filterDialogVisible">

      <filters-list></filters-list>

      <div class="text-center">
        <el-button type="primary" @click="filterDialogVisible = false" v-t="'filters.show_result'"></el-button>
      </div>

      <template v-slot:header>
        <i class="icon-arrow_back float-start" @click="filterDialogVisible = false"></i>
        <span v-t="'filters.filter'"></span>
      </template>

    </el-dialog>
  </div>
</template>

<script>
import FiltersList from "@/components/left_bar/filters/FiltersList";
import {needAuth} from "@/mixins/auth/needAuth";

export default {
  name: "tabs",
  mixins: [needAuth],
  components: {
    FiltersList,
  },
  emits: ["change"],
  props: {
    tabs: {
      type: Object,
      default() {
        return {
          'default': 'Visión General',
          'favourites': 'Favoritos',
        }
      }
    },
    default: {
      type: String,
      default: 'default'
    },
    blockForGuest: {
      type: Boolean,
      default: true
    },
    changeQuery: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      filterDialogVisible: false,
      feedTab: _.has(this.tabs, this.default) ? this.default : 'default', // default,my-feed,most-discount,favourites,most-hits,last-7-days
    }
  },
  methods: {
    changeTab(newKey) {
      if (this.blockForGuest && this.triggerLoginDialog()) {
        return;
      }

      if (this.changeQuery) {
        let query = _.clone(this.$router.currentRoute.value.query);
        query = _.omit(query, ['tab']);
        if (newKey !== 'default') {
          query['tab'] = newKey;
        }
        this.$router.replace({query: query});
      }

      this.feedTab = newKey;
      this.$emit('change', newKey);
    }
  }
}
</script>
