const getDefaultState = () => {
    return {
        stores: {
            all: [],
            promise: null
        },
        currentStore: null
    }
};

export default {
    namespaced: true,
    state: () => (getDefaultState()),

    getters: {
        STORES(state) {
            return state.stores.all;
        },
        STORES_WITH_FOLDERS(state) {
            return _.sortBy(_.filter(state.stores.all, function (need) {
                return need.folders_count > 0;
            }), 'folders_count').reverse();
        },
        CURRENT_STORE(state) {
            return state.currentStore;
        }
    },

    actions: {
        async FETCH_STORES({state, commit}) {
            if (state.stores.promise) {
                return state.stores.promise;
            }

            let promise = axios.get('/stores').then((response) => {
                commit('UPDATE_STORES', response.data.data);
            }).catch((e) => {
                console.log(e);
            }).then(() => {
                commit('UPDATE_PROMISE_STORES', null);
            });

            commit('UPDATE_PROMISE_STORES', promise);
            return promise;
        },
        async FETCH_CURRENT_STORE({state, commit, dispatch}, route) {
            return axios.get('/store/' + route).then((response) => {
                commit('UPDATE_CURRENT_STORE', response.data.data);
            });
        },
        RESET_STORES({commit}) {
            commit('SET_DEFAULT_DATA');
        },
        SET_SUBSCRIPTION({state, commit}, store_id) {
            return axios.put(`/stores/${store_id}/subscribe`).then((response) => {
                commit('UPDATE_CURRENT_STORE', response.data.data);
            });
        },
        REMOVE_SUBSCRIPTION({state, commit}, store_id) {
            return axios.put(`/stores/${store_id}/unsubscribe`).then((response) => {
                commit('UPDATE_CURRENT_STORE', response.data.data);
            });
        },
    },

    mutations: {
        UPDATE_STORES(state, data) {
            return state.stores.all = data;
        },

        UPDATE_PROMISE_STORES(state, data) {
            return state.stores.promise = data;
        },

        UPDATE_CURRENT_STORE(state, data) {
            return state.currentStore = data;
        },

        SET_DEFAULT_DATA(state) {
            return _.assign(state, getDefaultState());
        },
    }
}
