<template>
  <div class="bg-white" :style="feedFilterStyle" ref="feed_filter_container">
    <tabs :tabs="tabs" @change="tabChange" :default="defaultTab" :change-query="true"></tabs>
  </div>
  <div v-if="showFixedFeedFilter" :style="{height: this.feedFilterHeight + `px`}"></div>
</template>

<script>
import Tabs from "@/components/feed/Tabs";

export default {
  name: "feed-filter",
  emits: ["tab-changed"],
  components: {Tabs},
  props: {
    defaultTab: {
      type: String,
      default: 'default'
    },
  },
  data() {
    return {
      filterDialogVisible: false,
      feedFilterHeight: 0,
      feedFilterStyle: {}
    }
  },
  mounted() {
    this.feedFilterHeight = this.$refs.feed_filter_container.clientHeight;
  },
  computed: {
    tabs() {
      let tabs = {};
      tabs["default"] = this.$t('search.home-tabs.default');
      tabs["last-created"] = this.$t('search.home-tabs.last-created');
      tabs["more-hits"] = this.$t('search.home-tabs.more-hits');
      tabs["more-comments"] = this.$t('search.home-tabs.more-comments');
      tabs["favourites"] = this.$t('search.home-tabs.favourites');
      return tabs;
    },
    showFixedFeedFilter() {
      return this.xsOnly && this.currentScrollTop > 500 && this.isScrollingUp;
    }
  },
  methods: {
    tabChange(tabKey) {
      this.$emit('tab-changed', tabKey);
    },
  },
  watch: {
    showFixedFeedFilter(value) {
      if (!value) {
        this.feedFilterStyle = {};
        return;
      }
      this.feedFilterStyle = {
        'width': '100vw',
        'position': `fixed`,
        'top': `45px`,
        'z-index': 10,
        'padding-top': '10px',
        'transition': 'top 0.3s',
      };
    }
  }
}
</script>
