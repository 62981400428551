<template>
  <base-filter ref="filter"
               :filter-label="$t('filters.brands')"
               :filter-options="filterOptions"
               :default-checked="[]"
               filter-key="brands_filter"
               @filter="filter">
  </base-filter>
</template>

<script>
import BaseFilter from "@/components/left_bar/filters/BaseFilter";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "brands-filter",
  components: {BaseFilter},
  computed: {
    ...mapGetters('promo', [
      'PROMOS_BRANDS',
    ]),

    filterOptions() {
      let resultOptions = {};
      _.forEach(this.PROMOS_BRANDS, function (brand) {
        resultOptions[brand.id] = brand.translation.title;
      });
      return resultOptions;
    }
  },
  mounted() {
    let defaultFilterValue = this.$route.query['brands_filter[]']
    if (!_.isEmpty(defaultFilterValue)) {
      this.$refs.filter.setCheckedList(_.castArray(defaultFilterValue));
    }
  },
  methods: {
    ...mapActions('filter', [
      'SET_FILTER_VALUE',
    ]),

    filter(checked) {
      this.SET_FILTER_VALUE({brands_filter: checked});
    }
  }
}
</script>
