<template>
  <div class="main-menu">
      <div class="menu-header-wrapper">
        <span class="main-menu-header" v-t="'menu.main.menu'"/>
      </div>
    <div class="menu-item-container">
      <div class="main-menu-item" v-if="!GUEST">
        <router-localized-link class="text-dark" v-t="'menu.main.home'" :to="{name: 'home'}"/>
      </div>

      <div class="main-menu-item" v-if="!GUEST">
        <router-localized-link class="text-dark" v-t="'menu.main.prizes'" :to="{name: 'prizes-pool'}"/>
      </div>

      <div v-if="GUEST" class="main-menu-item">
        <router-localized-link class="text-dark" v-t="'menu.main.who_is_chollofinal'" :to="getPagePathBySlug('wie-of-wat-zijn-promojagers')"/>
      </div>

      <div v-if="!GUEST" class="main-menu-item" @click="goReferralPage">
        <span v-t="'menu.main.invite_friend'"/>
        <span class="text-danger ms-1">{{ referralReward }}</span>
      </div>

      <el-divider class="my-2"/>

      <div v-if="GUEST" class="main-menu-item">
        <router-localized-link class="text-dark" v-t="'menu.user.about_points'" :to="getPagePathBySlug('bonus-points')"/>
      </div>
      <div class="main-menu-item">
        <router-localized-link class="text-dark" v-t="'menu.main.contact'" :to="getPagePathBySlug('contact')"/>
      </div>

      <el-divider class="my-2"/>
      <div v-if="GUEST" class="main-menu-item" @click="login" v-t="'menu.main.login'"/>
      <div v-else class="main-menu-item" @click="logout" v-t="'menu.main.logout'"/>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {needAuth} from "@/mixins/auth/needAuth";
import RouterLocalizedLink from "@/components/common/RouterLocalizedLink";

export default {
  name: "main-menu",
  components: {RouterLocalizedLink},
  mixins: [needAuth],
  emits: ['login'],
  mounted() {
    if (!this.BONUS_ACTIONS.length) {
      this.FETCH_BONUS_ACTIONS();
    }
  },
  computed: {
    ...mapGetters('bonus_action', [
      'BONUS_ACTIONS',
      'BONUS_ACTION_TYPES',
    ]),
    ...mapGetters('page', [
      'PAGES',
      'PAGES_LOADED',
    ]),
    ...mapGetters('auth', [
      'GUEST',
      'LOCALE',
    ]),

    referralReward() {
      let action = this.BONUS_ACTIONS.filter(need => need.type === this.BONUS_ACTION_TYPES['referrals-first-post-approved'])[0];
      if (!action) {
        return '';
      }
      return '(+' + action.impact_on_balance + ')';
    },

    pagesInMenu() {
      let thisElem = this;
      return _.filter(this.PAGES, function (page) {
        return page.in_menu && thisElem.$i18n.locale === page.locale.slug;
      })
    }
  },
  beforeMount() {
    if (!this.PAGES_LOADED) {
      this.FETCH_PAGES();
    }
  },
  methods: {
    ...mapActions('page', [
      'FETCH_PAGES'
    ]),
    ...mapActions('auth', [
      'LOGOUT',
    ]),
    ...mapActions('bonus_action', [
      'FETCH_BONUS_ACTIONS',
    ]),

    logout() {
      this.LOGOUT().then(() => {
        this.routerPush({name: 'home'})
      })
    },

    login() {
      this.$emit('login')
    },

    goReferralPage() {
      if (this.triggerLoginDialog()) {
        return;
      }
      this.routerPush({name: 'referral_page'});
    },
  }
}
</script>
