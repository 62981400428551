<template>
  <div class="mx-1 mx-md-5">
    <div class="folder-pager" ref="page_container">
      <Carousel :mouse-drag="false" ref="folder_carousel">
        <Slide v-for="(pagesChunk, index) in pagesSlides" :key="index">
          <div class="carousel__item d-flex justify-content-center align-items-center">
            <div v-for="(page, pageIndex) in pagesChunk" :key="pageIndex">
              <folder-page-zoomer
                  :show-map="CURRENT_STORE.is_paid_client"
                  :carousel-index="carouselIndex"
                  :image="page.image_url"
                  :items="page.items"></folder-page-zoomer>
            </div>
          </div>
        </Slide>

        <template #addons>
          <Navigation/>
        </template>
      </Carousel>
    </div>

    <div class="folder-pager-slider">
      <el-slider v-model="sliderIndex"
                 :show-tooltip="false"
                 :step="1"
                 :min="0"
                 :max="pagesSlides.length - 1"
                 :marks="marks"
                 @change="changePage"
                 show-stops/>
    </div>

    <div class="my-3" v-if="PROMOS.length">
      <folder-promos-carousel :title="$t('folder.promos_from_this_page')"
                              :promos="PROMOS"
                              @load="loadPromos"
                              child-class="bg-folder-secondary"></folder-promos-carousel>
    </div>
  </div>
</template>

<script>
import {Carousel, Slide, Navigation} from 'vue3-carousel';
import {mapActions, mapGetters} from "vuex";
import FolderPageZoomer from "@/components/feed/folder/FolderPageZoomer";
import FolderPromosCarousel from "@/components/feed/folder/FolderPromosCarousel";

export default {
  name: "folder-pager",
  components: {FolderPromosCarousel, FolderPageZoomer, Carousel, Slide, Navigation},
  data() {
    return {
      sliderIndex: 0,
      marks: {},
      carouselIndex: 0,
      viewedPages: []
    };
  },
  computed: {
    ...mapGetters('store', [
      'CURRENT_STORE',
    ]),
    ...mapGetters('folder', [
      'CURRENT_FOLDER',
    ]),
    ...mapGetters('promo', [
      'PROMOS',
    ]),
    allPages() {
      return this.CURRENT_FOLDER.pages;
    },
    singlePageSlides() {
      let pages = _.sortBy(this.allPages, 'page');
      return _.chunk(pages, 1);
    },
    pagesSlides() {
      return this.xsOnly ? this.singlePageSlides : this.duoPageSlides;
    },
    duoPageSlides() {
      let pages = _.sortBy(this.allPages, 'page');
      return _.union([_.take(pages, 1)], _.chunk(_.slice(pages, 1), 2));
    }
  },
  mounted() {
    this.$watch(() => {
      return this.$refs.folder_carousel.data.currentSlide.value;
    }, (val) => {
      this.carouselIndex = val;
    });
    this.prepareMarks();
    this.resolveStartingSlide();
  },
  methods: {
    ...mapActions('promo', [
      'FETCH_PROMOS',
      'RESET_PROMOS',
    ]),

    resolveStartingSlide() {
      let startingIndex = 0;
      let startingPage = _.get(this.$router.currentRoute.value.query, 'page');
      if (!_.isEmpty(startingPage)) {
        startingIndex = _.findIndex(this.pagesSlides, function (need) {
          return _.size(_.filter(need, (o) => o.page == startingPage)) > 0;
        });
      }
      if (startingIndex > 0) {
        this.changePage(startingIndex);
      } else {
        this.loadPromos();
      }
    },

    loadPromos() {
      let pageIds = _.map(this.pagesSlides[this.carouselIndex], 'id');
      this.FETCH_PROMOS({
        morphable_type: 'FolderItem',
        folder_id: this.CURRENT_FOLDER.id,
        folder_page_ids: pageIds,
        include_filters: []
      });
    },

    changePage(value) {
      this.$refs.folder_carousel.slideTo(value);
    },

    prepareMarks() {
      let thisElem = this;
      this.marks = {};

      let chunk = Math.floor(this.pagesSlides.length / (this.xsOnly ? 5 : 10));
      chunk = _.max([chunk, 1]);
      _.forEach(this.pagesSlides, function (slide, key) {
        if ((key + 1) % chunk === 0) {
          thisElem.marks[key] = {
            label: _.join(_.map(slide, 'page'), '-'),
            style: {
              'white-space': 'nowrap'
            }
          };
        }
      });
    }
  },
  watch: {
    carouselIndex(newIndex) {
      let currentPage = _.get(_.first(_.get(this.pagesSlides, newIndex)), 'page');
      let query = _.clone(this.$router.currentRoute.value.query);
      query['page'] = currentPage;
      this.$router.replace({query: query});

      this.sliderIndex = newIndex;
      this.RESET_PROMOS();
      this.loadPromos();
    }
  }
}
</script>
