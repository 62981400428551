import store from '@/store';

export default async (to) => {
    await store.dispatch('webshop/RESET_WEBSHOPS');
    await store.dispatch('webshop/FETCH_CURRENT_WEBSHOP', to.params.webshopSlug);

    if (_.isNil(store.getters['webshop/CURRENT_WEBSHOP'])) {
        return {name: 'webshops'};
    }
};
