<template>
  <div v-if="Object.keys(filterOptions).length">
    <el-divider class="bold-divider my-3"></el-divider>
    <div class="filter-container">
      <div class="filter-header" @click="openFilter">
        <i :class="filterOpened ? 'icon-minus' : 'icon-plus-11'"></i>
        <span class="cursor-pointer" v-text="filterLabel"></span>
      </div>
      <div class="filter-options" v-if="filterOpened">
        <el-checkbox-group v-model="checkedList" :max="max" @change="filterEvent">
          <div v-for="(filterOption, i) in filtersList" :key="i">
            <el-checkbox :key="i" :label="filterOption.key" class="big">{{ filterOption.label }}</el-checkbox>
          </div>
        </el-checkbox-group>

        <div class="mt-2">
        <span class="text-danger cursor-pointer"
              v-if="shortLength < Object.keys(filterOptions).length"
              v-text="shortFilters ? $t('filters.show_more') : $t('filters.show_less')"
              @click="shortFilters = !shortFilters"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {needAuth} from "@/mixins/auth/needAuth";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "base-filter",
  mixins: [needAuth],
  props: {
    filterKey: {
      type: String,
    },
    filterLabel: {
      type: String,
      default: '',
    },
    filterOptions: Object,
    defaultChecked: {
      type: Array,
      default() {
        return []
      }
    },
    max: {
      type: Number,
      default: undefined,
    },
  },
  computed: {
    ...mapGetters('filter', [
      'OPENED_FILTERS',
    ]),
    filtersList() {
      if (_.isArray(this.filterOptions)) {
        return this.filterOptions;
      }

      let thisElem = this;
      let keys = Object.keys(this.filterOptions);
      let resultArray = [];
      _.forEach(keys, function (key) {
        resultArray.push({
          key: key,
          label: thisElem.filterOptions[key],
        });
      });

      resultArray = _.sortBy(resultArray, 'label');
      if (this.shortFilters) {
        resultArray = _.take(resultArray, this.shortLength);
      }

      return resultArray;
    },
    filterOpened() {
      return _.get(this.OPENED_FILTERS, this.filterKey, true);
    }
  },
  data() {
    return {
      shortFilters: true,
      shortLength: 6,
      checkedList: this.defaultChecked,
    };
  },
  methods: {
    ...mapActions('filter', [
      'SET_FILTER_OPENED',
    ]),
    filterEvent() {
      if (this.triggerLoginDialog()) {
        setTimeout(() => {
          this.checkedList = [];
        }, 1000);
        return;
      }
      this.$emit('filter', this.checkedList);
    },
    setCheckedList(values) {
      this.checkedList = values;
    },
    openFilter() {
      let filter = {};
      _.set(filter, this.filterKey, !this.filterOpened);
      this.SET_FILTER_OPENED(filter);
    }
  }
}
</script>
