export const breadcrumbs = {
    methods: {
        getBreadcrumbStructure() {
            let breadcrumb = this.$route.meta.breadcrumb();
            let itemListElement = [];
            _.forEach(breadcrumb, function (item, i) {
                itemListElement.push({
                    "@type": "ListItem",
                    "position": i + 1,
                    "name": _.get(item, 'text', ''),
                    "item": _.get(item, 'href', ''),
                });
            });

            return {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": itemListElement,
            }
        }
    },
};
