export const folders = {
    mounted(){
        document.addEventListener("DOMContentLoaded", function() {
            // Folders carousel scroll feature
            let foldersNextArrows = document.getElementsByClassName("folders-carousel-arrow");
            let foldersArrowNextClicked = function(event) {
                let target = event.target;
                let scrollBlock = target.closest(".folders-carousel-container").querySelector('.el-scrollbar__wrap');
                scrollBlock.scrollLeft += 230;
                let arrowPrev = target.closest(".folders-carousel-container").querySelector('.folders-carousel-arrow-back');
                arrowPrev.classList.remove('d-none');
            };
            for (let i = 0; i < foldersNextArrows.length; i++) {
                foldersNextArrows[i].addEventListener('click', foldersArrowNextClicked, false);
            }

            let foldersPrevArrows = document.getElementsByClassName("folders-carousel-arrow-back");
            let foldersArrowPrevClicked = function(event) {
                let target = event.target;
                let scrollBlock = target.closest(".folders-carousel-container").querySelector('.el-scrollbar__wrap');
                scrollBlock.scrollLeft -= 230;
            };
            for (let i = 0; i < foldersPrevArrows.length; i++) {
                foldersPrevArrows[i].addEventListener('click', foldersArrowPrevClicked, false);
            }

            // Folder promos carousel scroll feature
            let foldersPromosNextArrows = document.getElementsByClassName("folder-promos-carousel-arrow");
            let folderPromosArrowNextClicked = function(event) {
                let target = event.target;
                let scrollBlock = target.closest(".folder-promos-carousel-container").querySelector('.el-scrollbar__wrap');
                scrollBlock.scrollLeft += 230;
                let arrowPrev = target.closest(".folder-promos-carousel-container").querySelector('.folder-promos-carousel-arrow-back');
                arrowPrev.classList.remove('d-none');
            };
            for (let i = 0; i < foldersPromosNextArrows.length; i++) {
                foldersPromosNextArrows[i].addEventListener('click', folderPromosArrowNextClicked, false);
            }

            let foldersPromosPrevArrows = document.getElementsByClassName("folder-promos-carousel-arrow-back");
            let folderPromosArrowPrevClicked = function(event) {
                let target = event.target;
                let scrollBlock = target.closest(".folder-promos-carousel-container").querySelector('.el-scrollbar__wrap');
                scrollBlock.scrollLeft -= 230;
            };
            for (let i = 0; i < foldersPromosPrevArrows.length; i++) {
                foldersPromosPrevArrows[i].addEventListener('click', folderPromosArrowPrevClicked, false);
            }
        });
    }
};
