<template>
  <div class="news-category-feed pb-5 px-2">
    <div class="category-container">
      <div class="d-flex">
        <div class="category-image-container pb-1" v-if="CURRENT_CATEGORY.image_url">
          <img :src="CURRENT_CATEGORY.image_url"
               :alt="CURRENT_CATEGORY.translation.title"
               :title="CURRENT_CATEGORY.translation.title"
               class="category-image"
          >
        </div>
        <div class="category-info-container">
          <div class="category-title pt-2 pb-1" v-html="CURRENT_CATEGORY.translation.title"></div>
          <div class="category-headline font-weight-bold" v-html="CURRENT_CATEGORY.translation.headline"></div>
        </div>
      </div>
    </div>
    <el-row type="flex" justify="start">
      <template v-for="(article, i) in CURRENT_CATEGORY_ARTICLES" :key="i">
        <news-preview :article="article" :layout="dlayout[i]"></news-preview>
        <el-col v-if="i == 3" :xs="24" :sm="24" :md="8" :lg="12" :xl="8">
          <div class="article-ad-container">
            <single-advertiser></single-advertiser>
          </div>
        </el-col>
      </template>
    </el-row>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import NewsPreview from "@/components/feed/news/NewsPreview";
import SingleAdvertiser from '@/components/feed/news/SingleAdvertiser';

export default {
  name: "NewsByCategoryFeed",
  components: {
    NewsPreview,
    SingleAdvertiser
  },
  data() {
    return {
      //TODO -- Need optimize
      dlayout: [
        {col_lg: 8, col_xl: 8, title_class: 'out', img_class: 'middle'},
        {col_lg: 8, col_xl: 8, title_class: 'out', img_class: 'middle'},
        {col_lg: 8, col_xl: 8, title_class: 'out', img_class: 'middle'},
        {col_lg: 16, col_xl: 16},
        {col_lg: 8, col_xl: 8, title_class: 'sl'},
        {col_lg: 16, col_xl: 16, title_class: ''},
        {col_xl: 24, container_class: 'md-container', title_class: 't-right'},
        {col_lg: 8, col_xl: 8, title_class: 'out', img_class: 'middle'},
        {col_lg: 8, col_xl: 8, title_class: 'out', img_class: 'middle'},
        {col_lg: 8, col_xl: 8, title_class: 'out', img_class: 'middle'},
        {col_lg: 24, col_xl: 24, title_class: 't-right'},
      ]
    }
  },
  computed: {
    ...mapGetters('news_category', [
      'CURRENT_CATEGORY',
      'CURRENT_CATEGORY_ARTICLES'
    ]),
  },
  methods: {
    //
  },
}
</script>
