<template>
  <div>
    <div class="main-container-wrapper d-flex justify-content-center align-items-center">
      <div class="font-size-40 text-danger" v-loading="true">
        REDIRECTING...
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GoToView",
  beforeMount() {
    axios.post('/goto', {key: this.$route.params.key}).then((response) => {
      window.open(response.data.link, '_self');
    });
  }
}
</script>
