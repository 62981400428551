<template>
  <div class="feed-container">
    <div class="mb-2">
      <h4 class="text-uppercase title" v-html="pageObject.title"></h4>
    </div>
    <div v-html="pageObject.content"></div>
  </div>
</template>

<script>
import {useHead} from "@vueuse/head";

export default {
  name: "page",
  props: ['pageObject'],

  created() {
    let title = this.pageObject.tag_title;
    let description = this.pageObject.meta_description;
    useHead({
      title: title,
      meta: [
        {
          name: `title`,
          content: title,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:description`,
          content: description,
        },
      ],
    })
  },
  mounted() {
    //
  }
}
</script>
