<template>
  <span></span>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "init-social-register",
  props: {
    socialUser: Object,
  },
  mounted() {
    // Works only for Facebook
    if (!_.isEmpty(this.socialUser, '_provider')) {
      let provider = _.get(this.socialUser, '_provider');
      let accessToken = _.get(this.socialUser, 'accessToken');

      setTimeout(() => {
        this.SET_LOADER(true);
        this.emitter.emit("show-login-dialog");
      }, 800);

      setTimeout(() => {
        this.emitter.emit('try-register', {provider: provider, options: {access_token: accessToken}});
      }, 1600);
    }
  },
  methods: {
    ...mapActions('loader', [
      'SET_LOADER',
    ])
  }
}
</script>
