<template>
  <div class="filter-container">
    <div class="filter-header" @click="filterActive = !filterActive">
      <i :class="filterActive ? 'icon-minus' : 'icon-plus-11'"></i>
      <span class="cursor-pointer" v-t="'filters.keyword'"></span>
    </div>
    <div class="filter-options mt-2" v-if="filterActive">
      <div class="d-flex justify-content-between align-items-center">
        <div class="me-1">
          <el-input class="rounded-input"
                    v-model="input"
                    :placeholder="$t('filters.search')"
                    @keydown.enter="filterKeyword"
          ></el-input>
        </div>
        <el-button class="p-2" type="primary" size="small" @click="filterKeyword">
          <i class="el-icon-search font-size-20"></i>
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {needAuth} from "@/mixins/auth/needAuth";

export default {
  name: "search-filter",
  mixins: [needAuth],
  data() {
    return {
      filterActive: true,
      input: '',
    };
  },
  mounted() {
    let defaultFilterValue = this.$route.query['search_filter']
    if (defaultFilterValue !== undefined) {
      this.input = defaultFilterValue;
    }
  },
  methods: {
    ...mapActions('filter', [
      'SET_FILTER_VALUE',
    ]),

    filterKeyword() {
      if (this.triggerLoginDialog()) {
        return;
      }
      this.SET_FILTER_VALUE({search_filter: this.input});
    }
  }
}
</script>
