const dates = {
    currentDateFormatted() {
        let date = new Date();
        return ('0' + date.getDate()).slice(-2) + '/'
            + ('0' + (date.getMonth() + 1)).slice(-2) + '/'
            + ('' + date.getFullYear());
    },
    // same as \Str::ucfirst(now()->translatedFormat('F Y'))
    currentDateFY() {
        let date = new Date();
        let month = date.toLocaleString('default', {month: 'long'});
        let year = date.getFullYear();
        return month + ' ' + year;
    },
    // Y-m-d to d/m/Y
    dateToFormatted(date){
        let d = new Date(Date.parse(date)),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) {
            month = "0" + month;
        }
        if (day.length < 2) {
            day = "0" + day;
        }

        return [day, month, year].join("/");
    }
};

export default dates;
