export const autocompelete = {
    data() {
        return {
            suggestions: [],
            keywords_to_ignore_when_search: {
                folder: ['folder', 'folders', 'reclamefolder', 'depliant', 'depliants', 'déplians', 'dépliants', 'dépliant']
            }
        };
    },
    mounted() {
        this.$refs.autocomplete.inputRef.$el.addEventListener('input', (text) => {
            let query = text.target.value;
            this.$refs.autocomplete.focus();
            this.query = query;
            this.getSuggestions(query).then(() => {
                this.$refs.autocomplete.getData(query);
            });
        });
    },
    methods: {
        getSuggestions(queryString) {
            let thisElem = this;
            if (queryString.length < 2) {
                this.suggestions = [];
                return Promise.resolve();
            }
            let groupsPriority = {
                folder: 1,
                store: 2,
                webshop: 3,
                product: 4,
                brand: 5,
            };
            return axios.post('/search/suggest', {search: queryString}).then((data) => {
                let result = [];

                _.forEach(_.keys(data.data), function (groupKey) {
                    _.forEach(data.data[groupKey], function (item) {
                        result.push({
                            groupKey: groupKey,
                            title: item.title,
                            url: item.url,
                            group: thisElem.$t('search.autosuggest.types.' + groupKey),
                            priority: groupsPriority[groupKey],
                        });
                    });
                });
                this.suggestions = result;
            });
        },
        querySearch(queryString, cb) {
            if (queryString.length < 2) {
                cb([]);
                return;
            }

            let suggestions = _.sortBy(this.suggestions, function (need) {
                let result = need.title.toLowerCase().indexOf(queryString.toLowerCase());
                result = result * 100;
                result = result + need.priority;

                return result;
            });

            cb(suggestions);
        },
        triggerSuggest(query) {
            let thisElem = this;
            let triggered = false;
            _.forEach(thisElem.keywords_to_ignore_when_search, function (keywords, groupKey){
                let keywordFound = keywords.some(word => query.includes(word));
                let suggest = thisElem.suggestions.find(item => item.groupKey === groupKey);
                if (suggest && keywordFound) {
                    thisElem.$router.push(suggest.url);
                    triggered = true;
                    return false; // break foreach
                }
            });
            return triggered;
        },
        handleSelect(item) {
            if (this.triggerLoginDialog()) {
                return;
            }
            this.$router.push(item.url);
        },
    },
};
