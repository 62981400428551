<template>
  <div v-if="show404">
    <div class="main-container-wrapper d-flex justify-content-center align-items-center">
      <div class="font-size-40 text-danger">
        404 | NOT FOUND
      </div>
    </div>
  </div>

  <default-grid v-else>
    <template v-slot:left-container v-if="showLeftBarUserMenu">
      <left-bar show-user-menu></left-bar>
    </template>
    <template v-slot:main-container>
      <page v-if="pageObject" :page-object="pageObject"></page>
    </template>
  </default-grid>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import DefaultGrid from "@/views/DefaultGrid";
import Page from "@/components/feed/Page";
import LeftBar from "@/components/left_bar/LeftBar";

export default {
  name: "PageNotFoundHandlerView",
  components: {LeftBar, Page, DefaultGrid},
  data() {
    return {
      pageObject: false,
      route: 404,
      show404: false,
    }
  },
  computed: {
    ...mapGetters('page', [
      'PAGES',
      'PAGES_LOADED',
    ]),
    showLeftBarUserMenu() {
      return ['bonus-points'].includes(_.get(this.pageObject, 'slug'));
    }
  },
  mounted() {
    if (this.PAGES_LOADED) {
      this.getPageObject();
      return;
    }

    this.FETCH_PAGES().then(() => {
      this.getPageObject();
    })
  },
  methods: {
    ...mapActions('page', [
      'FETCH_PAGES'
    ]),

    getPageObject() {
      let thisElem = this;
      let route = _.get(this.$route.params, 'pathMatch[0]', false);
      if (!route) {
        this.show404 = true;
        return;
      }
      this.route = route;
      this.pageObject = _.find(this.PAGES, function (need) {
        return need.route === thisElem.route && need.locale.slug === thisElem.$i18n.locale;
      });
      if (!this.pageObject) {
        this.show404 = true;
      }
    }
  },
  watch: {
    $route(to, from) {
      this.getPageObject();
    }
  }
}
</script>
