<template>
  <div class="user-menu">
    <div class="text-center">
      <div class="user-menu-header">
        <router-localized-link class="text-dark" :to="{name: 'prizes-pool'}">
          {{ $t('menu.user.points_header', {name: USER.fname, points: USER.account.available}) }}
        </router-localized-link>
      </div>

      <el-divider class="my-3"></el-divider>

      <div class="user-menu-item">
        <router-localized-link :to="{name: 'favourites'}">
          <i class="icon-heart-2"></i> <span v-t="'menu.user.favourites'"></span>
        </router-localized-link>
      </div>

      <div class="user-menu-item">
        <router-localized-link :to="{name: 'prizes-pool'}">
          <i class="icon-cup"></i> <span v-t="'menu.main.prizes'"></span>
        </router-localized-link>
      </div>

      <el-divider class="my-3"></el-divider>

      <div class="user-menu-item">
        <router-localized-link class="text-dark" :to="{name: 'referral_page'}">
          <span v-t="'menu.main.invite_friend'"></span>
          <span class="text-danger ms-1">
            {{ referralReward }}
          </span>
        </router-localized-link>
      </div>

      <el-divider class="my-3"></el-divider>

      <div class="user-menu-item">
        <router-localized-link class="text-dark" v-t="'menu.user.about_points'" :to="getPagePathBySlug('bonus-points')"/>
      </div>

      <div class="user-menu-item" v-t="'menu.user.settings'" @click="$emit('userSettings')"></div>

      <template v-for="(page, i) in pagesInMenu" :key="i">
        <div class="user-menu-item">
          <router-localized-link class="text-dark" :to="{path: page.route}">
            {{ page.title }}
          </router-localized-link>
        </div>
      </template>

      <div class="user-menu-item">
        <router-localized-link class="text-dark" v-t="'menu.main.contact'" :to="getPagePathBySlug('contact')"/>
      </div>

      <el-divider class="my-3"></el-divider>

      <div class="user-menu-item" @click="logout" v-t="'menu.main.logout'"></div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import RouterLocalizedLink from "@/components/common/RouterLocalizedLink";

export default {
  name: "user-menu",
  emits: ['userSettings'],
  components: {RouterLocalizedLink},
  data() {
    return {
      //
    }
  },
  mounted() {
    if (!this.BONUS_ACTIONS.length) {
      this.FETCH_BONUS_ACTIONS();
    }
  },
  computed: {
    ...mapGetters('bonus_action', [
      'BONUS_ACTIONS',
      'BONUS_ACTION_TYPES',
    ]),
    ...mapGetters('auth', [
      'USER',
      'GUEST',
      'LOCALE',
    ]),
    ...mapGetters('page', [
      'PAGES',
    ]),

    referralReward() {
      let action = this.BONUS_ACTIONS.filter(need => need.type === this.BONUS_ACTION_TYPES['referrals-first-post-approved'])[0];
      if (!action) {
        return '';
      }
      return '(+' + action.impact_on_balance + ')';
    },

    pagesInMenu() {
      let thisElem = this;
      return _.filter(this.PAGES, function (page) {
        return page.in_menu && thisElem.$i18n.locale === page.locale.slug;
      })
    }
  },
  methods: {
    ...mapActions('bonus_action', [
      'FETCH_BONUS_ACTIONS',
    ]),
    ...mapActions('loader', [
      'SET_LOADER',
    ]),
    ...mapActions('auth', [
      'LOGOUT',
      'UPDATE_USER',
    ]),

    logout() {
      this.LOGOUT().then(() => {
        this.routerPush({name: 'home'})
      })
    },
  }
}
</script>
