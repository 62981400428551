<template>
  <default-grid>
    <template v-slot:left-container>
      <left-bar show-product-logo></left-bar>
    </template>
    <template v-slot:main-container>
      <div class="promos-feed">
        <h1 class="text-capitalize text-danger font-size-26 my-3" v-text="h1Text"></h1>

        <tabs :tabs="tabs"
              :default="currentTab"
              :block-for-guest="false"
              :change-query="true"
              @change="tabChange" ></tabs>

        <el-row type="flex" justify="start" class="secondary-feed" v-if="['online-promos', 'folder-promos'].includes(currentTab)">
          <template v-for="(promo, i) in PROMOS" :key="i">
            <el-col :xs="12" :sm="8" :md="8" :lg="12" :xl="8">
              <folder-promo-preview v-if="'FolderItem' === promo.morphable_type"
                                    :folder-promo="promo.data"></folder-promo-preview>
              <online-promo-preview v-if="'OnlinePromo' === promo.morphable_type"
                                    :online-promo="promo.data"></online-promo-preview>
            </el-col>
          </template>
        </el-row>

        <el-row type="flex" justify="start" v-else>
          <template v-for="(promo, i) in PROMOS" :key="i">
            <el-col :span="24">
              <folder-promo v-if="'FolderItem' === promo.morphable_type"
                            :folder-promo="promo.data"></folder-promo>
              <online-promo v-if="'OnlinePromo' === promo.morphable_type"
                            :online-promo="promo.data"></online-promo>
              <post v-if="'Post' === promo.morphable_type"
                              :post="promo.data"></post>
            </el-col>
          </template>
        </el-row>

        <div class="w-100 d-flex align-items-center justify-content-center my-3" v-if="!PROMOS_IS_COMPLETE">
          <el-button type="primary"
                     :class="{'w-75':xsOnly,'w-50':!xsOnly}"
                     @click="loadPromos">
            <span v-t="'webshop.load_more'"></span>
          </el-button>
        </div>
      </div>
    </template>
  </default-grid>
</template>

<script>
import DefaultGrid from "@/views/DefaultGrid";
import LeftBar from "@/components/left_bar/LeftBar";
import Tabs from "@/components/feed/Tabs";
import FolderPromo from "@/components/feed/promo/FolderPromo";
import OnlinePromo from "@/components/feed/promo/OnlinePromo";
import Post from "@/components/feed/post/Post";
import {mapActions, mapGetters} from "vuex";
import FolderPromoPreview from "@/components/feed/promo/FolderPromoPreview";
import OnlinePromoPreview from "@/components/feed/promo/OnlinePromoPreview";

export default {
  name: "ProductView",
  components: {
    OnlinePromoPreview,
    FolderPromoPreview,
    Post,
    OnlinePromo,
    FolderPromo,
    Tabs,
    LeftBar,
    DefaultGrid,
  },
  data() {
    return {
      currentTab: _.get(this.$route.query, 'tab', 'default'),
      fetchOptions: {
        include_filters: ['types', 'morphable_types'],
        morphable_types: [],
        types: [],
        products: [],

        order: '',
        only_popular_promos: '',
      }
    }
  },
  computed: {
    ...mapGetters('promo', [
      'PROMOS',
      'PROMOS_MORPHABLE_TYPES',
      'PROMOS_IS_COMPLETE',
    ]),
    ...mapGetters('product', [
      'CURRENT_PRODUCT',
    ]),
    ...mapGetters('filter', [
      'FILTERS_VALUES',
    ]),

    tabs() {
      let tabs = {};
      tabs["default"] = this.$t('product.tabs.default');
      if (this.CURRENT_PRODUCT.total_online_promos > 0) {
        tabs["online-promos"] = this.$t('product.tabs.online-promos');
      }
      if (this.CURRENT_PRODUCT.total_folder_items > 0) {
        tabs["folder-promos"] = this.$t('product.tabs.folder-promos');
      }
      tabs["more-hits"] = this.$t('product.tabs.more-hits');
      tabs["recent"] = this.$t('product.tabs.recent');

      return tabs;
    },

    h1Text() {
      let date = new Date();
      let m = date.toLocaleString(this.$i18n.locale, {month: 'long'});
      let y = date.getFullYear();
      return this.$t('product.product_page_h1', {product: this.CURRENT_PRODUCT.translation.title, month: m, year: y});
    }
  },
  beforeMount() {
    this.RESET_FILTERS();
    this.RESET_PROMOS();
  },
  methods: {
    ...mapActions('filter', [
      'SET_FILTER_ACTIVE',
      'SET_FILTER_INACTIVE',
      'RESET_FILTERS',
    ]),
    ...mapActions('promo', [
      'FETCH_PROMOS',
      'RESET_PROMOS',
    ]),
    loadPromos() {
      return this.FETCH_PROMOS(this.fetchOptions);
    },
    prepareFetchOptions() {
      this.SET_FILTER_ACTIVE('types_filter');

      this.fetchOptions.product = this.CURRENT_PRODUCT.id;
      this.fetchOptions.types = this.FILTERS_VALUES.types_filter;
      this.fetchOptions.morphable_types = this.FILTERS_VALUES.morphable_types_filter;

      this.fetchOptions.order = '';
      this.fetchOptions.only_popular_promos = '';
      switch (this.currentTab) {
        case 'online-promos':
          this.SET_FILTER_INACTIVE('types_filter');
          this.fetchOptions.order = 'published_at';
          this.fetchOptions.morphable_types = ['OnlinePromo'];
          break;
        case 'folder-promos':
          this.SET_FILTER_INACTIVE('types_filter');
          this.fetchOptions.q       = this.CURRENT_PRODUCT.translation.title;
          this.fetchOptions.product = '';
          this.fetchOptions.order   = 'valid_to';
          this.fetchOptions.morphable_types = ['FolderItem'];
          break;
        case 'more-hits':
          this.fetchOptions.order = 'hits_count';
          break;
        case 'recent':
          this.fetchOptions.order = 'published_at';
          break;
        case 'default':
        default:
          this.fetchOptions.order = 'codes_first';
          this.fetchOptions.only_popular_promos = 1;
          break;
      }
    },
    tabChange(tabKey) {
      this.currentTab = tabKey;
      this.RESET_PROMOS();
      this.prepareFetchOptions();
      this.loadPromos();
    },
  },
  watch: {
    FILTERS_VALUES: {
      // the callback will be called immediately after the start of the observation
      deep: true,
      handler(newVal, oldVal) {
        this.RESET_PROMOS();
        this.prepareFetchOptions();
        this.loadPromos();
      }
    }
  }
}
</script>
