<template>
  <default-grid>
    <template v-slot:main-container>
      <div class="feed-container">
        <feed-filter @tab-changed="tabChange" :default-tab="currentTab"></feed-filter>

        <div class="home-feed">

          <el-row type="flex" justify="start">
            <template v-for="(promo, i) in PROMOS" :key="i">
              <el-col :span="24">
                <folder-promo v-if="'FolderItem' === promo.morphable_type"
                              :folder-promo="promo.data"></folder-promo>
                <online-promo v-if="'OnlinePromo' === promo.morphable_type"
                              :online-promo="promo.data"></online-promo>
                <post v-if="'Post' === promo.morphable_type"
                                :post="promo.data"></post>
              </el-col>
            </template>
          </el-row>

          <infinite-loading @infinite="load" :key="currentTab">
            <template #spinner>
              <div class="spinner-container" v-loading="true"></div>
            </template>
            <template #complete>
              <div></div>
            </template>
            <template #error>
              <div></div>
            </template>
          </infinite-loading>
        </div>
      </div>
    </template>
  </default-grid>
</template>

<script>
import DefaultGrid from '@/views/DefaultGrid';
import {mapActions, mapGetters} from "vuex";
import FeedFilter from "@/components/feed/FeedFilter";
import Post from "@/components/feed/post/Post";
import FolderPromo from "@/components/feed/promo/FolderPromo";
import OnlinePromo from "@/components/feed/promo/OnlinePromo";
import {useHead} from "@vueuse/head";
import {needAuth} from "@/mixins/auth/needAuth";

export default {
  name: 'HomeView',
  components: {
    DefaultGrid,
    FeedFilter,
    Post,
    FolderPromo,
    OnlinePromo,
  },
  mixins: [needAuth],
  data() {
    return {
      currentTab: _.get(this.$route.query, 'tab', 'default'),
      fetchOptions: {
        post: '',
        include_filters: ['types', 'morphable_types', 'categories', 'stores'],
        morphable_types: [],
        types: [],
        categories: [],
        stores: [],

        order: '',
        only_favourites: '',
        only_popular_promos: '',
        only_valid_promos: '',
        published_not_later: '',
      }
    }
  },
  computed: {
    ...mapGetters('promo', [
      'PROMOS',
      'PROMOS_IS_COMPLETE',
    ]),
    ...mapGetters('filter', [
      'FILTERS_VALUES',
    ]),
  },
  created() {
    useHead({
      // Can be static or computed
      link: [
        {
          rel: "canonical",
          href: new URL(this.$route.path, window.location.origin).href
        },
      ],
    })
  },
  beforeMount() {
    this.RESET_PROMOS();
    this.RESET_FILTERS();

    this.prepareFetchOptions();
    this.loadPromos();
  },
  methods: {
    ...mapActions('filter', [
      'SET_FILTER_ACTIVE',
      'SET_FILTER_INACTIVE',
      'SET_FILTER_OPENED',
      'RESET_FILTERS',
    ]),
    ...mapActions('promo', [
      'FETCH_PROMOS',
      'RESET_PROMOS',
    ]),

    prepareFetchOptions() {
      this.SET_FILTER_ACTIVE('types_filter');
      this.SET_FILTER_ACTIVE('categories_filter');
      this.SET_FILTER_ACTIVE('stores_filter');

      this.SET_FILTER_OPENED({types_filter: false, categories_filter: false, stores_filter: false});

      this.fetchOptions.post = '';
      if(this.$route.query.post !== undefined){
        this.fetchOptions.post = this.$route.query.post;
      }

      this.fetchOptions.categories = this.FILTERS_VALUES.categories_filter;
      this.fetchOptions.stores = this.FILTERS_VALUES.stores_filter;
      this.fetchOptions.types = this.FILTERS_VALUES.types_filter;
      this.fetchOptions.morphable_types = this.FILTERS_VALUES.morphable_types_filter;

      this.fetchOptions.order = '';
      this.fetchOptions.only_favourites = '';
      this.fetchOptions.only_popular_promos = '';
      this.fetchOptions.only_valid_promos = '';
      this.fetchOptions.published_not_later = '';
      switch (this.currentTab) {
        case 'last-created':
          this.fetchOptions.only_popular_promos = 1;
          this.fetchOptions.only_valid_promos = 1;
          this.fetchOptions.order = 'published_at';
          break;
        case 'more-hits':
          this.fetchOptions.order = 'hits_count';
          this.fetchOptions.published_not_later = 7;
          break;
        case 'more-comments':
          this.fetchOptions.order = 'comments_count';
          this.fetchOptions.published_not_later = 7;
          break;
        case 'favourites':
          this.fetchOptions.order = 'favourite_at';
          this.fetchOptions.only_favourites = 1;
          break;
        case 'default':
        default:
          this.fetchOptions.only_popular_promos = 1;
          this.fetchOptions.only_valid_promos = 1;
          // this.fetchOptions.order = 'commented_at'; This is default!
          break;
      }
    },

    tabChange(tabKey){
      this.currentTab = tabKey;
      this.prepareFetchOptions();
      this.RESET_PROMOS();
      this.loadPromos();
    },

    loadPromos() {
      return this.FETCH_PROMOS(this.fetchOptions);
    },

    load(state) {
      //Show login dialog modal instead of loading
      if (_.size(this.PROMOS) && this.triggerLoginDialog()) {
        state.complete();
        return;
      }

      this.loadPromos().then(() => {
        if (this.PROMOS_IS_COMPLETE) {
          state.complete();
        } else {
          state.loaded();
        }
      });
    }
  },

  watch: {
    FILTERS_VALUES: {
      // the callback will be called immediately after the start of the observation
      deep: true,
      handler(newVal, oldVal) {
        this.prepareFetchOptions();
        this.RESET_PROMOS();
        this.loadPromos();
      }
    }
  }
}
</script>
