<template>
  <el-row type="flex" justify="start" v-if="'default' === currentTab">
    <el-col :xs="24" :sm="24" :md="20" :lg="24" :xl="20">
      <el-row type="flex" justify="start">
        <template v-for="(promo, i) in items" :key="i">
          <el-col :span="24">
            <post v-if="'Post' === promo.morphable_type"
                  :vuex-module="vuexModule"
                  :post="promo.data"></post>
          </el-col>
        </template>
      </el-row>
    </el-col>
  </el-row>

  <el-row type="flex" justify="start" class="secondary-feed" v-if="'folder-promos' === currentTab">
    <template v-for="(promo, i) in items" :key="i">
      <el-col :xs="12" :sm="6" :md="6" :lg="8" :xl="6">
        <folder-promo-preview v-if="'FolderItem' === promo.morphable_type"
                              :vuex-module="vuexModule"
                              :folder-promo="promo.data"></folder-promo-preview>
      </el-col>
    </template>
  </el-row>

  <el-row type="flex" justify="start" class="secondary-feed" v-if="'online-promos' === currentTab">
    <template v-for="(promo, i) in items" :key="i">
      <el-col :xs="12" :sm="6" :md="6" :lg="8" :xl="6">
        <online-promo-preview v-if="'OnlinePromo' === promo.morphable_type"
                              :vuex-module="vuexModule"
                              :online-promo="promo.data"></online-promo-preview>
      </el-col>
    </template>
  </el-row>

  <el-row type="flex" justify="start" v-if="'favourites' === currentTab">
    <el-col :xs="24" :sm="24" :md="20" :lg="24" :xl="20">
      <el-row type="flex" justify="start">
        <template v-for="(promo, i) in items" :key="i">
          <el-col :xs="24">
            <folder-promo v-if="'FolderItem' === promo.morphable_type"
                          :vuex-module="vuexModule"
                          :folder-promo="promo.data"></folder-promo>
            <online-promo v-if="'OnlinePromo' === promo.morphable_type"
                          :vuex-module="vuexModule"
                          :online-promo="promo.data"></online-promo>
            <post v-if="'Post' === promo.morphable_type"
                  :vuex-module="vuexModule"
                  :post="promo.data"></post>
          </el-col>
        </template>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
import Post from "@/components/feed/post/Post";
import FolderPromoPreview from "@/components/feed/promo/FolderPromoPreview";
import OnlinePromoPreview from "@/components/feed/promo/OnlinePromoPreview";
import FolderPromo from "@/components/feed/promo/FolderPromo";
import OnlinePromo from "@/components/feed/promo/OnlinePromo";

export default {
  name: "search-promos-list",
  components: {OnlinePromo, FolderPromo, OnlinePromoPreview, FolderPromoPreview, Post},
  props: {
    items: Array,
    currentTab: String,
    vuexModule: {
      type: String,
      default: 'promo',
    },
  },
}
</script>
