export default {
    namespaced: true,
    state: {
        categories: {
            all: [],
            promise: null
        },
    },

    getters: {
        CATEGORIES(state) {
            return state.categories.all;
        }
    },

    actions: {
        async FETCH_CATEGORIES({state, commit}) {
            if (state.categories.promise) {
                return state.categories.promise;
            }

            let promise = axios.get('/categories').then((response) => {
                commit('UPDATE_CATEGORIES', response.data.data);
            }).catch((e) => {
                console.log(e);
            }).then(() => {
                commit('UPDATE_PROMISE_CATEGORIES', null);
            });

            commit('UPDATE_PROMISE_CATEGORIES', promise);
            return promise;
        },
    },

    mutations: {
        UPDATE_CATEGORIES(state, data) {
            return state.categories.all = data;
        },

        UPDATE_PROMISE_CATEGORIES(state, data) {
            return state.categories.promise = data;
        }
    }
}
