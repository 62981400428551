<template>
  <div v-loading.fullscreen.lock="IS_LOADING"></div>
</template>

<script>

import {discountCodes} from "@/mixins/post/discountCodes";
import {folders} from "@/mixins/public_spa_js/folders";
import {mapGetters} from "vuex";

export default {
  name: "min-app",
  mixins: [discountCodes, folders],
  computed: {
    ...mapGetters('loader', [
      'IS_LOADING',
    ]),
  },
  beforeMount() {
    this.$store.dispatch('auth/UPDATE_I18N', this.$i18n);
  },
  watch: {
    $i18n: {
      handler(val) {
        this.$store.dispatch('auth/UPDATE_I18N', this.$i18n);
      },
      deep: true
    }
  }
}
</script>
