<template>
    <el-scrollbar :native="false">
      <div class="blog-filter">
        <el-tag v-for="(tabName, tabKey) in NEWS_CATEGORIES"
                :key="tabKey"
                :class="{'cursor-pointer': tabName.translation.slug !== feedTab}"
                :effect="tabName.translation.slug === feedTab ? 'dark' : 'plain'"
                :size="tagSize">
          <router-localized-link
              :to="{name: 'news.category', params: {['categorySlug']: String(tabName.translation.slug)}}">
            {{ tabName.translation.title }}
          </router-localized-link>
        </el-tag>
      </div>
      <div class="pt-2">
            <span
                v-for="subcategory in subCategories"
                :key="subcategory"
                :class="{'active': subcategory.translation.slug === feedSubCategory}"
                class="sub-category px-2"
            >
                <router-localized-link
                    :to="{name: 'news.category', params: {['categorySlug']: String(subcategory.translation.slug)}}">
                    {{ subcategory.translation.title }}
                </router-localized-link>
            </span>
      </div>
    </el-scrollbar>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import RouterLocalizedLink from "@/components/common/RouterLocalizedLink";

export default {
  name: "CategoriesList",
  components: {RouterLocalizedLink},
  data() {
    return {
      feedTab: '',
      feedSubCategory: '',
      subCategories: []
    }
  },
  computed: {
    ...mapGetters('news_category', [
      'NEWS_CATEGORIES',
      'CURRENT_CATEGORY'
    ]),
  },
  beforeMount() {
    this.FETCH_NEWS_CATEGORIES();
    if (_.get(this.CURRENT_CATEGORY, 'parent.translation.slug', false)) {
      let thisElem = this;
      this.feedTab = this.CURRENT_CATEGORY.parent.translation.slug;
      this.feedSubCategory = this.CURRENT_CATEGORY.translation.slug;
      this.subCategories = _.get(this.CURRENT_CATEGORY, 'children', []);

      _.forEach(this.NEWS_CATEGORIES, function (category) {
        if (category.translation.slug === thisElem.feedTab) {
          thisElem.subCategories = category.children;
        }
      });
    } else {
      this.feedTab = _.get(this.CURRENT_CATEGORY, 'translation.slug', '');
      this.subCategories = _.get(this.CURRENT_CATEGORY, 'children', [])
    }
  },
  methods: {
    ...mapActions('news_category', [
      'FETCH_NEWS_CATEGORIES',
    ]),
  }
}
</script>
