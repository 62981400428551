import store from "@/store";

export const discountCodes = {
  mounted() {
    this.emitter.on("copy-discount-code", (data) => {
      if (data.discount_url) {
        localStorage.setItem("CopiedDiscountCode", data.discount_code);
        window.open(window.location.href, "_blank");
        window.open(data.discount_url, "_self");
      } else {
        this.emitter.emit("show-login-dialog");
      }
    });

    if (localStorage.getItem("CopiedDiscountCode")) {
      if (store.getters["auth/GUEST"]) {
        setTimeout(() => {
          this.emitter.emit("show-login-dialog");
        }, 500);
      } else {
        this.$notify.success({
          title: this.$t("post.notifications.copied.title"),
          message: this.$t("post.notifications.copied.content", {
            text: localStorage.getItem("CopiedDiscountCode"),
          }),
          position: this.notificationPosition,
          duration: this.notificationDuration,
        });
      }

      localStorage.removeItem("CopiedDiscountCode");
    }
  },
};
