const getDefaultState = () => {
    return {
        categories: {
            all: [],
            promise: null
        },
        currentCategory: {
            data: [],
            promise: null
        },
        currentCategoryArticles: null,
    }
};

export default {
    namespaced: true,
    state: () => (getDefaultState()),

    getters: {
        NEWS_CATEGORIES(state) {
            return state.categories.all;
        },
        CURRENT_CATEGORY(state) {
            return state.currentCategory.data;
        },
        CURRENT_CATEGORY_ARTICLES(state) {
            return state.currentCategoryArticles;
        }
    },

    actions: {
        FETCH_NEWS_CATEGORIES({state, commit}) {
            if (state.categories.promise) {
                return state.categories.promise;
            }

            let promise = axios.get('/news-categories').then((response) => {
                commit('UPDATE_NEWS_CATEGORIES', response.data.data);
            }).catch((e) => {
                console.log(e);
            }).then(() => {
                commit('UPDATE_PROMISE_NEWS_CATEGORIES', null);
            });

            commit('UPDATE_PROMISE_NEWS_CATEGORIES', promise);
            return promise;
        },

        FETCH_CURRENT_CATEGORY({state, commit, dispatch}, slug) {
            if (state.currentCategory.promise) {
                return state.currentCategory.promise;
            }

            let promise = axios.get('/news/' + slug).then((response) => {
                commit('UPDATE_CURRENT_CATEGORY',  _.get(response,'data.current_category',[]));
                commit('UPDATE_CURRENT_CATEGORY_ARTICLES', response.data.data);
            }).catch((e) => {
                console.log(e);
            }).then(() => {
                commit('UPDATE_PROMISE_CURRENT_CATEGORY', null);
            });

            commit('UPDATE_PROMISE_CURRENT_CATEGORY', promise);
            return promise;
        },

        RESET_CATEGORIES({commit}) {
            commit('SET_DEFAULT_DATA');
        },
    },

    mutations: {
        UPDATE_NEWS_CATEGORIES(state, data) {
            return state.categories.all = data;
        },

        UPDATE_PROMISE_NEWS_CATEGORIES(state, data) {
            return state.categories.promise = data;
        },

        UPDATE_CURRENT_CATEGORY(state, data) {
            return state.currentCategory.data = data;
        },

        UPDATE_PROMISE_CURRENT_CATEGORY(state, data) {
            return state.currentCategory.promise = data;
        },

        UPDATE_CURRENT_CATEGORY_ARTICLES(state, data) {
            return state.currentCategoryArticles = data;
        },

        SET_DEFAULT_DATA(state) {
            let defaultState = getDefaultState();
            return _.assign(state, defaultState);
        }

    }
}
