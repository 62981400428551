<template>
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item v-for="(breadcrumbItem, i) in breadcrumb" :key="i">
      <span class="text-secondary" v-if="breadcrumbItem.disabled">
        {{ breadcrumbItem.text }}
      </span>

      <router-localized-link :to="{path: breadcrumbItem.href}" v-else>
        <span class="text-danger font-weight-medium">
          {{ breadcrumbItem.text }}
        </span>
      </router-localized-link>
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
import RouterLocalizedLink from "@/components/common/RouterLocalizedLink";

export default {
  name: "breadcrumbs",
  components: {RouterLocalizedLink},
  data() {
    return {
      breadcrumb: []
    }
  },
  methods: {
    goTo(href) {
      this.routerPush({path: href})
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      if (typeof to.meta.breadcrumb === "function") {
        this.breadcrumb = to.meta.breadcrumb.call(this, this.$route);
      } else {
        this.breadcrumb = to.meta.breadcrumb;
      }
    }
  }
}
</script>
