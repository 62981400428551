<template>
  <div class="post-hit" :class="'hit-' + size" :style="{'width': containerWidth + `px`, 'top': top + `px`}">
    <div class="minus-hit">
      <div class="minus-icon" :class="{'is-active': isDislike}" @click="hit(false)">
        -1
      </div>
    </div>

    <div class="target" ref="target">
      <i class="icon-target"></i>
      <div class="target-bg"></div>

      <i class="icon-arrow" :style="arrowStyle"></i>
    </div>

    <div class="plus-hit">
      <div class="plus-icon" :class="{'is-active': isLike}" @click="hit">
        +1
      </div>
    </div>
  </div>

  <div class="hits-label" :style="labelStyle">
    {{ hitsCount }}&nbsp;<span>{{ $tc('post.hits', hitsCount) }}</span>
  </div>
</template>

<script>
export default {
  name: "hit",
  props: {
    containerWidth: Number,
    top: Number,
    hitsCount: Number,
    isLike: Boolean,
    isDislike: Boolean,
    size: {
      type: String,
      default: 'medium',
    },
  },
  emits: ["hit"],
  data() {
    return {
      //
    }
  },
  computed: {
    labelStyle() {
      if (this.top > 0) {
        return {};
      }
      return {
        'padding-top': (this.xsOnly ? 10 : 20) + 'px'
      };
    },
    arrowStyle() {
      let fromTop = 'medium' === this.size ? '-40px' : '-15px';

      return {
        'left': this.isLike ? '50%' : '120vw',
        'top': this.isLike ? fromTop : '-80vw',
      };
    }
  },
  mounted() {
    //
  },
  methods: {
    hit(like = true) {
      if (like && this.isLike || (!like && this.isDislike)) {
        return;
      }
      this.$emit('hit', like);
    },
  }
}
</script>
