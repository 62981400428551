const getDefaultState = () => {
    return {
        currentFolder: {
            data: {},
            promise: null,
        },
        folders: {
            params: {
                page: 1,
                per_page: 9,
                include: 'store,hits,favourites,dislikes',
            },
            promise: null,
            is_complete: false,
            all: [],
        },
        filters: {
            stores: [],
            categories: [],
        }
    }
};

export default {
    namespaced: true,
    state: () => (getDefaultState()),

    getters: {
        CURRENT_FOLDER(state) {
            return state.currentFolder.data;
        },

        FOLDERS(state) {
            return state.folders.all;
        },

        FOLDERS_PROMISE(state) {
            return state.folders.promise;
        },

        FOLDERS_CATEGORIES(state) {
            return state.filters.categories;
        },

        FOLDERS_STORES(state) {
            return state.filters.stores;
        },
    },

    actions: {
        FETCH_CURRENT_FOLDER({state, commit}, id) {
            if (state.currentFolder.promise) {
                return state.currentFolder.promise;
            }
            let params = {include: 'pages.items,store'};
            let promise = axios.get('/folders/' + id, {params}).then((response) => {
                commit('UPDATE_CURRENT_FOLDER', response.data.data);
            }).catch((e) => {
                console.log(e);
            }).then(() => {
                commit('UPDATE_PROMISE_CURRENT_FOLDER', null);
            });
            commit('UPDATE_PROMISE_CURRENT_FOLDER', promise);
            return promise;
        },
        FETCH_FOLDERS({state, commit}, fetchParams) {
            if (state.folders.promise) {
                return state.folders.promise;
            }

            let params = _.assign(state.folders.params, fetchParams);
            params = _.pickBy(params, function (elem) {
                if (undefined === elem) {
                    return false;
                }
                if (Array.isArray(elem)) {
                    return elem.length;
                }
                return elem.toString().length;
            });
            let promise = axios.get('/folders?', {params}).then((response) => {
                commit('UPDATE_FOLDERS', response.data.data);

                if (params.include_filters) {
                    commit('UPDATE_CATEGORIES', response.data.meta.categories);
                    commit('UPDATE_STORES', response.data.meta.stores);
                }
            }).catch((e) => {
                console.log(e);
            }).then(() => {
                commit('UPDATE_PROMISE_FOLDERS', null);
            });

            commit('UPDATE_PROMISE_FOLDERS', promise);
            return promise;
        },

        RESET_FOLDERS({commit}) {
            commit('SET_DEFAULT_DATA');
        },

        SET_HIT({state, commit}, folder_id) {
            return axios.put(`/folders/${folder_id}/set-hit`).then((response) => {
                commit('UPDATE_FOLDER', response.data.data);
            });
        },

        SET_DISLIKE({state, commit}, folder_id) {
            return axios.put(`/folders/${folder_id}/set-dislike`).then((response) => {
                commit('UPDATE_FOLDER', response.data.data);
            });
        },

        SET_FAVORITE({state, commit}, folder_id) {
            return axios.put(`/folders/${folder_id}/set-favorite`).then((response) => {
                commit('UPDATE_FOLDER', response.data.data);
            });
        },

        REMOVE_FAVORITE({state, commit}, folder_id) {
            return axios.put(`/folders/${folder_id}/remove-favorite`).then((response) => {
                commit('UPDATE_FOLDER', response.data.data);
            });
        },
    },

    mutations: {
        UPDATE_CURRENT_FOLDER(state, data) {
            return state.currentFolder.data = data;
        },

        UPDATE_PROMISE_CURRENT_FOLDER(state, promise) {
            return state.currentFolder.promise = promise;
        },

        UPDATE_FOLDERS(state, data) {
            state.folders.params.page += _.isEmpty(data) ? 0 : 1;
            state.folders.is_complete = _.isEmpty(data);

            return state.folders.all = _.union(state.folders.all, data);
        },

        UPDATE_FOLDER(state, instance) {
            let index = _.findIndex(state.folders.all, {id: instance.id,});
            let folder = _.find(state.folders.all, {id: instance.id,});
            if (index > -1) {
                folder = _.merge(folder, instance);
                state.folders.all.splice(index, 1, folder);
            }
        },

        UPDATE_PROMISE_FOLDERS(state, promise) {
            return state.folders.promise = promise;
        },

        UPDATE_CATEGORIES(state, categories) {
            return state.filters.categories = categories;
        },

        UPDATE_STORES(state, stores) {
            return state.filters.stores = stores;
        },

        SET_DEFAULT_DATA(state) {
            let defaultState = getDefaultState();
            _.unset(defaultState, 'filters');
            return _.assign(state, defaultState);
        }
    }
}
