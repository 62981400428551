<template>
  <footer class="w-100 bg-white">
    <div class="additional-footer">

      <div class="main-footer">
        <div class="text-center footer-links">
          © Chollofinal {{ currentYear }}
          <template v-for="(page, i) in pagesInFooter" :key="i">
            - <router-localized-link class="text-dark" :to="{path: page.route}">{{ page.title }}</router-localized-link>
          </template>

          - <router-localized-link class="text-dark" :to="{name: 'stores'}" v-t="'menu.main.stores'"></router-localized-link>
          - <router-localized-link class="text-dark" :to="{name: 'webshops'}" v-t="'menu.main.webshops'"></router-localized-link>
          - <router-localized-link class="text-dark" :to="{name: 'brands'}" v-t="'menu.main.brands'"></router-localized-link>
          - <router-localized-link class="text-dark" :to="{name: 'products'}" v-t="'menu.main.products'"></router-localized-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import RouterLocalizedLink from "@/components/common/RouterLocalizedLink";

export default {
  name: 'main-footer',
  components: {RouterLocalizedLink},
  computed: {
    ...mapGetters('page', ['PAGES', 'PAGES_LOADED']),
    ...mapGetters('auth', ['LOCALE']),
    currentYear() {
      return new Date().getFullYear()
    },
    pagesInFooter() {
      let thisElem = this
      return _.filter(this.PAGES, function (page) {
        return page.in_footer && thisElem.$i18n.locale === page.locale.slug
      })
    },
  },
  beforeMount() {
    if (!this.PAGES_LOADED) {
      this.FETCH_PAGES()
    }
  },
  methods: {
    ...mapActions('page', ['FETCH_PAGES']),
  },
}
</script>
