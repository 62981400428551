<template>
  <el-divider class="bold-divider my-3"></el-divider>
  <div class="filter-container">
    <div class="filter-header" @click="filterActive = !filterActive">
      <i :class="filterActive ? 'icon-minus' : 'icon-plus-11'"></i>
      <span class="cursor-pointer" v-t="'filters.price'"></span>
    </div>
    <div class="filter-options" v-if="filterActive">
      <div class="price-slider">
        <el-slider range
                   v-model="range"
                   :marks="marks"
                   :step="0.01"
                   :max="max"
                   :min="min"
                   @change="filterPrice"></el-slider>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "price-filter",
  data() {
    return {
      filterActive: true,
      min: 0,
      max: 0,
      range: [0, 0],
      marks: {},
    };
  },
  computed: {
    ...mapGetters('promo', [
      'PROMOS_PRICES',
    ]),
  },
  mounted() {
    this.initFilter(this.PROMOS_PRICES);

    let defaultFilterValue = this.$route.query['price_filter[]']
    if (!_.isEmpty(defaultFilterValue) && _.size(defaultFilterValue) === 2) {
      this.initRange(defaultFilterValue[0], defaultFilterValue[1]);
    }
  },
  methods: {
    ...mapActions('filter', [
      'SET_FILTER_VALUE',
    ]),

    filterPrice() {
      let range = [null, null];
      if (this.range[0] !== this.min || this.range[1] !== this.max) {
        range = this.range;
      }
      this.SET_FILTER_VALUE({price_filter: range});
    },

    initFilter(data) {
      let min = Math.floor(data[0]);
      let max = Math.floor(data[1]);
      if (this.range[0] === 0 && this.range[1] === 0 && max > 0) {
        this.range = [min, max];
      }
      this.min = min;
      this.max = max;
    },
    initRange(min, max) {
      min = parseFloat(min);
      max = parseFloat(max);
      if (this.range[0] !== min || this.range[1] !== max) {
        this.range = [min, max];
      }
    }
  },
  watch: {
    PROMOS_PRICES(to, from) {
      this.initFilter(to);
    }
  }
}
</script>

<style>
.price-slider {
  max-width: 200px;
}
</style>
