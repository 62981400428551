<template>
  <base-filter ref="filter"
               :filter-label="$t('filters.webshops')"
               :filter-options="filterOptions"
               :default-checked="[]"
               filter-key="webshops_filter"
               @filter="filter">
  </base-filter>
</template>

<script>
import BaseFilter from "@/components/left_bar/filters/BaseFilter";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "webshops-filter",
  components: {BaseFilter},
  computed: {
    ...mapGetters('promo', [
      'PROMOS_WEBSHOPS',
    ]),

    filterOptions() {
      let resultOptions = {};
      _.forEach(this.PROMOS_WEBSHOPS, function (webshop) {
        resultOptions[webshop.id] = webshop.translation.title;
      });
      return resultOptions;
    }
  },
  mounted() {
    let defaultFilterValue = this.$route.query['webshops_filter[]']
    if (!_.isEmpty(defaultFilterValue)) {
      this.$refs.filter.setCheckedList(_.castArray(defaultFilterValue));
    }
  },
  methods: {
    ...mapActions('filter', [
      'SET_FILTER_VALUE',
    ]),

    filter(checked) {
      this.SET_FILTER_VALUE({webshops_filter: checked});
    }
  }
}
</script>
