<template>
  <div class="folder-promo">
    <div class="folder-promo-header" ref="folder_promo_header">
      <el-avatar :src="folderPromo.folderPage.folder.store.image_url" shape="circle"
                 class="folder-promo-author-avatar"></el-avatar>

      <div class="folder-promo-author">
        <div class="folder-promo-author-name">
          {{ folderPromo.folderPage.folder.store.translation.title }}
        </div>
        <div class="folder-promo-time" :class="{'text-danger': !folderPromo.is_valid}">
          {{ folderPromo.valid_text }}
        </div>
      </div>

      <div class="folder-promo-actions">
        <div class="folder-promo-prices" v-if="!xsOnly">
          <div class="old-price" v-if="folderPromo.old_price">
            {{ folderPromo.old_price }} €
          </div>
          <div class="new-price">
            {{ folderPromo.new_price }} €
          </div>
        </div>


        <div class="folder-promo-subscribe">
          <i class="icon-bell-2"></i>
        </div>
        <div class="folder-promo-favourite">
          <i class="cursor-pointer"
             :class="{'active icon-heart-2':folderPromo.is_favourite, 'icon-heart':!folderPromo.is_favourite}"
             @click="processFavourite"></i>
        </div>
      </div>
    </div>

    <div class="folder-promo-prices w-100 d-flex align-items-end justify-content-center" v-if="xsOnly">
      <div class="old-price" v-if="folderPromo.old_price">
        {{ folderPromo.old_price }} €
      </div>
      <div class="new-price">
        {{ folderPromo.new_price }} €
      </div>
    </div>

    <div class="folder-promo-content">
      {{ folderPromo.title }}

      <hash-tags :tags="tags"></hash-tags>

      <div class="carousel-container" :style="carouselContainerStyle">
        <div class="folder-promo-carousel" ref="folder_promo_carousel">
          <el-carousel :height="carouselHeight + 'px'"
                       arrow="never">
            <el-carousel-item>
              <div class="folder-promo-carousel-image">
                <img :src="folderPromo.image_url" alt="" :style="`max-width:` + carouselWidth + `px`" @click="openPromoUrl">
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>

        <div class="d-flex align-items-center justify-content-center">
          <hit :container-width="carouselWidth"
               :top="targetTop"
               :hits-count="folderPromo.hits.count"
               :is-like="folderPromo.is_hit"
               :is-dislike="folderPromo.is_dislike"
               @hit="processHit"></hit>
        </div>
      </div>

      <div class="folder-link my-3">
        <el-button type="primary"
                   :class="{'w-75':xsOnly,'w-50':!xsOnly}"
                   @click="openPromoUrl">
          <div>
            <span>
              <span class="font-size-16 font-weight-bold" v-t="'promo.view_the_folder'"></span>
              <i class="icon-arrow-3"></i>
            </span>
          </div>
        </el-button>
      </div>
    </div>
    <div class="folder-promo-footer">
      <div class="comments-wrapper mt-1">
        <comments-header :hidden-total="hiddenComments" :total="commentsCount" @show-more="showMoreComments"></comments-header>

        <comments-loading :loading="commentsLoading"></comments-loading>

        <comments :comments-data="visibleCommentsData" @hit="hitCommentProcess"></comments>

        <comments-loading :loading="commentsPushing"></comments-loading>

        <comment-form
            @submit="pushComment"
        ></comment-form>
      </div>
    </div>
  </div>
</template>

<script>
import Hit from '@/components/feed/Hit';
import CommentForm from '@/components/feed/comment/CommentForm';
import Comments from '@/components/feed/comment/Comments';
import {comments} from "@/mixins/comments";
import CommentsHeader from "@/components/feed/comment/CommentsHeader";
import CommentsLoading from "@/components/feed/comment/CommentsLoading";
import {slug} from "@/mixins/slug";
import {needAuth} from "@/mixins/auth/needAuth";
import HashTags from "@/components/feed/post/HashTags";

export default {
  name: "folder-promo",
  components: {HashTags, CommentsLoading, CommentsHeader, Hit, Comments, CommentForm},
  props: {
    folderPromo: Object,
    vuexModule: {
      type: String,
      default: 'promo',
    },
  },
  mixins: [comments, slug, needAuth],
  data() {
    return {
      carouselHeight: 0,
      carouselWidth: 0,
      targetTop: 0,
    }
  },
  computed: {
    carouselContainerStyle() {
      return {
        'height': this.carouselHeight + (this.xsOnly ? 100 : 90) + 'px'
      }
    },

    tags() {
      let result = [];
      result.push({
        text: this.convertToSlug(this.$t('post.promos_filter.folder_promos')),
        link: this.$router.resolve({name: 'promos'}).href,
        entityType: 'type'
      });

      if (_.get(this.folderPromo, 'folderPage.folder.store.id', false)) {
        result.push({
          text: this.convertToSlug(_.get(this.folderPromo, 'folderPage.folder.store.translation.title')),
          link: this.$router.resolve({
            name: 'store.show',
            params: {storeSlug: _.get(this.folderPromo, 'folderPage.folder.store.translation.route')}
          }).href,
          entityType: 'store'
        });
      }

      if (_.get(this.folderPromo, 'brands[0].id', false)) {
        result.push({
          text: this.convertToSlug(_.get(this.folderPromo, 'brands[0].translation.title')),
          link: this.$router.resolve({
            name: 'brand.show',
            params: {brandSlug: _.get(this.folderPromo, 'brands[0].translation.route')}
          }).href,
          entityType: 'brand'
        });
      }

      return result;
    }
  },
  mounted() {
    let thisElem = this;
    new ResizeObserver(function () {
      thisElem.calculateHeight();
    }).observe(this.$refs.folder_promo_header);

    this.commentsCount = this.folderPromo.comments_count;
    this.comments = this.folderPromo.comments;
    this.visibleComments = _.min([3, this.folderPromo.comments.length]);
  },
  methods: {
    calculateHeight() {
      let thisElem = this;

      if (!thisElem.$refs.folder_promo_header) {
        return 0;
      }
      // cropped images are to small. So 2 times smaller(50% of container width)
      thisElem.carouselWidth = thisElem.$refs.folder_promo_header.clientWidth / 2 - 20;
      thisElem.carouselHeight = 0;

      let currentImage = new Image();
      currentImage.src = thisElem.folderPromo.image_url;
      currentImage.onload = function () {
        let currentHeight = 0;
        if (thisElem.carouselWidth < currentImage.width) {
          currentHeight = thisElem.carouselWidth / currentImage.width * currentImage.height;
        } else {
          currentHeight = currentImage.height;
        }
        if (thisElem.carouselHeight < currentHeight) {
          thisElem.carouselHeight = currentHeight;
          thisElem.targetTop = thisElem.carouselHeight + thisElem.$refs.folder_promo_carousel.offsetTop - (thisElem.xsOnly ? 20 : 30);
        }
      };
    },
    processHit(value) {
      if (this.triggerLoginDialog()) {
        return;
      }
      let data = {
        morphable_type: 'FolderItem',
        morphable_id: this.folderPromo.id,
      };
      if (value) {
        this.$store.dispatch(this.vuexModule + '/SET_HIT', data);
      } else {
        this.$store.dispatch(this.vuexModule + '/SET_DISLIKE', data);
      }
    },
    processFavourite() {
      if (this.triggerLoginDialog()) {
        return;
      }
      let data = {
        morphable_type: 'FolderItem',
        morphable_id: this.folderPromo.id,
      };
      if (this.folderPromo.is_favourite) {
        this.$store.dispatch(this.vuexModule + '/REMOVE_FAVORITE', data);
      } else {
        this.$store.dispatch(this.vuexModule + '/SET_FAVORITE', data);
      }
    },
    showMoreComments() {
      this.showMoreCommentsProcess('FolderItem', this.folderPromo.id);
    },
    pushComment(form) {
      this.pushCommentProcess('FolderItem', this.folderPromo.id, form);
    },
    openPromoUrl() {
      this.routerPush({
        name: 'folder.view',
        params: {
          storeSlug: this.folderPromo.folderPage.folder.store.translation.route,
          folderDate: this.folderPromo.folderPage.folder.valid_from,
        },
        query: {
          id: this.folderPromo.folderPage.folder.id,
          page: this.folderPromo.folderPage.page
        }
      });
      // if(this.folderPromo.folderPage.folder.store.include_links_in_folders){
      //   window.open(this.folderPromo.url, '_blank');
      // }
    }
  }
}
</script>
