<template>
  <header class="w-100">
    <div class="header-area position-relative"></div> <!-- to margin all next elements because header-area is fixed -->
    <div class="header-area">
      <el-row justify="center">
        <el-col :span="23">
          <div class="container">
            <div class="d-flex justify-content-between align-items-center">
              <router-localized-link class="header-logo cursor-pointer" :to="{name : 'home'}">
                <img  src="@/assets/images/darck-logo-new.png">
              </router-localized-link>

              <div :class="{'header-search': !this.xsOnly,'m-header-search': this.xsOnly}">
                <el-autocomplete
                    v-model="query"
                    ref="autocomplete"
                    :fetch-suggestions="querySearch"
                    class="search-input"
                    :placeholder="$t('header.search_placeholder')"
                    :size="inputsSize"
                    @select="handleSelect"
                    @keydown.enter="submitSearch">
                  <template #suffix>
                    <i class="icon-search cursor-pointer" @click="submitSearch"></i>
                  </template>
                  <template #default="{ item }">
                    <span class="font-size-16 pe-2">{{item.title}}</span>
                    <span class="text-secondary small">({{item.group}})</span>
                  </template>
                </el-autocomplete>
              </div>

              <div class="d-flex align-items-center justify-content-center">
                <div v-if="GUEST" class="header-question cursor-pointer">
                  <div  class="login-text">
                    <span v-html="'Iniciar Sesión'" @click="displayLoginDialog('login')"></span>
                    <i class="icon-account-2 ms-2"></i>
                  </div>
                  <i class="font-weight-bold position-relative align-self-center"
                     :class="{'icon-menu': !dialogs.showHeaderMenu,'icon-close': dialogs.showHeaderMenu}"
                     @click="dialogs.showHeaderMenu = !dialogs.showHeaderMenu">
                  </i>
                  <main-menu v-if="dialogs.showHeaderMenu" @login="displayLoginDialog('login')"/>
                </div>
                <div v-else class="header-question ms-lg-5">
                  <span
                    v-if="lgAndUp"
                    class="logged-user-text cursor-pointer"
                    @click="dialogs.showUserMenu = !dialogs.showUserMenu"
                  >
                    {{ $t('header.hello_user_label1', { name: this.USER.fname }) }}
                  </span>
                  <notifications/>
                  <div class="d-flex cursor-pointer avatar-wrapper" @click="dialogs.showUserMenu = !dialogs.showUserMenu">
                    <i v-if="dialogs.showUserMenu" class="icon-close-2 user-avatar"/>
                    <el-avatar
                      v-else
                      :src="this.USER.avatar_url"
                      shape="circle"
                      class="user-avatar"
                    />
                    <svg v-if="!dialogs.showUserMenu" class="avatar-arrow" viewBox="0 0 18 10" xmlns="http://www.w3.org/2000/svg"><path d="M2.75843 0.4435L9.0084 6.33616L15.2584 0.4435C15.4075 0.302893 15.5845 0.191358 15.7794 0.115263C15.9742 0.039167 16.1831 1.48153e-09 16.394 0C16.6049 -1.48153e-09 16.8137 0.039167 17.0086 0.115263C17.2034 0.191358 17.3805 0.302893 17.5296 0.4435C17.6787 0.584106 17.797 0.751031 17.8778 0.934742C17.9585 1.11845 18 1.31535 18 1.5142C18 1.71305 17.9585 1.90995 17.8778 2.09366C17.797 2.27737 17.6787 2.4443 17.5296 2.5849L10.136 9.55586C9.98694 9.69665 9.80993 9.80835 9.61507 9.88456C9.4202 9.96077 9.21131 10 9.00034 10C8.78938 10 8.58048 9.96077 8.38562 9.88456C8.19075 9.80835 8.01374 9.69665 7.86472 9.55586L0.471073 2.5849C0.321745 2.4444 0.203273 2.27751 0.12244 2.09378C0.0416069 1.91006 0 1.71311 0 1.5142C0 1.3153 0.0416069 1.11834 0.12244 0.934619C0.203273 0.750893 0.321745 0.584003 0.471073 0.4435C1.09929 -0.133616 2.13021 -0.148804 2.75843 0.4435Z" fill="#FE0611"/></svg>
                  </div>
                  <user-menu v-if="dialogs.showUserMenu" @user-settings="dialogs.showSettingsDialog = true"/>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="header-menu position-relative" v-if="showMenu && !this.xsOnly"></div> <!-- to margin all next elements because header-menu is fixed -->
    <div class="header-menu" v-if="showMenu">
      <el-row justify="center">
        <el-col :span="23">
          <div class="container">
            <el-row justify="space-between">
              <el-col :span="0" :lg="8" :xl="8">
                <div class="menu-logo">
                  <span v-html="$t('header.logo_label')"></span>
                </div>

                <div class="menu-flag">
                  <div class="line1"></div>
                  <div class="line2"></div>
                  <div class="line3"></div>
                </div>
              </el-col>
              <el-col :span="24" :lg="16" :xl="16">
                <div class="d-flex justify-content-end align-items-center">

                  <div class="menu-list" :style="menuStyles">
                    <div class="menu-list-item">
                      <home-button :height="24" :width="21">
                        <span v-t="'header.menu.home'" :class="menuTextLabelStyles"/>
                      </home-button>
                    </div>
                    <div
                        v-if="!this.xsOnly && !GUEST"
                        class="menu-list-item"
                    >
                      <i class="icon-plus font-weight-bold" @click="newPostClicked"/>
                      <span
                          v-t="'header.menu.new_post'"
                          :class="menuTextLabelStyles"
                          @click="newPostClicked"
                      />
                    </div>

                    <div class="menu-list-item">
                      <router-localized-link :to="{name: 'folders'}">
                        <i class="icon-paper font-weight-bold"></i>
                        <span v-t="'header.menu.folders'" :class="menuTextLabelStyles"/>
                      </router-localized-link>
                    </div>

                    <div class="menu-list-item">
                      <router-localized-link :to="{name: 'promos'}">
                        <i class="icon-percent font-weight-bold"></i>
                        <span v-t="'header.menu.promos'" :class="menuTextLabelStyles"/>
                      </router-localized-link>
                    </div>

                    <div class="menu-list-item">
                      <router-localized-link :to="{name: 'news'}">
                        <i class="icon-bullhorn font-weight-bold"></i>
                        <span v-t="'header.menu.news'" :class="menuTextLabelStyles"/>
                      </router-localized-link>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="login-bar" v-if="GUEST && false"><!-- hidden TODO delete this block -->
      <div class="login-bar-inner py-3">
        <div class="login-bar-text font-weight-bold mx-3 mx-md-4">
          <span v-t="'header.login_register_label'"></span>
        </div>

        <el-button class="my-1 my-md-0" type="primary" @click="displayLoginDialog('register')">
          <div class="d-flex justify-content-center align-items-center">
            <span v-t="'header.register_button'"/>
            <i class="icon-arrow-3"></i>
          </div>
        </el-button>

        <div class="mx-3 mx-md-4 mt-2 mt-md-0">
          <span class="text-decoration-underline cursor-pointer"
                v-t="'header.login_button'"
                @click="displayLoginDialog('login')"></span>
        </div>
      </div>
    </div>

    <div class="breadcrumb-header">
      <el-row justify="center" align="middle">
        <el-col :span="23">
          <div class="container">
            <breadcrumbs></breadcrumbs>
          </div>
        </el-col>
      </el-row>
    </div>

    <settings-dialog v-model:show-dialog="dialogs.showSettingsDialog"></settings-dialog>

    <post-dialog v-model:show-dialog="dialogs.showPostDialog"></post-dialog>

    <login-dialog v-model:show-dialog="dialogs.showLoginDialog" :starting-tab="startingTab"></login-dialog>
  </header>
</template>

<script>
import Breadcrumbs from '@/components/header/Breadcrumbs';
import LoginDialog from '@/components/header/LoginDialog';
import UserMenu from '@/components/header/UserMenu';
import PostDialog from '@/components/feed/post/PostDialog';
import {mapGetters} from "vuex";
import {needAuth} from "@/mixins/auth/needAuth";
import Notifications from "@/components/header/Notifications";
import SettingsDialog from "@/components/header/auth/SettingsDialog";
import RouterLocalizedLink from "@/components/common/RouterLocalizedLink";
import HomeButton from "@/components/common/buttons/HomeButton.vue";
import {autocompelete} from "@/mixins/autocompelete";
import MainMenu from "@/components/header/MainMenu.vue";

export default {
  name: "main-header",
  components: {
    MainMenu,
    HomeButton,
    RouterLocalizedLink,
    SettingsDialog,
    Notifications,
    Breadcrumbs,
    LoginDialog,
    PostDialog,
    UserMenu,
  },
  mixins: [needAuth, autocompelete],
  data() {
    return {
      query: '',
      dialogs: {
        showLoginDialog: false,
        showPostDialog: false,
        showSettingsDialog: false,
        showUserMenu: false,
        showHeaderMenu: false,
      },
      startingTab: 'default', // for <login-dialog>
    };
  },
  computed: {
    ...mapGetters('auth', [
      'USER',
      'GUEST',
    ]),
    menuStyles() {
      return `max-width: ${this.displayBreakpoint === 'xl' && this.GUEST ? '500px' : '800px'}`;
    },

    showMenu() { // hide menu when route and des
      let needHide = this.$route.name === 'folder.view' && !['xs'].includes(this.displayBreakpoint);
      return !needHide;
    },
    menuTextLabelStyles() {
      return `font-weight-medium ${['xs'].includes(this.displayBreakpoint) ? 'font-size-11' : 'ms-2'}`;
    },
  },
  mounted() {
    setTimeout(() => {
      if (_.get(this.$route, 'hash') === '#signup' && this.GUEST) {
        this.startingTab = 'register';
        this.dialogs.showLoginDialog = true;
      }

      if (_.get(this.$route, 'hash') === '#verified' && this.GUEST) {
        this.startingTab = 'verified';
        this.dialogs.showLoginDialog = true;
      }
    }, 1000);

    this.emitter.on("show-login-dialog", (tab = 'default') => {
      this.startingTab = tab;
      this.dialogs.showLoginDialog = true;
    });
    this.emitter.on("show-settings-dialog", () => {
      this.dialogs.showSettingsDialog = true;
    });
  },
  methods: {
    openDialogIfMobileGuest() {
      if (this.webview && this.GUEST && !cookies.get('visited')) {
        this.displayLoginDialog('default');

        cookies.set('visited', true, '1y');
      }
    },
    displayLoginDialog(startingTab) {
      this.startingTab = startingTab;
      this.dialogs.showLoginDialog = true;
    },
    closeDialogsExcept(exceptDialog = null) {
      let thisElem = this;
      _.forEach(this.dialogs, function (value, key) {
        if (key !== exceptDialog) {
          thisElem.dialogs[key] = false;
        }
      });
    },
    submitSearch() {
      if (this.triggerLoginDialog()) {
        return;
      }

      if (this.triggerSuggest(this.query)) {
        return;
      }

      this.routerPush({name: 'search', query: {q: String(this.query)}});
    },
    newPostClicked() {
      if (this.triggerLoginDialog()) {
        return;
      }
      this.dialogs.showPostDialog = true
    },
  },
  watch: {
    $route(to, from) {
      if (to.name === from.name && to.path !== from.path) {
        this.$router.go(0);
      }
      if ('search' !== to.name) {
        this.query = '';
      }
      this.closeDialogsExcept();
      this.openDialogIfMobileGuest();
    },
    'dialogs.showLoginDialog': function (newVal, oldVal) {
      newVal && this.closeDialogsExcept('showLoginDialog');
    },
    'dialogs.showPostDialog': function (newVal, oldVal) {
      newVal && this.closeDialogsExcept('showPostDialog');
    },
    'dialogs.showSettingsDialog': function (newVal, oldVal) {
      newVal && this.closeDialogsExcept('showSettingsDialog');
    },
    'dialogs.showUserMenu': function (newVal, oldVal) {
      newVal && this.closeDialogsExcept('showUserMenu');
    },
    'dialogs.showHeaderMenu': function (newVal, oldVal) {
      newVal && this.closeDialogsExcept('showHeaderMenu');
    },
  }
}
</script>

<style>
.header-search .search-input {
  cursor: pointer;
}
</style>
