<template>
  <base-filter ref="filter"
               :filter-label="$t('filters.post')"
               :filter-options="filterOptions"
               :default-checked="[]"
               filter-key="promos_filter"
               @filter="filter">
  </base-filter>
</template>

<script>
import BaseFilter from "@/components/left_bar/filters/BaseFilter";
import {mapActions} from "vuex";

export default {
  name: "promos-filter",
  components: {BaseFilter},
  data() {
    return {
      filterOptions: {
        "discount_code": this.$t('post.promos_filter.discount_code'),
        "folder_promos": this.$t('post.promos_filter.folder_promos'),
        "online_promos": this.$t('post.promos_filter.online_promos'),
        "day_deals": this.$t('post.promos_filter.day_deals'),
      },
    };
  },
  mounted() {
    let defaultFilterValue = this.$route.query['promos_filter[]']
    if (!_.isEmpty(defaultFilterValue)) {
      this.$refs.filter.setCheckedList(_.castArray(defaultFilterValue));
    }
  },
  methods: {
    ...mapActions('filter', [
      'SET_FILTER_VALUE',
    ]),

    filter(checked) {
      this.SET_FILTER_VALUE({promos_filter: checked});
    }
  }
}
</script>
