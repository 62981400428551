<template>
  <div>
    <div class="text-danger">{{ header }}</div>

    <div v-for="(entities, char) in grouped" :key="char" class="my-3">
      <el-divider></el-divider>

      <div class="w-100">
        <h4 class="text-uppercase">{{ char }}</h4>
      </div>

      <div class="w-100 d-flex align-items-center justify-content-start flex-wrap">
        <div v-for="(entity,i) in entities"
             :key="i"
             class="char-sorted-entity"
             :title="entity.translation.title">
          <router-localized-link :to="{name: routeName, params: {[routeParamName]: String(entity.translation.route)}}">
            <div class="char-sorted-img-container">
              <img :src="entity.image_url" alt="">
            </div>

            <div class="char-sorted-title hover">
              {{ entity.translation.title }}
            </div>
          </router-localized-link>
        </div>

        <div class="char-sorted-entity"
             v-if="showLoad && entities.length === previewCount && !loadedChars.includes(char)"
             @click="loadForChar(char)">
          <div class="char-sorted-img-container">
            <i class="icon el-icon-refresh-right" style="font-size: 2rem;"></i>
          </div>

          <div class="char-sorted-title hover">
            <span v-t="'filters.show_more'"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouterLocalizedLink from "@/components/common/RouterLocalizedLink";
export default {
  name: "char-sorted-entities",
  components: {RouterLocalizedLink},
  emits: ["load-char"],
  props: {
    data: {
      type: Array,
      default() {
        return []
      }
    },
    header: {
      type: String,
      default: ''
    },
    showLoad: {
      type: Boolean,
      default: false
    },
    // Only if showLoad === true
    previewCount: {
      type: Number,
      default: 10
    },
    routeName: {
      type: String,
      default: 'store.show'
    },
    routeParamName: {
      type: String,
      default: 'storeSlug'
    }
  },
  data() {
    return {
      loadedChars: []
    }
  },
  computed: {
    grouped() {
      let collection = this.data;

      return _.groupBy(collection, (item) => {
        let char = item.translation.title.substring(0, 1);
        return Number.isInteger(Number(char)) ? '0-9' : _.upperCase(char);
      });
    }
  },
  methods: {
    loadForChar(char) {
      this.$emit('load-char', char);
      this.loadedChars.push(char);
    }
  }
}
</script>
