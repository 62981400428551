<template>
  <el-row justify="center">
    <el-col :span="23">
      <div class="container">
        <el-row type="flex" justify="space-between">
          <el-col :span="6" v-if="lgAndUp" class="px-2">
            <aside>
              <slot name="left-container">
                <left-bar></left-bar>
              </slot>
            </aside>
          </el-col>

          <el-col :span="mainContainerSpan">
            <div class="main-container-wrapper">
              <slot name="main-container"></slot>
            </div>
          </el-col>

          <el-col :span="6" v-if="lgAndUp && showRightContainer" class="px-2">
            <aside>
              <slot name="right-container">
                <right-bar></right-bar>
              </slot>
            </aside>
          </el-col>
        </el-row>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import LeftBar from "@/components/left_bar/LeftBar";
import RightBar from "@/components/right_bar/RightBar";
import {discountCodes} from "@/mixins/post/discountCodes";

export default {
  name: "default-grid",
  components: {RightBar, LeftBar},
  props: {
    showRightContainer: {
      type: Boolean,
      default: true
    }
  },
  mixins: [discountCodes],
  computed: {
    mainContainerSpan() {
      return this.lgAndUp ? (this.showRightContainer ? 12 : 18) : 24;
    }
  }
}
</script>
