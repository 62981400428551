<template>
  <div class="bonus-prize">
    <div class="bonus-prize-image-container">
      <img :src="prize.image_url" alt="">
    </div>
    <b>{{ $t('prizes.prize_price', {amount: prize.price}) }}</b>
    <p>{{ prize.translation.title }}</p>

    <div class="text-danger text-center cursor-pointer"
         v-if="isEnoughPoints"
         @click="requestPrize">
      <span v-t="'prizes.request_prize'"></span>
      <i class="icon-arrow_forward"></i>
    </div>

    <div class="text-danger text-center" v-else
         v-t="'prizes.not_enough_points'"></div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "bonus-prize",
  props: ['prize'],
  computed: {
    ...mapGetters('auth', [
      'USER',
    ]),

    isEnoughPoints() {
      return this.USER.account.available >= this.prize.price;
    }
  },
  methods: {
    requestPrize() {
      this.routerPush({name: 'prizes-pool.prize-request', params: {prizeId: String(this.prize.id)}});
    }
  }
}
</script>

<style lang="scss" scoped>
.bonus-prize {
  background-color: white;
  margin: 10px 10px;
  padding: 10px;
}
</style>
