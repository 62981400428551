<template>
  <div class="store-offer-container">
    <h3 class="text-danger store-offer-header" v-text="$t('store.store_offer_title', {store:storeTitle})"></h3>
    <el-scrollbar class="w-100 pb-3" :native="false" ref="scrollbar_ref" :min-size="40">
      <div class="scrollbar-flex-content">
        <div class="scrollbar-item" v-for="(offer, i) in offers" :key="i">
          <div class="store-offer-content">
            <router-localized-link
                :to="{name: 'product.show', params: {['productSlug']: String(offer.translation.route)}}">
              <img v-if="offer.image_url" :src="offer.image_url" alt="{{ offer.translation.title }}">
              <img v-else src="@/assets/images/webshop_avatar.png">

              <div class="store-offer-title" v-text="storeTitle"></div>
              <div class="store-offer-wrap">
                {{ offer.translation.title }} <i class="icon-arrow-3"></i>
              </div>
            </router-localized-link>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import RouterLocalizedLink from "@/components/common/RouterLocalizedLink";

export default {
  name: "store-offers",
  components: {RouterLocalizedLink},
  props: {
    offers: Object,
    storeTitle: String,
  }
}
</script>
