<template>
  <div class="advertiser-container">
    <div v-for="(data,i) in ADS" :key="i">

      <div>
        <div class="title">
          {{ data.title }}
        </div>
        <img :src="data.image_url" @click="goAd(data.url)">
        <div class="description" v-html="data.description"></div>
        <div class="link" @click="goAd(data.url)">
          <span v-t="'ad.view_offer'"></span> <i class="icon-arrow_forward"></i>
        </div>
      </div>

      <el-divider class="my-3"></el-divider>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "advertiser",
  mounted() {
    this.FETCH_ADS();
  },
  computed: {
    ...mapGetters('ad', [
      'ADS',
    ]),
  },
  methods: {
    ...mapActions('ad', [
      'FETCH_ADS'
    ]),

    goAd(url) {
      window.open(url, '_blank');
    }
  }
}
</script>
