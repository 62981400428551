<template>
  <div class="d-flex align-items-center justify-content-between" v-if="total > 0">
    <div>
      <span class="text-secondary cursor-pointer" @click="showMore" v-if="hiddenTotal > 0">
        {{ $t('comment.show_more', {count: hiddenTotal}) }}
      </span>
    </div>

    <span class="font-weight-bold">
      {{ $t('comment.total_comments', {count: total}) }}
    </span>
  </div>
</template>

<script>
import {needAuth} from "@/mixins/auth/needAuth";

export default {
  name: "comments-header",
  mixins: [needAuth],
  emits: ['show-more'],
  props: {
    total: {
      type: Number,
      default: 0,
    },
    hiddenTotal: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    showMore() {
      this.webshopLoginDialog();
      if (this.triggerLoginDialog()) {
        return;
      }
      this.$emit('show-more');
    }
  }
}
</script>
