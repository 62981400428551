<template>
  <el-dialog
      title="Nieuwe Post"
      v-model="show"
      :show-close="false"
      :close-on-click-modal="true"
      :close-on-press-escape="true"
      :destroy-on-close="true"
      :lock-scroll="false"
      width="100vw"
      class="post-dialog danger-header-dialog"
      @close="closeDialog"
      center>

    <div class="post-types">
      <el-scrollbar :native="false">
        <div class="pb-2">
          <el-tag v-for="(type,i) in orderedTypes"
                  class="small-tag"
                  :key="i"
                  :class="{'cursor-pointer': type.id !== parentTypeId}"
                  :effect="type.id === parentTypeId ? 'dark' : 'plain'"
                  :size="tagSize"
                  @click="parentTypeChanged(type)">
            {{ type.translation.title }}
          </el-tag>
        </div>
      </el-scrollbar>
    </div>

    <div v-if="!postIsCreated">
      <el-form :model="form"
               ref="form"
               :rules="rules">
        <el-form-item prop="type">
          <el-select v-if="parentType && parentType.children.length"
                     v-model="newPostTypeId"
                     class="rounded-select"
                     placeholder="Om welke promo gaat het?"
                     @change="postTypeChanged">
            <el-option
                v-for="item in parentType.children"
                :key="item"
                :label="item.translation.title"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="content">
          <el-input
              v-if="newPostTypeId"
              class="rounded-textarea"
              type="textarea"
              :placeholder="newPostType.translation.placeholder_content"
              v-model="form.content"
              :autosize="{ minRows: 5, maxRows: 20}"
              ref="content"
              @paste="pasteEvent"
          ></el-input>
        </el-form-item>

        <div class="position-relative">
          <emoji-picker @select="selectEmoji($event)"></emoji-picker>
          <el-upload
              class="upload-image-container"
              :action="imageUploadUrl"
              :headers="{'Accept':'application/json','Authorization':'Bearer ' + AUTH_TOKEN}"
              :on-remove="handleImageRemove"
              :on-error="handleImageError"
              :on-change="handleImageOnChange"
              :limit="4"
              ref="upload"
              list-type="picture"
              :file-list="form.images">
            <a href="javascript:void(0)" class="img-upload-btn text-dark" v-if="form.images.length < 4">
              <i class="el-icon-camera font-size-20"></i>
            </a>
          </el-upload>
        </div>

        <el-form-item prop="discount_code" class="mt-2">
          <el-input
              v-if="showDiscountCodeInput"
              class="rounded-input"
              placeholder="Vul hier je kortingscode in"
              v-model="form.discount_code">
          </el-input>
        </el-form-item>

        <el-row type="flex" justify="center" v-if="showDiscountCodeInput">
          <el-col :span="11">
            <el-form-item prop="webshop">
              <el-select
                  class="rounded-select"
                  v-model="form.webshop"
                  clearable
                  filterable
                  remote
                  placeholder="Webshop"
                  :remote-method="searchWebshop"
                  :loading="loadingWebshop">
                <el-option
                    v-for="item in webshops"
                    :key="item.id"
                    :label="item.translation.title"
                    :value="item.id"
                />
              </el-select>
            </el-form-item>

          </el-col>
          <el-col :span="2"></el-col>
          <el-col :span="11">
            <el-form-item prop="expired_at">
              <el-date-picker
                  class="rounded-select w-100"
                  type="date"
                  placeholder="Verloopt op"
                  v-model="form.expired_at">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="my-3" v-if="newPostTypeId && getRewardText(BONUS_ACTION_TYPES['post-created'], 'Type', newPostType.id) !== '0'">
          <p class="label-100">+{{ getRewardText(BONUS_ACTION_TYPES['post-created'], 'Type', newPostType.id) }}</p>
          <p class="label-coins" v-t="'bonus_system.balance_postfix'"></p>
        </div>

        <el-button v-if="newPostTypeId"
                   type="primary"
                   class="w-100"
                   @click="submitPost">
          <span v-t="'post.send'"></span>
        </el-button>
      </el-form>
    </div>

    <div v-else>
      <div class="text-center text-success font-size-120">
        <i class="icon-check"></i>
      </div>

      <div class="text-success text-center font-size-30">
        <span v-t="'post.sent_successfully'"></span>
      </div>


      <div class="p-2">
        {{$t('post.success_description', {points: getRewardText(BONUS_ACTION_TYPES['post-created'], 'Type', newPostType.id)})}}
      </div>

      <el-button type="success"
                 class="w-100"
                 @click="closeDialog">
        <span v-t="'post.back_to_site'"></span>
      </el-button>
    </div>

    <template v-slot:header>
      <div>
        <i class="icon-arrow_back float-start cursor-pointer" @click="closeDialog"></i>
        <span v-t="'post.new_post'"></span>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {validator} from "@/mixins/validator";
import EmojiPicker from '@/components/feed/post/EmojiPicker';

export default {
  name: "post-dialog",
  props: ['showDialog'],
  mixins: [validator],
  components: {EmojiPicker},
  data() {
    return {
      show: this.showDialog,
      form: {
        type: '',
        content: '',
        images: [],
        discount_code: '',
        webshop: '',
        expired_at: '',
      },
      rules: {
        type: [
          {validator: this.backendValidator}
        ],
        content: [
          {validator: this.backendValidator}
        ],
        discount_code: [
          {validator: this.backendValidator}
        ],
        webshop: [
          {validator: this.backendValidator}
        ],
        expired_at: [
          {validator: this.backendValidator}
        ]
      },
      imageUploadUrl: process.env.VUE_APP_API_URL + 'posts/upload-image',
      newPostTypeId: null,
      newPostType: null,
      parentType: null,
      parentTypeId: null,

      postIsCreated: false,
      loadingWebshop: false,
      webshops: [],
    }
  },
  mounted() {
    if (!this.TYPES.length) {
      this.FETCH_TYPES().then(() => {
        this.parentTypeChanged(this.TYPES[0]);
      });
    }
    if (!this.BONUS_ACTIONS.length) {
      this.FETCH_BONUS_ACTIONS();
    }
  },
  computed: {
    ...mapGetters('type', [
      'TYPES',
    ]),
    ...mapGetters('bonus_action', [
      'BONUS_ACTIONS',
      'BONUS_ACTION_TYPES',
    ]),
    ...mapGetters('auth', [
      'GUEST',
      'AUTH_TOKEN',
    ]),

    orderedTypes() {
      return _.sortBy(this.TYPES, 'additional_order');
    },

    showDiscountCodeInput() {
      return 2 === _.get(this.newPostType, 'kind', null);
    }
  },
  methods: {
    ...mapActions('type', [
      'FETCH_TYPES',
    ]),
    ...mapActions('bonus_action', [
      'FETCH_BONUS_ACTIONS',
    ]),
    ...mapActions('loader', [
      'SET_LOADER',
    ]),
    resetForm() {
      this.form.discount_code = '';
      this.form.content = '';
      this.form.webshop = '';
      this.form.expired_at = '';
      this.form.images = [];
      this.$refs.upload.clearFiles();
    },
    submitPost() {
      this.form.type = this.newPostTypeId;
      this.SET_LOADER(true);

      this.$http.post('/posts', this.form).then((response) => {
        this.errors = {};
      }).catch((e) => {
        switch (e.response.status) {
          case 422:
            this.errors = _.assign({}, e.response.data.errors);
            break;

          default:
            this.errors.password = [e.response.data.error];
            break;
        }
      }).then(() => {
        this.SET_LOADER(false);
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.resetForm();
            this.postIsCreated = true;
          }
        });
      });
    },
    closeDialog() {
      this.postIsCreated = false;
      this.$emit('update:show-dialog', false);
    },
    handleImageError(err, file, files) {
      let e = JSON.parse(err.message);

      this.$notify.error({
        title: this.$t('notifications.default.title'),
        message: _.get(e, 'errors.file.0', this.$t('notifications.default.message')),
        position: this.notificationPosition,
        duration: this.notificationDuration,
      });
    },
    handleImageOnChange(file, files) {
      if (_.get(file, 'response.file')) {
        this.form.images.push({
          name: file.name,
          url: URL.createObjectURL(file.raw),
          path: _.get(file, 'response.file'),
        });
      }
    },
    handleImageRemove(file, fileList) {
      this.form.images = fileList;
    },
    pasteEvent(event) {
      if (this.form.images.length > 3) {
        return;
      }

      let items = event.clipboardData.items;
      for (let index in items) {
        let item = items[index];
        if (item.kind === 'file') {
          let imageFromClipboard = item.getAsFile();
          let formData = new FormData();
          formData.append("file", imageFromClipboard);

          axios.post('/posts/upload-image', formData).then((response) => {
            this.form.images.push({
              name: imageFromClipboard.name,
              url: URL.createObjectURL(imageFromClipboard),
              path: response.data.file,
            });
          });
          break;
        }
      }
    },
    postTypeChanged(newPostTypeId) {
      this.newPostType = this.parentType.children.filter(need => need.id === newPostTypeId)[0];
    },
    parentTypeChanged(newType) {
      this.newPostType = newType;
      this.newPostTypeId = newType.id;
      this.parentType = newType;
      this.parentTypeId = newType.id;

      if (newType.children.length) {
        this.newPostType = newType.children[0];
        this.newPostTypeId = this.newPostType.id;
      }
    },

    getRewardText(type, bonusable_type = null, bonusable_id = null) {
      let action = this.BONUS_ACTIONS.filter(need => need.type === type && need.bonusable_type === bonusable_type && need.bonusable_id === bonusable_id)[0];
      if (undefined === action) {
        return '0';
      }
      if (0 < action.impact_on_balance) {
        return action.impact_on_balance;
      }
      return '0';
    },

    selectEmoji(emoji) {
      const insertText = emoji.native;
      if (!insertText.length) return;

      const textarea = this.$refs.content.$refs.textarea;
      const sentence = textarea.value;
      const len = sentence.length;
      let pos = textarea.selectionStart;
      if (pos === undefined) {
        pos = 0;
      }

      const before = sentence.substr(0, pos);
      const after = sentence.substr(pos, len);

      this.form.content = before + insertText + after;

      this.$nextTick().then(() => {
        textarea.selectionStart = pos + insertText.length;
      });
    },
    searchWebshop(query) {
      if (query !== '') {
        this.loadingWebshop = true;
        axios.get('/webshops?q=' + query).then((response) => {
          this.webshops = response.data.data;
          this.loadingWebshop = false;
        });
        return;
      }
      this.webshops = [];
    },
  },
  watch: {
    showDialog(newShowDialog) {
      this.show = newShowDialog;
    }
  },
}
</script>

<style lang="scss">
.upload-image-container {
  margin-top: -10px;

  .el-upload-list {
    width: 100%;
    margin-bottom: 4px;
  }
}

.img-upload-btn {
  position: absolute;
  right: 15px;
  top: 0;
}

.emoji-btn {
  position: absolute;
  right: 45px;
  top: 0;
}
</style>
