<template>
  <div class="my-3">
    <el-divider class="my-3 bold-divider"></el-divider>

    <div class="text-danger font-size-20">
      <span v-t="'bookmarks.header'"></span>
    </div>

    <div class="mt-2" v-for="(item, i) in BOOKMARKS" :key="i">
      <div class="d-flex align-items-center justify-content-between p-2 text-secondary">
        <div class="text-decoration-underline cursor-pointer" @click="openBookmark(item)">
          {{ item.title }}
        </div>
        <div class="cursor-pointer">
          <i class="icon-close" @click="deleteBookmark(item)"></i>
        </div>
      </div>
    </div>

    <div class="mt-2 text-danger font-size-18 cursor-pointer" @click="addBookmark">
      <span class="text-decoration-underline" v-t="'bookmarks.add_bookmark'"></span>
      <i class="ms-2 icon-arrow-4"></i>
    </div>

    <el-dialog
        :title="$t('bookmarks.add_bookmark')"
        v-model="showCreateForm"
        :show-close="true"
        :close-on-click-modal="true"
        :close-on-press-escape="true"
        :destroy-on-close="true"
        :lock-scroll="false"
        :append-to-body="true"
        class="create-bookmark-dialog danger-header-dialog"
        center>

      <el-form :model="createForm"
               label-width="80px"
               label-position="left"
               ref="form"
               :rules="rules">
        <el-form-item prop="title" :label="$t('bookmarks.create_form.title.label')">
          <el-input class="rounded-input" v-model="createForm.title"
                    :placeholder="$t('bookmarks.create_form.title.placeholder')"/>
        </el-form-item>
        <el-form-item prop="url" :label="$t('bookmarks.create_form.url.label')">
          <el-input class="rounded-input" v-model="createForm.url"
                    :placeholder="$t('bookmarks.create_form.url.placeholder')"/>
        </el-form-item>

        <div class="d-flex align-items-center justify-content-end">
          <el-form-item>
            <el-button type="primary" @click="storeBookmark">
              {{ $t('bookmarks.create_form.submit_btn') }}
            </el-button>
          </el-form-item>
        </div>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {validator} from "@/mixins/validator";

export default {
  name: "bookmarks",
  mixins: [validator],
  data() {
    return {
      showCreateForm: false,
      createForm: {
        title: '',
        url: '',
      },
      rules: {
        title: [
          {validator: this.backendValidator}
        ],
        url: [
          {validator: this.backendValidator}
        ],
      },
    }
  },
  computed: {
    ...mapGetters('bookmark', [
      'BOOKMARKS',
    ]),

    ...mapGetters('auth', [
      'GUEST',
    ])
  },
  mounted() {
    this.FETCH_BOOKMARKS();
  },
  methods: {
    ...mapActions('bookmark', [
      'FETCH_BOOKMARKS',
      'DELETE_BOOKMARK',
      'CREATE_BOOKMARK',
    ]),

    openBookmark(bookmark) {
      window.open(bookmark.url, '_blank').focus();
    },

    deleteBookmark(bookmark) {
      if (0 === bookmark.type) { // can delete only USERLINK (not SITELINK)
        this.$notify.error({
          title: this.$t('notifications.default.title'),
          message: this.$t('notifications.default.message'),
          position: this.notificationPosition,
          duration: this.notificationDuration,
        });
        return;
      }
      if (!this.GUEST) {
        this.DELETE_BOOKMARK(bookmark.id);
      }
    },

    addBookmark() {
      if (this.GUEST) {
        setTimeout(() => {
          this.emitter.emit("show-login-dialog");
        }, 1000);
      } else {
        this.showCreateForm = true;
      }
    },

    storeBookmark() {
      if (!this.GUEST) {
        this.CREATE_BOOKMARK(this.createForm).then((response) => {
          this.errors = {};
        }).catch((e) => {
          switch (e.response.status) {
            case 422:
              this.errors = _.assign({}, e.response.data.errors);
              break;
          }
        }).then(() => {
          this.$refs.form.validate((valid) => {
            if (valid) {
              this.resetForm();
              this.showCreateForm = false;
            }
          });
        });
      }
    },

    resetForm() {
      this.createForm.title = '';
      this.createForm.url = '';
    },
  }
}
</script>
