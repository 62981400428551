export const dates = {
  methods: {
    dateStringFromTimestamp(timestamp) {
      if (!timestamp) {
        return "-";
      }

      let d = new Date(timestamp * 1000),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) {
        month = "0" + month;
      }
      if (day.length < 2) {
        day = "0" + day;
      }

      return [day, month, year].join("/");
    },
  },
};
