<template>
  <div class="article-feed">
    <el-row type="flex" justify="start">
      <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
        <div class="px-3 pt-1">
          <div class="article-image-content">
            <img v-if="CURRENT_ARTICLE.translation.additional_image" :src="CURRENT_ARTICLE.translation.additional_image"
                 class="article-image">
            <img v-else-if="CURRENT_ARTICLE.translation.cover_image" :src="CURRENT_ARTICLE.translation.cover_image"
                 class="article-image">
            <img v-else src="@/assets/images/promo-blog.png"
                 class="article-image">
          </div>
          <div class="article-title-content">
            {{ CURRENT_ARTICLE.translation.title }}
          </div>
          <div class="article-main-content">
            <div v-html="CURRENT_ARTICLE.translation.content"></div>
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
        <div class="article-ad-container">
          <single-advertiser></single-advertiser>
        </div>
        <news-left-bottom-bar :lastViews="LAST_MONTH"></news-left-bottom-bar>
      </el-col>
    </el-row>

    <div>
      <h4 class="most-views-header text-center" v-if="MOST_VIEWS.length>0"
          v-html="$t('news.interesting_for_you_too')"></h4>
      <el-row type="flex" justify="start">
        <template v-for="article in MOST_VIEWS" :key="article.id">
          <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
            <a v-if="article.is_external" v-bind:href="article.translation.external_link"
               class="p-3 most-views-article-block" target="_blank" rel="noopener noreferrer">
              <div class="article-image-content">
                <img v-if="article.translation.cover_image" :src="article.translation.cover_image"
                     class="article-image middle">
                <img v-else src="@/assets/images/promo-blog.png"
                     class="article-image middle">
              </div>
              <div class="most-views-article-title py-2">
                {{ article.translation.title }}
              </div>
            </a>
            <router-localized-link class="p-3 most-views-article-block"
                                   :to="{name: 'news.article', params: {categorySlug: String(article.category.translation.slug), articleSlug: String(article.translation.slug)}}" v-else>
              <div class="article-image-content">
                <img v-if="article.translation.cover_image" :src="article.translation.cover_image"
                     class="view-article-image">
                <img v-else src="@/assets/images/promo-blog.png"
                     class="view-article-image">
              </div>
              <div class="most-views-article-title py-2">
                {{ article.translation.title }}
              </div>
            </router-localized-link>
          </el-col>
        </template>
      </el-row>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import NewsLeftBottomBar from "@/components/feed/news/NewsLeftBottomBar";
import {useHead} from "@vueuse/head";
import RouterLocalizedLink from "@/components/common/RouterLocalizedLink";
import SingleAdvertiser from "@/components/feed/news/SingleAdvertiser";

export default {
  name: "ArticleFeed",
  components: {
    NewsLeftBottomBar,
    SingleAdvertiser,
    RouterLocalizedLink
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('article', [
      'CURRENT_ARTICLE',
      'LAST_MONTH',
      'MOST_VIEWS'
    ]),
  },
  created() {
    let title = this.CURRENT_ARTICLE.translation.title;
    let keywords = this.CURRENT_ARTICLE.translation.meta_keyword;
    let description = this.CURRENT_ARTICLE.translation.meta_description;
    let image = this.CURRENT_ARTICLE.image_url;

    useHead({
      title: title,
      meta: [
        {
          name: `title`,
          content: title,
        },
        {
          name: `keywords`,
          content: keywords,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:image`,
          content: image,
        },
      ],
    })
  },
  methods: {
    //
  },
}
</script>
