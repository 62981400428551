import store from '@/store';

export default async (to) => {
    let currentSlug = to.params.categorySlug +'/'+ to.params.articleSlug;
        
    await store.dispatch('article/FETCH_CURRENT_ARTICLE', currentSlug);
    
    let currentArticleId = store.getters['article/CURRENT_ARTICLE'].id;

    if (undefined === currentArticleId) {
        return {name: 'news'};
    }
};
