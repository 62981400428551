<template>
  <div class="bonus-prizes">
    <div class="prizes-header text-danger" v-t="'prizes.prizes_pool'"></div>

    <div class="prizes-header2 text-danger my-3" v-t="'prizes.your_balance_is_currently'"></div>

    <div class="prizes-balance text-danger text-center">
      {{ this.USER.account.available }}
    </div>

    <div class="prizes-balance-coins text-danger text-center" v-t="'prizes.points'"></div>

    <div class="text-center cursor-pointer my-3">
      <u @click="showTransactionsDialog = true" v-t="'prizes.overview_of_your_collected_promocoins'"></u>
    </div>

    <bonus-transactions-dialog v-model:show-dialog="showTransactionsDialog"></bonus-transactions-dialog>

    <div class="prizes-header2 text-danger my-3" v-t="'prizes.redeem_great_prizes'"></div>

    <div class="prizes-text my-3" v-t="'prizes.more_coins_text'"></div>

    <div class="bonus-prizes-container secondary-feed">
      <el-row type="flex" justify="start" class="secondary-feed">
        <el-col v-for="(prize,i) in PRIZES"
                :key="i"
                :span="12"
                :xl="8">
          <bonus-prize :prize="prize"></bonus-prize>
        </el-col>
      </el-row>
    </div>

    <div class="prizes-header2 text-danger my-3" v-t="'prizes.how_doest_it_work'"></div>

    <div class="prizes-text my-3" v-html="$t('prizes.how_doest_it_work_text')"></div>

<!--    <el-divider></el-divider>-->

<!--    <div class="bonus-prizes-question mt-2">-->
<!--      <i class="icon-plus-11"></i>&nbsp; <span v-t="'prizes.how_doest_it_work_options.option1'"></span>-->
<!--    </div>-->

<!--    <el-divider class="mt-3"></el-divider>-->

<!--    <div class="bonus-prizes-question mt-2">-->
<!--      <i class="icon-plus-11"></i>&nbsp; <span v-t="'prizes.how_doest_it_work_options.option2'"></span>-->
<!--    </div>-->

<!--    <el-divider class="mt-3"></el-divider>-->

<!--    <div class="bonus-prizes-question mt-2">-->
<!--      <i class="icon-plus-11"></i>&nbsp; <span v-t="'prizes.how_doest_it_work_options.option3'"></span>-->
<!--    </div>-->

<!--    <el-divider class="mt-3"></el-divider>-->

<!--    <div class="bonus-prizes-question mt-2">-->
<!--      <i class="icon-plus-11"></i>&nbsp; <span v-t="'prizes.how_doest_it_work_options.option4'"></span>-->
<!--    </div>-->

<!--    <el-divider class="mt-3"></el-divider>-->

<!--    <div class="bonus-prizes-question mt-2">-->
<!--      <i class="icon-plus-11"></i>&nbsp; <span v-t="'prizes.how_doest_it_work_options.option5'"></span>-->
<!--    </div>-->

<!--    <el-divider class="mt-3"></el-divider>-->

<!--    <div class="bonus-prizes-question mt-2">-->
<!--      <i class="icon-plus-11"></i>&nbsp; <span v-t="'prizes.how_doest_it_work_options.option6'"></span>-->
<!--    </div>-->

    <div class="prizes-header2 text-danger my-3" v-t="'prizes.gift_system'"></div>

    <div class="prizes-text my-3" v-html="$t('prizes.gift_system_text')"></div>

    <table class="mt-3 bonus-prizes-table">
      <thead>
      <tr>
        <th>{{ $t('prizes.gift_system_table.headers.1') }}</th>
        <th>{{ $t('prizes.gift_system_table.headers.2') }}</th>
        <th>{{ $t('prizes.gift_system_table.headers.3') }}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>{{ $t('prizes.gift_system_table.raws.1', {amount: 500}) }}</td>
        <td>{{ $t('prizes.gift_system_table.raws.2', {amount: 5}) }}</td>
        <td>{{ $t('prizes.gift_system_table.raws.3') }}</td>
      </tr>
      <tr>
        <td>{{ $t('prizes.gift_system_table.raws.1', {amount: 1500}) }}</td>
        <td>{{ $t('prizes.gift_system_table.raws.2', {amount: 10}) }}</td>
        <td>{{ $t('prizes.gift_system_table.raws.3') }}</td>
      </tr>
      <tr>
        <td>{{ $t('prizes.gift_system_table.raws.1', {amount: 5000}) }}</td>
        <td>{{ $t('prizes.gift_system_table.raws.2', {amount: 35}) }}</td>
        <td>{{ $t('prizes.gift_system_table.raws.3') }}</td>
      </tr>
      <tr>
        <td>{{ $t('prizes.gift_system_table.raws.1', {amount: 10000}) }}</td>
        <td>{{ $t('prizes.gift_system_table.raws.2', {amount: 50}) }}</td>
        <td>{{ $t('prizes.gift_system_table.unlimited') }}</td>
      </tr>
      </tbody>
    </table>

    <div class="prizes-header2 text-danger my-3" v-t="'prizes.warranties_header'"></div>

    <div class="prizes-text my-3" v-html="$t('prizes.warranties_text')"></div>
  </div>
</template>

<script>
import BonusPrize from '@/components/feed/bonuses/BonusPrize';
import BonusTransactionsDialog from '@/components/feed/bonuses/BonusTransactionsDialog';
import {mapActions, mapGetters} from "vuex";

export default {
  name: "bonus-prizes",
  components: {BonusPrize, BonusTransactionsDialog},
  data() {
    return {
      showTransactionsDialog: false
    }
  },
  mounted() {
    this.FETCH_PRIZES();
  },
  computed: {
    ...mapGetters('prize', [
      'PRIZES',
    ]),
    ...mapGetters('auth', [
      'USER',
    ]),
  },
  methods: {
    ...mapActions('prize', [
      'FETCH_PRIZES'
    ]),
  },
}
</script>
