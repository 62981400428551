<script>
import {h} from 'vue'
import {compile} from "vue/dist/vue.esm-bundler.js"

export default {
  name: "dynamic-content",
  props: ['content'],
  render() {
    // use an array to return multiple root nodes
    return [
      h(compile(this.content)),
    ]
  }
}
</script>

