<template>
  <div class="d-block position-relative" :style="{width: fullWidth + `px`, height: fullHeight + `px`}">

    <div ref="drag-zoom">
      <vue-drag-zoom
          ref="vue-drag-zoom"
          :area-node="node"
          allow-zoom
          :range="0.5"
          :max-zoom="3"
          :min-zoom="1">
        <img ref="zoom_image" class="folder-page-image" :src="image" draggable="false">

        <map name="zoom-image-map" v-if="fullHeight && fullWidth && showMap && currentZoom === 1">
          <folder-page-area v-for="item in items"
                            :key="item.id"
                            :title="item.title"
                            :url="item.url"
                            :position="item.position"
                            :container-height="fullHeight"
                            :container-width="fullWidth"></folder-page-area>
        </map>
      </vue-drag-zoom>
      <div class="pz-zoom-button pz-zoom-control-position-bottom"
           :class="{'pz-zoom-button-out': currentZoom > 1}"
           @click="clickZoom"></div>
    </div>

  </div>
</template>

<script>
import FolderPageArea from "@/components/feed/folder/FolderPageArea";
import VueDragZoom from 'vue-drag-zoom';

export default {
  name: "folder-page-zoomer",
  components: {FolderPageArea, VueDragZoom},
  props: {
    items: Array,
    showMap: {
      type: Boolean,
      default: true
    },
    image: {
      type: String,
      default: ''
    },
    carouselIndex: {
      type: Number,
    },
  },
  data() {
    return {
      isMounted: false,
      fullHeight: null,
      fullWidth: null,
      node: null,
    }
  },
  computed: {
    currentZoom() {
      if (!this.isMounted) {
        return 1;
      }
      return this.$refs['vue-drag-zoom'].currentZoom;
    }
  },
  mounted() {
    this.isMounted = true;
    this.node = this.$refs['drag-zoom'];
    new ResizeObserver(this.onResize).observe(this.$refs.zoom_image);
  },
  methods: {
    onResize() {
      if (this.$refs.zoom_image) {
        this.fullHeight = this.$refs.zoom_image.clientHeight;
        this.fullWidth = this.$refs.zoom_image.clientWidth;
      }
    },
    resetZoom() {
      if (!this.isMounted) {
        return;
      }
      this.$refs['vue-drag-zoom'].currentZoom = 1;
    },
    clickZoom() {
      if (this.currentZoom > 1) {
        this.$refs['vue-drag-zoom'].currentZoom = 1;
      } else {
        this.$refs['vue-drag-zoom'].currentZoom = 2;
      }
    }
  },
  watch: {
    carouselIndex() {
      this.resetZoom();
    }
  }
}
</script>
