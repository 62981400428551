<template>
  <el-row justify="center" class="bg-folder-secondary">
    <el-col :span="23">
      <div class="container news-container">
        <el-row type="flex" justify="space-between">
          <el-col :span="24">
            <div class="main-container-wrapper">
              <div class="blog-category-wrap px-3">
                <categories-list></categories-list>
              </div>
              <article-feed></article-feed>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import CategoriesList from "@/components/feed/news/CategoriesList";
import ArticleFeed from "@/views/news/ArticleFeed";

export default {
  name: 'ArticleView',
  components: {
    CategoriesList,
    ArticleFeed
  },
}
</script>
