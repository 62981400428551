<template>
  <el-row type="flex" justify="start" class="p-3 my-3 rounded" :class="bgClass" v-if="LOCALE === 'nl'">
    <el-col :span="8" :xs="24">
      <div class="d-flex align-items-center justify-content-center" style="font-size: 100px">
        <i class="icon-Google-Chrome"><span class="path1"></span><span
            class="path2"></span><span
            class="path3"></span><span class="path4"></span><span class="path5"></span></i>
      </div>
    </el-col>
    <el-col :span="16" :xs="24">
      <div>
        <span>{{ $t('ad.extension.header', {title: title}) }}</span>
        <br>
        <span class="font-weight-bold text-decoration-underline cursor-pointer" @click="goLink1" v-t="'ad.extension.link1_text'"></span>
        <br>

        <el-row type="flex" justify="start">
          <el-col :span="12" :lg="18" :xs="18">
            <el-button :type="btnType"
                       class="mt-3 w-100"
                       @click="goLink2">
              <span class="font-weight-bold" :class="btnTextClass" v-t="'ad.extension.link2_text'"></span>
            </el-button>
          </el-col>
        </el-row>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "extension-preview",
  props: {
    bgClass: {
      type: String,
      default: 'bg-green-light',
    },
    btnType: {
      type: String,
      default: 'success',
    },
    btnTextClass: {
      type: String,
      default: 'text-white',
    },
    title: {
      type: String,
      default: 'Zalando',
    },
  },
  computed: {
    ...mapGetters('auth', [
      'LOCALE',
    ])
  },
  methods: {
    goLink1() {
      window.open('https://www.hln.be/consument/promojagers-supertip-zo-vind-je-automatisch-kortingscodes-in-webshops~a811f683/', '_blank');
    },
    goLink2() {
      window.open('https://chrome.google.com/webstore/detail/promojagersbe-extension/nfdcnacbhjonmghbbnadkjcejbdlnaep?hl=nl', '_blank');
    },
  }
}
</script>
