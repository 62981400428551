<template>
  <router-localized-link class="d-flex" :to="{name : 'home'}">
    <svg
        :class="customClass"
        :width="width"
        :height="height"
        viewBox="0 0 14 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.5 7.37606L6.75 2.13637L0 7.37606V5.23969L6.75 0L13.5 5.23969V7.37606ZM11.8125 7.18537V12.2479H8.4375V8.87287H5.0625V12.2479H1.6875V7.18537L6.75 3.3885L11.8125 7.18537Z" fill="#FE0611"/>
    </svg>
    <slot/>
  </router-localized-link>
</template>

<script>
import RouterLocalizedLink from "@/components/common/RouterLocalizedLink.vue";
export default {
  name: "HomeButton",
  props: {
    customClass: {
      type: String,
      default: ''
    },
    width: Number,
    height: Number,
  },
  components: {RouterLocalizedLink}
}
</script>

<style scoped>

</style>