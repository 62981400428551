<template>
  <div class="left-bar-user-menu">

    <el-divider class="my-3 bold-divider" :class="dividersClasses[0]"></el-divider>
    <div class="user-menu-item">
      <router-localized-link :to="{name: 'favourites'}">
        <i class="icon-heart-2"></i> <span v-t="'menu.user.favourites'"></span>
      </router-localized-link>
    </div>

    <el-divider class="my-3 bold-divider" :class="dividersClasses[1]"></el-divider>

    <div class="user-menu-item">
      <router-localized-link :to="{name: 'prizes-pool'}">
        <i class="icon-cup"></i> <span v-t="'menu.main.prizes'"></span>
      </router-localized-link>
    </div>

    <el-divider class="my-3 bold-divider" :class="dividersClasses[2]"></el-divider>

    <div class="user-menu-item">
      <router-localized-link class="text-dark" v-t="'menu.main.invite_friend'" :to="{name: 'referral_page'}"></router-localized-link>
    </div>

    <el-divider class="my-3 bold-divider" :class="dividersClasses[3]"></el-divider>

    <div class="user-menu-item">
      <router-localized-link class="text-dark" v-t="'menu.user.about_points'" :to="getPagePathBySlug('bonus-points')"></router-localized-link>
    </div>

    <el-divider class="my-3 bold-divider" :class="dividersClasses[4]"></el-divider>

    <div class="user-menu-item cursor-pointer" v-t="'menu.user.settings'" @click="showSettings"></div>

    <el-divider class="my-3 bold-divider"></el-divider>

    <div class="user-menu-item cursor-pointer" v-t="'menu.main.logout'" @click="logout"></div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import RouterLocalizedLink from "@/components/common/RouterLocalizedLink";

export default {
  name: "left-bar-user-menu",
  components: {RouterLocalizedLink},
  data() {
    return {
      langSwitch: false
    }
  },
  computed: {
    dividersClasses() {
      return {
        0: {
          'danger-divider': ['favourites'].includes(this.currentRouteName)
        },
        1: {
          'danger-divider': ['favourites', 'prizes-pool'].includes(this.currentRouteName)
        },
        2: {
          'danger-divider': ['prizes-pool', 'referral_page'].includes(this.currentRouteName)
        },
        3: {
          'danger-divider': ['referral_page'].includes(this.currentRouteName) || ['bonus-points'].includes(this.currentPageSlug)
        },
        4: {
          'danger-divider': ['bonus-points'].includes(this.currentPageSlug)
        }
      }
    }
  },
  methods: {
    ...mapActions('auth', [
      'LOGOUT',
    ]),

    logout() {
      this.LOGOUT().then(() => {
        this.routerPush({name: 'home'})
      })
    },

    showSettings() {
      this.emitter.emit("show-settings-dialog");
    }
  }
}
</script>
