export default {
  namespaced: true,
  state: {
    pages: [],
    promise: null,
  },

  getters: {
    PAGES(state) {
      return state.pages;
    },

    PAGES_LOADED(state) {
      return Object.keys(state.pages).length > 0;
    },
  },

  actions: {
    FETCH_PAGES({ state, commit }) {
      if (state.promise) {
        return state.promise;
      }

      let promise = axios.get("/pages").then((response) => {
        commit("SET_PAGES", response.data.data);
      }).catch((e) => {
        console.log(e);
      }).then(() => {
        commit('UPDATE_PROMISE_PAGES', null);
      });

      commit('UPDATE_PROMISE_PAGES', promise);
      return promise;
    }
  },

  mutations: {
    SET_PAGES(state, data) {
      return _.set(state, 'pages', data);
    },

    UPDATE_PROMISE_PAGES(state, data) {
      return state.promise = data;
    },
  },
};
