<template>
  <default-grid>
    <template v-slot:left-container>
      <left-bar show-store-logo></left-bar>
    </template>
    <template v-slot:main-container>
      <div class="promos-feed">
        <h1 class="text-capitalize text-danger font-size-26 my-3" v-text="h1Text"></h1>

        <tabs :tabs="tabs" :default="currentTab" @change="tabChange" :change-query="true"></tabs>

        <el-row type="flex" justify="start" class="secondary-feed" v-if="['folder-promos'].includes(currentTab)">
          <template v-for="(promo, i) in PROMOS" :key="i">
            <el-col :xs="12" :sm="8" :md="8" :lg="12" :xl="8">
              <folder-promo-preview v-if="'FolderItem' === promo.morphable_type"
                                    :folder-promo="promo.data"></folder-promo-preview>
            </el-col>
          </template>
        </el-row>

        <el-row type="flex" justify="start" v-else>
          <template v-for="(promo, i) in PROMOS" :key="i">
            <el-col :span="24">
              <folder-promo v-if="'FolderItem' === promo.morphable_type"
                            :folder-promo="promo.data"></folder-promo>
              <online-promo v-if="'OnlinePromo' === promo.morphable_type"
                            :online-promo="promo.data"></online-promo>
              <post v-if="'Post' === promo.morphable_type"
                              :post="promo.data"></post>
            </el-col>
          </template>
        </el-row>

        <div class="w-100 d-flex align-items-center justify-content-center" v-if="!PROMOS_IS_COMPLETE">
          <el-button type="primary"
                     :class="{'w-75':xsOnly,'w-50':!xsOnly}"
                     @click="loadPromos">
            <span v-t="'webshop.load_more'"></span>
          </el-button>
        </div>

        <h2 class="text-danger font-size-20 my-3" v-t="'webshop.seo.top_questions'"></h2>

        <div v-for="(faqItem, key) in faqData" :key="key">
          <el-divider class="my-3"></el-divider>

          <div class="font-size-20">
            <i class="icon-minus"></i>&nbsp; <span v-text="faqItem.question"></span>
          </div>

          <div class="font-size-14 m-3" v-html="faqItem.answer"></div>
        </div>

        <div class="my-5 seo-content" v-html="CURRENT_STORE.translation.seo_content"></div>

        <div v-if="last10PostsTableData.length">
          <h3 class="h4" v-html="$t('store.seo.table_header',{store: CURRENT_STORE.translation.title})"></h3>

          <div class="mb-5">
            <el-table :data="last10PostsTableData" border>
              <el-table-column prop="published_at" :label="$t('store.seo.table.row_1')" :min-width="25"/>
              <el-table-column prop="short_content" :label="$t('store.seo.table.row_2')" :min-width="75"/>
            </el-table>
          </div>
        </div>

      </div>
    </template>
  </default-grid>
</template>

<script>
import Post from "@/components/feed/post/Post";
import OnlinePromo from "@/components/feed/promo/OnlinePromo";
import FolderPromo from "@/components/feed/promo/FolderPromo";
import Tabs from "@/components/feed/Tabs";
import LeftBar from "@/components/left_bar/LeftBar";
import DefaultGrid from "@/views/DefaultGrid";
import {mapActions, mapGetters} from "vuex";
import {useHead} from "@vueuse/head";
import {dates} from "@/mixins/dates";
import {breadcrumbs} from "@/mixins/seo/breadcrumbs";
import FolderPromoPreview from "@/components/feed/promo/FolderPromoPreview";

export default {
  name: "StoreView",
  components: {
    FolderPromoPreview,
    Post,
    OnlinePromo,
    FolderPromo,
    Tabs,
    LeftBar,
    DefaultGrid
  },
  mixins: [dates, breadcrumbs],
  data() {
    return {
      currentTab: _.get(this.$route.query, 'tab', 'default'),
      fetchOptions: {
        include_filters: ['types', 'morphable_types'],
        morphable_types: [],
        types: [],
        stores: [],

        order: '',
        only_popular_promos: '',
      },
      faqData: []
    }
  },
  computed: {
    ...mapGetters('promo', [
      'PROMOS',
      'PROMOS_MORPHABLE_TYPES',
      'PROMOS_IS_COMPLETE',
    ]),
    ...mapGetters('store', [
      'CURRENT_STORE',
    ]),
    ...mapGetters('filter', [
      'FILTERS_VALUES',
    ]),

    tabs() {
      let tabs = {};
      tabs["default"] = this.$t('store.tabs.default');
      if (this.CURRENT_STORE.total_folders > 0) {
        tabs["folder-promos"] = this.$t('store.tabs.folder-promos');
      }
      tabs["more-hits"] = this.$t('store.tabs.more-hits');
      tabs["recent"] = this.$t('store.tabs.recent');

      return tabs;
    },

    h1Text() {
      let date = new Date();
      let m = date.toLocaleString(this.$i18n.locale, {month: 'long'});
      let y = date.getFullYear();
      return this.$t('store.store_page_h1', {store: this.CURRENT_STORE.translation.title, month: m, year: y});
    },

    last10PostsTableData() {
      let thisElem = this;
      return _.map(this.CURRENT_STORE.last_posts, function (post) {
        return {
          published_at: thisElem.dateStringFromTimestamp(_.get(post, 'published_at.timestamp', null)),
          short_content: post.short_content,
        }
      });
    }
  },
  created() {
    useHead({
      // Can be static or computed
      script: [
        {
          type: "application/ld+json",
          children: JSON.stringify(this.getFaqStructure())
        },
        {
          type: "application/ld+json",
          children: JSON.stringify(this.getBreadcrumbStructure())
        },
      ],
    })
  },
  beforeMount() {
    this.RESET_FILTERS();
    this.RESET_PROMOS();
  },
  methods: {
    ...mapActions('filter', [
      'SET_FILTER_ACTIVE',
      'SET_FILTER_INACTIVE',
      'RESET_FILTERS',
    ]),
    ...mapActions('promo', [
      'FETCH_PROMOS',
      'RESET_PROMOS',
    ]),
    loadPromos() {
      return this.FETCH_PROMOS(this.fetchOptions);
    },
    prepareFetchOptions() {
      this.SET_FILTER_ACTIVE('types_filter');

      this.fetchOptions.store = this.CURRENT_STORE.id;
      this.fetchOptions.types = this.FILTERS_VALUES.types_filter;
      this.fetchOptions.morphable_types = this.FILTERS_VALUES.morphable_types_filter;

      this.fetchOptions.order = '';
      this.fetchOptions.only_popular_promos = '';
      switch (this.currentTab) {
        case 'folder-promos':
          this.SET_FILTER_INACTIVE('types_filter');
          this.fetchOptions.morphable_types = ['FolderItem'];
          break;
        case 'more-hits':
          this.fetchOptions.order = 'hits_count';
          break;
        case 'recent':
          this.fetchOptions.order = 'published_at';
          break;
        case 'default':
        default:
          this.fetchOptions.only_popular_promos = 1;
          break;
      }
      return true;
    },
    tabChange(tabKey) {
      this.currentTab = tabKey;
      this.RESET_PROMOS();
      this.prepareFetchOptions() && this.loadPromos();
    },
    getFaqStructure() {
      let params = {
        "store": this.CURRENT_STORE.translation.title,
        "total_posts": this.CURRENT_STORE.total_posts,
        "last_post_content": this.CURRENT_STORE.last_post_content,
      };
      let mainEntity = [];
      this.faqData = [];
      for (let i = 1; i <= 4; i++) {
        mainEntity.push({
          "@type": "Question",
          "name": this.$t("store.seo.question_" + i, params),
          "acceptedAnswer": {
            "@type": "Answer",
            "text": this.$t('store.seo.answer_' + i, params)
          }
        });
        this.faqData.push({
          question: this.$t("store.seo.question_" + i, params),
          answer: this.$t('store.seo.answer_' + i, params)
        });
      }
      return {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": mainEntity
      };
    }
  },
  watch: {
    FILTERS_VALUES: {
      // the callback will be called immediately after the start of the observation
      deep: true,
      handler(newVal, oldVal) {
        this.RESET_PROMOS();
        this.prepareFetchOptions() && this.loadPromos();
      }
    }
  }
}
</script>
