const getDefaultState = () => {
    return {
        promos: [],
        queryParams: {
            page: 0,
            per_page: 9
        },
        is_complete: false,
        promise: null,
        total: 0,

        filters: {
            morphable_types: [],
            types: [],
            categories: [],
            webshops: [],
            stores: [],
            brands: [],
            prices: [],
        }
    }
};

const getPromoNamespace = (morphable_type) => {
    let namespace = 'posts';
    switch (morphable_type) {
        case "Post":
            namespace = 'posts';
            break;
        case "OnlinePromo":
            namespace = 'online-promos';
            break;
        case "FolderItem":
            namespace = 'folder-items';
            break;
    }
    return namespace;
};

export default {
    namespaced: true,
    state: () => (getDefaultState()),

    getters: {
        PROMOS(state) {
            return state.promos;
        },

        PROMOS_PROMISE(state) {
            return state.promise;
        },

        PROMOS_IS_COMPLETE(state) {
            return state.is_complete;
        },

        PROMOS_MORPHABLE_TYPES(state) {
            return state.filters.morphable_types;
        },

        PROMOS_TYPES(state) {
            return state.filters.types;
        },

        PROMOS_CATEGORIES(state) {
            return state.filters.categories;
        },

        PROMOS_WEBSHOPS(state) {
            return state.filters.webshops;
        },

        PROMOS_STORES(state) {
            return state.filters.stores;
        },

        PROMOS_BRANDS(state) {
            return state.filters.brands;
        },

        PROMOS_PRICES(state) {
            return state.filters.prices;
        },

        PROMOS_TOTAL(state) {
            return state.total;
        }
    },

    actions: {
        FETCH_PROMOS({state, commit}, options) {
            if (state.promise) {
                return state.promise;
            }

            let params = _.assign(_.assign({}, state.queryParams), options);
            params.page += 1;
            let includeFilters = 1 === params.page;
            if (!includeFilters) {
                params.include_filters = [];
            }
            params = _.pickBy(params, function (elem) {
                if (undefined === elem) {
                    return false;
                }
                if (Array.isArray(elem)) {
                    return elem.length;
                }
                return elem.toString().length;
            });

            let promise = axios.get('/feed-items?', {params}).then((response) => {
                commit('UPDATE_PROMOS', response.data);
                commit('UPDATE_TOTAL', response.data.meta.total);

                if (includeFilters) {
                    commit('UPDATE_TYPES', _.get(response,'data.meta.types',[]));
                    commit('UPDATE_MORPHABLE_TYPES', _.get(response,'data.meta.morphable_types',[]));
                    commit('UPDATE_CATEGORIES', _.get(response,'data.meta.categories',[]));
                    commit('UPDATE_WEBSHOPS', _.get(response,'data.meta.webshops',[]));
                    commit('UPDATE_STORES', _.get(response,'data.meta.stores',[]));
                    commit('UPDATE_BRANDS', _.get(response,'data.meta.brands',[]));
                    commit('UPDATE_PRICES', _.get(response,'data.meta.prices',[]));
                }
            }).catch((e) => {
                console.log(e);
            }).then(() => {
                commit('UPDATE_PROMISE_PROMOS', null);
            });

            commit('UPDATE_PROMISE_PROMOS', promise);
            return promise;
        },

        RESET_PROMOS({commit}) {
            commit('SET_DEFAULT_DATA');
        },

        SET_HIT({state, commit}, data) {
            let namespace = getPromoNamespace(data.morphable_type);
            return axios.put(`/${namespace}/${data.morphable_id}/set-hit`).then((response) => {
                commit('UPDATE_PROMO', {
                    morphable_type: data.morphable_type,
                    morphable_id: data.morphable_id,
                    data: response.data.data
                });
            });
        },

        SET_DISLIKE({state, commit}, data) {
            let namespace = getPromoNamespace(data.morphable_type);
            return axios.put(`/${namespace}/${data.morphable_id}/set-dislike`).then((response) => {
                commit('UPDATE_PROMO', {
                    morphable_type: data.morphable_type,
                    morphable_id: data.morphable_id,
                    data: response.data.data
                });
            });
        },

        SET_FAVORITE({state, commit}, data) {
            let namespace = getPromoNamespace(data.morphable_type);
            return axios.put(`/${namespace}/${data.morphable_id}/set-favorite`).then((response) => {
                commit('UPDATE_PROMO', {
                    morphable_type: data.morphable_type,
                    morphable_id: data.morphable_id,
                    data: response.data.data
                });
            });
        },

        REMOVE_FAVORITE({state, commit}, data) {
            let namespace = getPromoNamespace(data.morphable_type);
            return axios.put(`/${namespace}/${data.morphable_id}/remove-favorite`).then((response) => {
                commit('UPDATE_PROMO', {
                    morphable_type: data.morphable_type,
                    morphable_id: data.morphable_id,
                    data: response.data.data
                });
            });
        },

        SET_SUBSCRIPTION({state, commit}, data) {
            let namespace = getPromoNamespace(data.morphable_type);
            return axios.put(`/${namespace}/${data.morphable_id}/subscribe`).then((response) => {
                commit('UPDATE_PROMO', {
                    morphable_type: data.morphable_type,
                    morphable_id: data.morphable_id,
                    data: response.data.data
                });
            });
        },

        REMOVE_SUBSCRIPTION({state, commit}, data) {
            let namespace = getPromoNamespace(data.morphable_type);
            return axios.put(`/${namespace}/${data.morphable_id}/unsubscribe`).then((response) => {
                commit('UPDATE_PROMO', {
                    morphable_type: data.morphable_type,
                    morphable_id: data.morphable_id,
                    data: response.data.data
                });
            });
        },
    },

    mutations: {
        UPDATE_PROMOS(state, data) {
            let promos = data.data;
            state.queryParams.page += _.isEmpty(promos) ? 0 : 1;
            state.is_complete = _.isEmpty(promos) || _.get(data, 'meta.current_page', 0) === _.get(data, 'meta.last_page', 1);
            return state.promos = _.union(state.promos, promos);
        },

        UPDATE_PROMO(state, instance) {
            _.set(instance, 'data', _.pickBy(instance.data, function(value, key) {
                return ['is_hit', 'is_dislike', 'is_favourite', 'favourite_at', 'subscriptions', 'hits', 'dislikes'].includes(key);
            }));

            // Find index and instance
            const index = _.findIndex(state.promos, {
                morphable_type: instance.morphable_type,
                morphable_id: instance.morphable_id
            });
            let promo = _.find(state.promos, {
                morphable_type: instance.morphable_type,
                morphable_id: instance.morphable_id
            });
            promo = _.merge(promo, instance);

            // Replace item in collection
            state.promos.splice(index, 1, promo);
        },

        UPDATE_PROMISE_PROMOS(state, data) {
            return state.promise = data;
        },

        UPDATE_TYPES(state, types) {
            return state.filters.types = types;
        },

        UPDATE_MORPHABLE_TYPES(state, morphable_types){
            return state.filters.morphable_types = morphable_types;
        },

        UPDATE_CATEGORIES(state, categories) {
            return state.filters.categories = categories;
        },

        UPDATE_WEBSHOPS(state, webshops) {
            return state.filters.webshops = webshops;
        },

        UPDATE_STORES(state, stores) {
            return state.filters.stores = stores;
        },

        UPDATE_BRANDS(state, brands) {
            return state.filters.brands = brands;
        },

        UPDATE_PRICES(state, prices) {
            return state.filters.prices = prices;
        },

        UPDATE_TOTAL(state, total) {
            if (total === undefined) {
                total = 0;
            }
            return state.total = total;
        },

        SET_DEFAULT_DATA(state) {
            let defaultState = getDefaultState();
            _.unset(defaultState, 'filters');
            return _.assign(state, defaultState);
        }
    }
}
