<template>
  <div class="right-bar-container">
    <el-scrollbar height="calc(100vh - 70px - 50px)">
      <el-divider class="bold-divider mb-3"></el-divider>

      <advertiser></advertiser>

      <div style="min-height: 210px"></div>
    </el-scrollbar>
  </div>
</template>

<script>
import Advertiser from '@/components/right_bar/Advertiser';

export default {
  name: "right-bar",
  components: {Advertiser}
}
</script>
