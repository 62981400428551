<template>
  <default-grid>
    <template v-slot:left-container>
      <left-bar show-webshop-logo></left-bar>
    </template>
    <template v-slot:main-container>
      <div class="promos-feed">
        <h1 class="text-capitalize text-danger font-size-26 my-3" v-text="h1Text"></h1>

        <tabs :tabs="tabs" :default="currentTab" @change="tabChange" :change-query="true"></tabs>

        <el-row type="flex" justify="start">
          <template v-for="(promo, i) in PROMOS" :key="i">
            <el-col :span="24">
              <folder-promo v-if="'FolderItem' === promo.morphable_type"
                            :folder-promo="promo.data"></folder-promo>
              <online-promo v-if="'OnlinePromo' === promo.morphable_type"
                            :online-promo="promo.data"></online-promo>
              <post v-if="'Post' === promo.morphable_type"
                              :post="promo.data"></post>
            </el-col>
          </template>
        </el-row>

        <div class="w-100 d-flex align-items-center justify-content-center" v-if="!PROMOS_IS_COMPLETE">
          <el-button type="primary"
                     :class="{'w-75':xsOnly,'w-50':!xsOnly}"
                     @click="loadPromos">
            <span v-t="'webshop.load_more'"></span>
          </el-button>
        </div>

<!--        <extension-preview></extension-preview>-->

        <h2 class="text-danger font-size-20 my-3" v-text="h2Text"></h2>

        <extension-preview :title="CURRENT_WEBSHOP.translation.title"
                           bg-class="bg-yellow-light"
                           btn-type="warning"
                           btn-text-class="text-dark"></extension-preview>

        <h2 class="text-danger font-size-20 my-3" v-t="'webshop.seo.top_questions'"></h2>

        <div v-for="(faqItem, key) in faqData" :key="key">
          <el-divider class="my-3"></el-divider>

          <div class="font-size-20">
            <i class="icon-minus"></i>&nbsp; <span v-text="faqItem.question"></span>
          </div>

          <div class="font-size-14 m-3" v-html="faqItem.answer"></div>
        </div>

        <div class="my-5 seo-content" v-html="CURRENT_WEBSHOP.translation.seo_content"></div>

        <div v-if="seoTableData.length">
          <h3 class="h4" v-html="$t('webshop.seo.table_header',{webshop: CURRENT_WEBSHOP.translation.title})"></h3>

          <div class="mb-5">
            <el-table :data="seoTableData" border>
              <el-table-column prop="published_at" :label="$t('webshop.seo.table.row_1')" :min-width="25"/>
              <el-table-column prop="short_content" :label="$t('webshop.seo.table.row_2')" :min-width="50"/>

              <el-table-column prop="discount_code" :label="$t('webshop.seo.table.row_3')" :min-width="25">
                <template #default="scope">
                <span :class="scope.row.code_class">
                  {{ scope.row.discount_code }}
                </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>

      </div>
    </template>
  </default-grid>
</template>

<script>
import {useHead} from "@vueuse/head";
import DefaultGrid from "@/views/DefaultGrid";
import {mapActions, mapGetters} from "vuex";
import LeftBar from "@/components/left_bar/LeftBar";
import Tabs from "@/components/feed/Tabs";
import FolderPromo from "@/components/feed/promo/FolderPromo";
import OnlinePromo from "@/components/feed/promo/OnlinePromo";
import Post from "@/components/feed/post/Post";
import ExtensionPreview from "@/components/footer/ExtensionPreview";
import {dates} from "@/mixins/dates";
import {breadcrumbs} from "@/mixins/seo/breadcrumbs";

export default {
  name: "WebshopView",
  components: {
    ExtensionPreview,
    Post,
    OnlinePromo,
    FolderPromo,
    Tabs,
    LeftBar,
    DefaultGrid
  },
  mixins: [dates, breadcrumbs],
  data() {
    return {
      currentTab: _.get(this.$route.query, 'tab', 'default'),
      fetchOptions: {
        include_filters: ['types', 'morphable_types'],
        morphable_types: [],
        types: [],
        webshops: [],

        order: '',
        type_kind: '',
        only_popular_promos: '',
      },
      faqData: []
    }
  },
  computed: {
    ...mapGetters('promo', [
      'PROMOS',
      'PROMOS_TYPES',
      'PROMOS_MORPHABLE_TYPES',
      'PROMOS_IS_COMPLETE',
    ]),
    ...mapGetters('webshop', [
      'CURRENT_WEBSHOP',
    ]),
    ...mapGetters('filter', [
      'FILTERS_VALUES',
    ]),

    tabs() {
      let tabs = {};
      tabs["default"] = this.$t('webshop.tabs.default');
      if (this.CURRENT_WEBSHOP.total_codes > 0) {
        tabs["codes"] = this.$t('webshop.tabs.codes');
      }
      if (this.CURRENT_WEBSHOP.total_online_promos > 0) {
        tabs["online-promos"] = this.$t('webshop.tabs.online-promos');
      }
      tabs["more-hits"] = this.$t('webshop.tabs.more-hits');
      tabs["recent"] = this.$t('webshop.tabs.recent');

      return tabs;
    },

    h1Text() {
      let date = new Date();
      let m = date.toLocaleString(this.$i18n.locale, {month: 'long'});
      let y = date.getFullYear();
      return this.$t('webshop.webshop_page_h1', {webshop: this.CURRENT_WEBSHOP.translation.title, month: m, year: y});
    },

    h2Text() {
      return this.$t('webshop.webshop_page_h2', {webshop: this.CURRENT_WEBSHOP.translation.title});
    },

    seoTableData() {
      let thisElem = this;
      return _.map(this.CURRENT_WEBSHOP.live_discounts, function (post) {
        let codeClass = 'text-dark';
        if (post.expired_at) {
          let date = new Date(post.expired_at.timestamp * 1000);
          codeClass = (date > new Date()) ? 'text-success' : 'text-danger';
        }

        return {
          published_at: thisElem.dateStringFromTimestamp(_.get(post, 'published_at.timestamp', null)),
          short_content: post.short_content,
          discount_code: post.discount_code,
          code_class: codeClass,
        }
      });
    }
  },
  created() {
    useHead({
      // Can be static or computed
      script: [
        {
          type: "application/ld+json",
          children: JSON.stringify(this.getFaqStructure())
        },
        {
          type: "application/ld+json",
          children: JSON.stringify(this.getBreadcrumbStructure())
        },
      ],
    })
  },
  beforeMount() {
    this.RESET_FILTERS();
    this.RESET_PROMOS();
  },
  methods: {
    ...mapActions('filter', [
      'SET_FILTER_ACTIVE',
      'SET_FILTER_INACTIVE',
      'RESET_FILTERS',
    ]),
    ...mapActions('promo', [
      'FETCH_PROMOS',
      'RESET_PROMOS',
    ]),
    loadPromos() {
      return this.FETCH_PROMOS(this.fetchOptions);
    },
    prepareFetchOptions() {
      this.SET_FILTER_ACTIVE('types_filter');

      this.fetchOptions.webshop = this.CURRENT_WEBSHOP.id;
      this.fetchOptions.types = this.FILTERS_VALUES.types_filter;
      this.fetchOptions.morphable_types = this.FILTERS_VALUES.morphable_types_filter;

      this.fetchOptions.order = '';
      this.fetchOptions.type_kind = '';
      this.fetchOptions.only_popular_promos = '';
      switch (this.currentTab) {
        case 'codes':
          this.SET_FILTER_INACTIVE('types_filter');
          this.fetchOptions.order = 'published_at';
          this.fetchOptions.morphable_types = ['Post'];
          this.fetchOptions.type_kind = 2;
          break;
        case 'online-promos':
          this.routerPush({
            name: 'promos',
            query: {
              'webshops_filter[]': [this.CURRENT_WEBSHOP.id],
            }
          });
          return false;
        case 'more-hits':
          this.fetchOptions.order = 'hits_count';
          break;
        case 'recent':
          this.fetchOptions.order = 'published_at';
          break;
        case 'default':
        default:
          this.fetchOptions.order = 'codes_first';
          this.fetchOptions.only_popular_promos = 1;
          break;
      }
      return true;
    },
    tabChange(tabKey) {
      this.currentTab = tabKey;
      this.RESET_PROMOS();
      this.prepareFetchOptions() && this.loadPromos();
    },
    getFaqStructure() {
      let params = {
        "webshop": this.CURRENT_WEBSHOP.translation.title,
        "total_posts": this.CURRENT_WEBSHOP.total_posts,
        "total_codes": this.CURRENT_WEBSHOP.total_valid_codes,
        "last_post_content": this.CURRENT_WEBSHOP.last_post_content,
      };
      let mainEntity = [];
      this.faqData = [];
      for (let i = 1; i <= 4; i++) {
        mainEntity.push({
          "@type": "Question",
          "name": this.$t("webshop.seo.question_" + i, params),
          "acceptedAnswer": {
            "@type": "Answer",
            "text": this.$t('webshop.seo.answer_' + i, params)
          }
        });
        this.faqData.push({
          question: this.$t("webshop.seo.question_" + i, params),
          answer: this.$t('webshop.seo.answer_' + i, params)
        });
      }
      return {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": mainEntity
      };
    }
  },
  watch: {
    FILTERS_VALUES: {
      // the callback will be called immediately after the start of the observation
      deep: true,
      handler(newVal, oldVal) {
        this.RESET_PROMOS();
        this.prepareFetchOptions() && this.loadPromos();
      }
    }
  }
}
</script>
