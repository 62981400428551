<template>
  <localized-link :to="link" v-if="$store.getters['loader/ROUTER_ACTIVE']">
    <slot></slot>
  </localized-link>
  <a :href="link" v-else>
    <slot></slot>
  </a>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "router-localized-link",
  props: {
    to: Object,
  },
  data() {
    return {
      //
    }
  },
  computed: {
    ...mapGetters('auth', [
      'LOCALE',
    ]),
    link() {
      return this.$localizePath(this.$router.resolve(this.to).href, this.LOCALE);
    }
  }
}
</script>
