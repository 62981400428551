import router from '@/router/index';

const getDefaultState = () => {
    return {
        active_filters: {
            promos_filter: false,
            types_filter: false,
            categories_filter: false,
            webshops_filter: false,
            stores_filter: false,
            brands_filter: false,
            price_filter: false,
            search_filter: false,
        },
        opened_filters: {
            promos_filter: true,
            types_filter: true,
            categories_filter: true,
            webshops_filter: true,
            stores_filter: true,
            brands_filter: true,
            price_filter: true,
            search_filter: true,
        },
        filters_values: {
            promos_filter: [],
            types_filter: [],
            morphable_types_filter: [],
            categories_filter: [],
            webshops_filter: [],
            stores_filter: [],
            brands_filter: [],
            price_filter: [],
            search_filter: '',
        }
    }
};

export default {
    namespaced: true,
    state: getDefaultState(),

    getters: {
        ACTIVE_FILTERS(state) {
            return state.active_filters;
        },
        OPENED_FILTERS(state) {
            return state.opened_filters;
        },
        FILTERS_VALUES(state) {
            return state.filters_values;
        },
    },

    actions: {
        async SET_FILTER_ACTIVE({commit}, key) {
            await commit('UPDATE_FILTER_ACTIVE', key);
        },

        async SET_FILTER_INACTIVE({commit}, key) {
            await commit('UPDATE_FILTER_INACTIVE', key);
        },

        async SET_FILTER_OPENED({commit}, filter) {
            await commit('UPDATE_FILTER_OPENED', filter);
        },

        async SET_FILTER_VALUE({commit}, filter) {
            await commit('UPDATE_FILTER_VALUE', filter);
        },

        async RESET_FILTERS({commit}) {
            commit('SET_DEFAULT_FILTERS');
        },
    },

    mutations: {
        UPDATE_FILTER_ACTIVE(state, key) {
            return _.set(state, 'active_filters.' + key, true);
        },

        UPDATE_FILTER_INACTIVE(state, key) {
            return _.set(state, 'active_filters.' + key, false);
        },

        UPDATE_FILTER_OPENED(state, filter) {
            return _.assign(state.opened_filters, filter);
        },

        UPDATE_FILTER_VALUE(state, filter) {
            let query = _.clone(router.currentRoute.value.query);
            _.forEach(filter, function (filterValue, filterKey) {
                if (_.isArray(_.get(getDefaultState(), `filters_values.${filterKey}`, false))) {
                    query[filterKey + '[]'] = _.toArray(filterValue);
                } else {
                    query[filterKey] = filterValue;
                }
            });

            router.replace({query: query});
            return _.assign(state.filters_values, filter);
        },

        SET_DEFAULT_FILTERS(state) {
            let defaultState = getDefaultState();
            _.forEach(_.keys(defaultState.filters_values), function (filterKey) {
                if (_.isArray(_.get(getDefaultState(), `filters_values.${filterKey}`, false))) {
                    let value = router.currentRoute.value.query[filterKey + '[]'];
                    if (value !== undefined) {
                        value = _.castArray(value);
                        _.set(defaultState, 'filters_values.' + filterKey, value);
                    }

                } else {
                    let value = router.currentRoute.value.query[filterKey];
                    if (value !== undefined) {
                        _.set(defaultState, 'filters_values.' + filterKey, value);
                    }
                }
            });

            return _.assign(state, defaultState);
        },
    }
}
