<template>
  <default-grid :show-right-container="false">
    <template v-slot:left-container>
      <left-bar show-store-logo
                show-store-mailer
                show-folders-stores></left-bar>
    </template>
    <template v-slot:main-container>
      <folders-store-feed :faq-data="faqData"></folders-store-feed>
    </template>
  </default-grid>
</template>

<script>
import DefaultGrid from "@/views/DefaultGrid";
import FoldersStoreFeed from "@/components/feed/FoldersStoreFeed";
import {mapGetters, mapActions} from "vuex";
import LeftBar from "@/components/left_bar/LeftBar";
import {useHead} from "@vueuse/head";
import {breadcrumbs} from "@/mixins/seo/breadcrumbs";

export default {
  name: "folders-store-view",
  components: {LeftBar, FoldersStoreFeed, DefaultGrid},
  mixins: [breadcrumbs],
  data() {
    return {
      faqData: []
    }
  },
  computed: {
    ...mapGetters('store', [
      'CURRENT_STORE',
    ]),
  },
  beforeMount() {
    this.RESET_FOLDERS();
    this.RESET_PROMOS();
  },
  created() {
    useHead({
      // Can be static or computed
      script: [
        {
          type: "application/ld+json",
          children: JSON.stringify(this.getFaqStructure())
        },
        {
          type: "application/ld+json",
          children: JSON.stringify(this.getBreadcrumbStructure())
        },
      ],
    })
  },
  methods: {
    ...mapActions('folder', [
      'RESET_FOLDERS',
    ]),
    ...mapActions('promo', [
      'RESET_PROMOS',
    ]),

    getFaqStructure() {
      let params = {
        "store": this.CURRENT_STORE.translation.title,
      };
      let mainEntity = [];
      this.faqData = [];
      for (let i = 1; i <= 4; i++) {
        mainEntity.push({
          "@type": "Question",
          "name": this.$t("store.seo_folders.question_" + i, params),
          "acceptedAnswer": {
            "@type": "Answer",
            "text": this.$t('store.seo_folders.answer_' + i, params)
          }
        });
        this.faqData.push({
          question: this.$t("store.seo_folders.question_" + i, params),
          answer: this.$t('store.seo_folders.answer_' + i, params)
        });
      }
      return {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": mainEntity
      };
    }
  }
}
</script>
