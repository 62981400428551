<template>
  <div class="hash-tags">
    <router-localized-link class="pe-2" v-for="(tag, i) in resultTags"
                 :key="i"
                 :to="{path: tag.link}">#{{ tag.text }}
    </router-localized-link>
  </div>
</template>

<script>
import RouterLocalizedLink from "@/components/common/RouterLocalizedLink";
export default {
  name: "hash-tags",
  components: {RouterLocalizedLink},
  props: ['tags'],
  data() {
    return {
      resultTags: [],
      tagsPriority: {'brand': 4, 'store': 3, 'product': 2, 'webshop': 1}
    }
  },
  created() {
    this.prepareTags(this.tags);
  },
  methods: {
    prepareTags(tags) {
      let thisElem = this;
      let resultTags = [];
      _.forEach(tags, (tag) => {
        let sameTagInResult = _.find(tags, (o) => {
          return o.text === tag.text && _.get(thisElem.tagsPriority, o.entityType) > _.get(thisElem.tagsPriority, tag.entityType)
        });
        if (_.isEmpty(sameTagInResult)) {
          resultTags.push(tag);
        }
      });
      this.resultTags = resultTags;
    }
  },
  watch: {
    tags(value) {
      this.prepareTags(value);
    }
  }
}
</script>
