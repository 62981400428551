<template>
  <el-dialog
      v-model="show"
      :show-close="true"
      :close-on-click-modal="true"
      :close-on-press-escape="false"
      :lock-scroll="false"
      width="100vw"
      :class="customClasses"
      center
      @close="closeDialog">
    <div v-if="!['registered', 'verified'].includes(step)"
         class="el-dialog__body__nav-wrapper">
      <el-tag
          :effect="'login' === step ? 'plain' : 'dark'"
          class="cursor-pointer font-size-14"
          @click="step = 'register'">
        <span v-t="'auth.register_for_free'"/>
      </el-tag>
      <el-tag
          :effect="'login' === step ? 'dark' : 'plain'"
          class="cursor-pointer font-size-14"
          @click="step = 'login'">
        <span v-t="'auth.login'"/>
      </el-tag>
    </div>

    <template v-if="'default' === step">
      <default-step
        @go-login="step = 'login'"
        @submit="step = 'register'"
      />
    </template>

    <template v-if="'login' === step">
      <login-step @logged-in="closeDialog" @password-reset="closeDialog" :try-login-data="tryLoginData"></login-step>
    </template>

    <template v-if="'register' === step">
      <register-step @registered="step = 'registered'" :try-register-data="tryRegisterData"></register-step>
    </template>

    <template v-if="'registered' === step">
      <registered-step @submit="closeDialog"></registered-step>
    </template>

    <template v-if="'verified' === step">
      <verified-step @submit="step = 'login'"></verified-step>
    </template>

    <template v-slot:header>
      <div>
        <i class="icon-arrow_back float-start cursor-pointer" @click="backDialog"/>
        <span v-t="'auth.only_logged_title'" v-if="'default' === step"/>
        <span v-t="'auth.verified_step.header'" v-else-if="'verified' === step"/>
        <span v-t="'auth.now_go_hunting'" v-else/>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import DefaultStep from "@/components/header/auth/Steps/DefaultStep.vue";
import LoginStep from "@/components/header/auth/Steps/LoginStep.vue";
import RegisteredStep from "@/components/header/auth/Steps/RegisteredStep.vue";
import RegisterStep from "@/components/header/auth/Steps/RegisterStep.vue";
import VerifiedStep from "@/components/header/auth/Steps/VerifiedStep";

export default {
  name: "login-dialog",
  components: {VerifiedStep, RegisterStep, LoginStep, RegisteredStep, DefaultStep},
  props: {
    showDialog: {
      type: Boolean,
    },
    startingTab: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      step: 'default', // default,register(1-3),registered,login,info
      show: this.showDialog,
      tryLoginData: null,
      tryRegisterData: null,
      stepTabs: {
        'register': 'Registrar Gratis',
        'login': 'Iniciar Sesión',
      }
    }
  },
  computed: {
    customClasses() {
      const dialogClass = 'default' !== this.step ? 'login-dialog' : 'default-dialog'

      return dialogClass + ' danger-header-dialog'
    }
  },
  mounted() {
    this.emitter.on("try-login", (data) => {
      this.step = 'login';
      this.tryLoginData = data;
    });

    this.emitter.on("try-register", (data) => {
      this.step = 'register';
      this.tryRegisterData = data;
    });
  },
  methods: {
    backDialog() {
      this.tryLoginData = null;
      this.tryRegisterData = null;
      if ('default' === this.step) {
        this.closeDialog();
        return;
      }
      this.step = 'default';
    },
    closeDialog() {
      this.tryLoginData = null;
      this.tryRegisterData = null;
      this.show = false;
      this.step = 'default';
      this.$emit('update:show-dialog', false);
    }
  },
  watch: {
    showDialog(newShowDialog) {
      this.step = this.startingTab;
      this.show = newShowDialog;
    }
  },
}
</script>
