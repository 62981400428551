<template>
  <div class="post">
    <div class="post-header" ref="post_header">
      <el-avatar :src="post.user.avatar_url" shape="circle"
                 class="post-author-avatar"></el-avatar>

      <div class="post-author">
        <div class="post-author-name" v-text="post.user.full_name"></div>
        <div class="post-time" v-text="post.time_ago"></div>
      </div>

      <div class="post-actions">
        <div class="post-favourite">
          <i class="cursor-pointer"
             :class="{'active icon-heart-2':post.is_favourite, 'icon-heart':!post.is_favourite}"
             @click="processFavourite"></i>
        </div>
        <div class="post-subscribe">
          <i class="cursor-pointer"
             :class="{'active icon-bell-3':isSubscribed, 'icon-bell-2':!isSubscribed}"
             @click="processSubscription"></i>
        </div>
      </div>
    </div>

    <div class="post-content">

      <div v-if="isShortQuestion" class="short-question font-weight-medium text-center"
           :style="{background: post.type.color}">
        <dynamic-content :content="post.content"></dynamic-content>
      </div>

      <div class="content-container" ref="contentContainer" :style="`max-height:${contentContainerHeight}`" v-else>
        <div ref="content">
          <dynamic-content :content="post.content"></dynamic-content>
        </div>
      </div>
      <a href="javascript:void(0)" v-if="showMoreActive" @click="showFullContent">
        <span v-t="'filters.show_more'"></span>
      </a>

      <hash-tags :tags="tags"></hash-tags>

      <div class="discount-code-wrapper mb-3 mx-3 mx-md-5" v-if="post.discount_code">
        <div class="d-flex justify-content-end align-items-center">
          <el-button
              round
              :class="{'w-100':xsOnly,'w-50':!xsOnly}"
              type="primary"
              @click="copyDiscountCode(this.post)">
            <span v-t="'post.discount_btn'"></span>
          </el-button>
        </div>
      </div>

      <div class="carousel-container" :style="carouselContainerStyle">
        <div class="post-carousel" ref="post_carousel">
          <el-carousel :height="carouselHeight + 'px'"
                       :arrow="arrow">
            <el-carousel-item v-for="(image, i) in post.images" :data="image" :key="i">
              <div class="post-carousel-image">
                <img :src="image.src" alt="" :style="`max-width:` + carouselWidth + `px`">
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>

        <hit :container-width="carouselWidth"
                       :top="targetTop"
                       :hits-count="hitsCount"
                       :is-like="post.is_hit"
                       :is-dislike="post.is_dislike"
                       @hit="processHit"></hit>
      </div>
    </div>
    <div class="post-footer">
      <div class="comments-wrapper mt-1">
        <comments-header :hidden-total="hiddenComments" :total="commentsCount" @show-more="showMoreComments"></comments-header>

        <comments-loading :loading="commentsLoading"></comments-loading>

        <comments :comments-data="visibleCommentsData" @hit="hitCommentProcess"></comments>

        <comments-loading :loading="commentsPushing"></comments-loading>

        <comment-form
            @submit="pushComment"
        ></comment-form>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import Hit from '@/components/feed/Hit';
import CommentForm from '@/components/feed/comment/CommentForm';
import Comments from "@/components/feed/comment/Comments";
import {comments} from "@/mixins/comments";
import CommentsHeader from "@/components/feed/comment/CommentsHeader";
import CommentsLoading from "@/components/feed/comment/CommentsLoading";
import {slug} from "@/mixins/slug";
import {needAuth} from "@/mixins/auth/needAuth";
import HashTags from "@/components/feed/post/HashTags";
import DynamicContent from "@/components/feed/post/DynamicContent";
import {copyDiscountCode} from "@/mixins/post/copyDiscountCode";
import {randomHits} from "@/mixins/post/randomHits";

export default {
  name: "post",
  components: {DynamicContent, HashTags, CommentsLoading, CommentsHeader, Comments, Hit, CommentForm},
  props: {
    post: Object,
    vuexModule: {
      type: String,
      default: 'promo',
    },
  },
  mixins: [comments, slug, needAuth, copyDiscountCode, randomHits],
  data() {
    return {
      carouselHeight: 0,
      carouselWidth: 0,
      targetTop: 0,
      contentContainerHeight: '',
      showMoreActive: false,
    }
  },
  computed: {
    arrow() {
      if (this.post.images.length === 1) {
        return 'never';
      }
      return 'always';
    },
    carouselContainerStyle() {
      return {
        'height': this.carouselHeight + (this.xsOnly ? 100 : 90) + 'px'
      }
    },
    tags() {
      let result = [];
      if (_.get(this.post, 'type.id', false)) {
        result.push({
          text: this.convertToSlug(_.get(this.post, 'type.translation.alias')),
          link: this.$router.resolve({name: 'home'}).href,
          entityType: 'type'
        });
      }

      if (_.get(this.post, 'webshops[0].id', false)) {
        result.push({
          text: this.convertToSlug(_.get(this.post, 'webshops[0].translation.title')),
          link: this.$router.resolve({
            name: 'webshop.show',
            params: {webshopSlug: _.get(this.post, 'webshops[0].translation.route')}
          }).href,
          entityType: 'webshop'
        });
      }

      if (_.get(this.post, 'stores[0].id', false)) {
        result.push({
          text: this.convertToSlug(_.get(this.post, 'stores[0].translation.title')),
          link: this.$router.resolve({
            name: 'store.show',
            params: {storeSlug: _.get(this.post, 'stores[0].translation.route')}
          }).href,
          entityType: 'store'
        });
      }

      if (_.get(this.post, 'brands[0].id', false)) {
        result.push({
          text: this.convertToSlug(_.get(this.post, 'brands[0].translation.title')),
          link: this.$router.resolve({
            name: 'brand.show',
            params: {brandSlug: _.get(this.post, 'brands[0].translation.route')}
          }).href,
          entityType: 'brand'
        });
      }

      if (_.get(this.post, 'products[0].id', false)) {
        result.push({
          text: this.convertToSlug(_.get(this.post, 'products[0].translation.title')),
          link: this.$router.resolve({
            name: 'product.show',
            params: {productSlug: _.get(this.post, 'products[0].translation.route')}
          }).href,
          entityType: 'product'
        });
      }

      return result;
    },

    isSubscribed() {
      if (this.$store.getters['auth/GUEST']) {
        return false;
      }
      return this.post.subscriptions.some(item => item.user_id === this.$store.getters['auth/USER'].id);
    },

    isShortQuestion() {
      return this.post.type.kind === 3 && !this.post.images.length && this.post.content_length <= 115;
    },
  },
  mounted() {
    let thisElem = this;
    new ResizeObserver(function () {
      thisElem.calculateHeight();
    }).observe(this.$refs.post_header);

    this.commentsCount = this.post.comments_count;
    this.comments = this.post.comments;
    this.visibleComments = _.min([3, this.post.comments.length]);

    if (this.$refs.content && this.$refs.contentContainer) {
      new ResizeObserver(function () {
        if (thisElem.$refs.content && thisElem.$refs.contentContainer) {
          thisElem.showMoreActive = (thisElem.$refs.content.clientHeight - thisElem.$refs.contentContainer.clientHeight) > 5;
        }
      }).observe(this.$refs.content);
    }
  },
  methods: {
    calculateHeight() {
      let thisElem = this;
      if (!thisElem.$refs.post_header) {
        return 0;
      }
      thisElem.carouselWidth = thisElem.$refs.post_header.clientWidth - 20;
      thisElem.carouselHeight = 0;

      if (_.isEmpty(thisElem.post.images)) {
        return 0;
      }

      _.forEach(thisElem.post.images, function (image) {
        let currentImage = new Image();
        currentImage.src = image.src;
        currentImage.onload = function () {
          let currentHeight = 0;
          if (thisElem.carouselWidth < currentImage.width) {
            currentHeight = thisElem.carouselWidth / currentImage.width * currentImage.height;
          } else {
            currentHeight = currentImage.height;
          }
          if (thisElem.carouselHeight < currentHeight) {
            thisElem.carouselHeight = currentHeight;
            thisElem.targetTop = thisElem.carouselHeight + thisElem.$refs.post_carousel.offsetTop - (thisElem.xsOnly ? 20 : 30);
          }
        };
      });
    },
    processHit(value) {
      this.webshopLoginDialog();
      if (this.triggerLoginDialog()) {
        return;
      }
      let data = {
        morphable_type: 'Post',
        morphable_id: this.post.id,
      };
      if (value) {
        this.$store.dispatch(this.vuexModule + '/SET_HIT', data);
      } else {
        this.$store.dispatch(this.vuexModule + '/SET_DISLIKE', data);
      }
    },
    processFavourite() {
      this.webshopLoginDialog();
      if (this.triggerLoginDialog()) {
        return;
      }
      let data = {
        morphable_type: 'Post',
        morphable_id: this.post.id,
      };
      if (this.post.is_favourite) {
        this.$store.dispatch(this.vuexModule + '/REMOVE_FAVORITE', data);
      } else {
        this.$store.dispatch(this.vuexModule + '/SET_FAVORITE', data);
      }
    },
    processSubscription() {
      this.webshopLoginDialog();
      if (this.triggerLoginDialog()) {
        return;
      }
      let data = {
        morphable_type: 'Post',
        morphable_id: this.post.id,
      };
      if (this.isSubscribed) {
        this.$store.dispatch(this.vuexModule + '/REMOVE_SUBSCRIPTION', data);
      } else {
        this.$store.dispatch(this.vuexModule + '/SET_SUBSCRIPTION', data);
      }
    },
    showMoreComments() {
      this.showMoreCommentsProcess('Post', this.post.id);
    },
    pushComment(form) {
      this.pushCommentProcess('Post', this.post.id, form);
    },
    showFullContent() {
      this.showMoreActive = false;
      this.contentContainerHeight = 'max-content';
    }
  }
}
</script>
