
export default {
    namespaced: true,
    state: {
        user: {},
        locale: cookies.get('locale') || process.env.VUE_APP_FALLBACK_LOCALE,
        notifications: [],
        token: cookies.get('auth-token') || '',
        include: 'account,socialAccounts',
        notifications_refresh_delay: 180000, // 3min
        i18n: null,
    },

    getters: {
        AUTH_TOKEN(state) {
            return state.token;
        },
        USER(state) {
            return state.user;
        },
        GUEST(state) {
            return _.isEmpty(state.user);
        },
        LOCALE(state) {
            return _.get(state, 'locale');
        },
        I18N(state) {
            return state.i18n;
        },
        NOTIFICATIONS(state) {
            return state.notifications;
        },
        NOTIFICATIONS_REFRESH_DELAY(state) {
            return state.notifications_refresh_delay;
        }
    },

    actions: {
        async FETCH_USER({state, commit}) {
            return await axios.get('/me', {params: {include: state.include}}).then((response) => {
                commit('SET_USER', response.data.data);
                commit('SET_LOCALE', response.data.data.locale);
                commit('SET_NOTIFICATIONS', response.data.notifications);
            });
        },

        async LOGOUT({dispatch, commit}) {
            await cookies.set('auth-token', '');
            await commit('SET_TOKEN', null);
            await commit('SET_USER', {});
            window.location.reload();
        },

        async UPDATE_TOKEN({commit}, token = '') {
            await cookies.set('auth-token', token);
            await commit('SET_TOKEN', token);
            window.location.reload();
        },

        async UPDATE_USER({state, commit}, fields) {
            return axios.put('/me', _.merge(fields, {include: state.include})).then((response) => {
                commit('SET_USER', response.data.data);
            });
        },

        async UPDATE_SETTINGS({state, commit}, fields) {
            return axios.put('/me/settings', _.merge(fields, {include: state.include})).then((response) => {
                commit('SET_USER', response.data.data);
            });
        },

        async UPDATE_LOCALE({state, commit, dispatch}, newLocale) {
            if (!_.isEmpty(state.user)) {
                await dispatch('UPDATE_USER', {locale: newLocale});
            }
            await commit('SET_LOCALE', newLocale);
        },

        async UPDATE_I18N({state, commit, dispatch}, value) {
            await commit('SET_I18N', value);
        },

        async FETCH_NOTIFICATIONS({state, commit, dispatch}) {
            setTimeout(() => {
                dispatch('FETCH_NOTIFICATIONS');
            }, state.notifications_refresh_delay);
            return await axios.get('/notifications').then((response) => {
                commit('SET_NOTIFICATIONS', response.data.data);
            });
        },

        async READ_NOTIFICATION({state, commit}, form) {
            return await axios.post('/notifications', form).then((response) => {
                commit('SET_NOTIFICATIONS', response.data.data);
            });
        },
    },

    mutations: {
        SET_USER(state, user) {
            return (state.user = user);
        },

        SET_TOKEN(state, token) {
            return (state.token = token);
        },

        SET_LOCALE(state, locale) {
            cookies.set('locale', locale, '1y')
            if (localStorage.getItem('VueAppLanguage') !== locale) {
                localStorage.setItem('VueAppLanguage', locale);
            }
            state.locale = locale;
            return (state.i18n.locale = locale);
        },

        SET_I18N(state, value) {
            return (state.i18n = value);
        },

        SET_NOTIFICATIONS(state, notifications) {
            return (state.notifications = notifications);
        }
    }
}
