import store from '@/store';

export default async (to) => {
    await store.dispatch('news_category/FETCH_CURRENT_CATEGORY', to.params.categorySlug);
    await store.dispatch('news_category/FETCH_NEWS_CATEGORIES');

    let currentCategorySlug = store.getters['news_category/CURRENT_CATEGORY'].translation.slug;

    if (undefined === currentCategorySlug) {
        return {name: 'news'};
    }
};
