<template>
  <default-grid>
    <template v-slot:left-container>
      <left-bar show-user-menu></left-bar>
    </template>
    <template v-slot:main-container>
      <div class="feed-container">
        <prize-request-form></prize-request-form>
      </div>
    </template>
    <template v-slot:right-container>
      <right-bar></right-bar>
    </template>
  </default-grid>
</template>

<script>
import DefaultGrid from '@/views/DefaultGrid';
import RightBar from '@/components/right_bar/RightBar';
import LeftBar from '@/components/left_bar/LeftBar';
import PrizeRequestForm from "@/components/feed/bonuses/PrizeRequestForm";

export default {
  name: "PrizeRequestView",
  components: {
    PrizeRequestForm,
    DefaultGrid,
    LeftBar,
    RightBar
  }
}
</script>
