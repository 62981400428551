<template>
  <default-grid>
    <template v-slot:left-container>
      <left-bar show-user-menu></left-bar>
    </template>
    <template v-slot:main-container>
      <div v-html="pageContent"></div>

      <div class="my-3">
        <h3 class="text-center mb-2">
          <i class="el-icon-d-arrow-right font-weight-bold" style="transform: rotate(90deg);"></i>
          <span v-t="'prizes.referral_page.link_label'" class="px-2"></span>
          <i class="el-icon-d-arrow-right font-weight-bold" style="transform: rotate(90deg);"></i>
        </h3>

        <el-row type="flex" justify="center">
          <el-col :span="24">
            <div class="p-4 text-center background-light-yellow" @click="doCopyReferralLink">
              <el-row type="flex" justify="center">
                <el-col :span="24" :xl="16">
                  <span class="referral-link text-center">{{ referralLink }}</span> &nbsp;
                </el-col>
                <el-col :span="24" :xl="8" class="mt-2 mt-xxl-0">
                   <span class="cursor-pointer text-center">
                        <span v-t="'prizes.referral_page.click_to_copy'"></span>
                        <i class="ps-2 el-icon-copy-document font-weight-bold font-size-18" v-if="!copied"></i>
                        <i class="ps-2 el-icon-check font-weight-bold font-size-18" v-else></i>
                    </span>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </div>

    </template>
  </default-grid>
</template>

<script>
import {mapGetters} from "vuex";
import DefaultGrid from "@/views/DefaultGrid";
import LeftBar from "@/components/left_bar/LeftBar";

export default {
  name: "ReferralView",
  components: {LeftBar, DefaultGrid},
  data() {
    return {
      copied: false
    }
  },
  computed: {
    ...mapGetters('page', [
      'PAGES_LOADED',
      'PAGES'
    ]),
    ...mapGetters('auth', [
      'USER',
    ]),

    pageContent() {
      let thisElem = this;
      let pages = _.filter(this.PAGES, function (need) {
        return _.get(need, 'locale.slug') === thisElem.$i18n.locale && _.get(need, 'slug') === 'referral-page';
      });

      return _.get(pages, '[0]content', '');
    },

    referralLink() {
      let link = this.$router.resolve({name: 'home', query: {doemee: this.USER.id}});
      return new URL(link.href, window.location.origin).href;
    }
  },
  methods: {
    doCopyReferralLink() {
      this.$copyText(this.referralLink, undefined, (error, event) => {
        this.copied = true;
        setTimeout(() => {
          this.copied = false
        }, 2000);
      });
    }
  }
}
</script>

<style scoped>
.background-light-yellow {
  background-color: #fff2cc;
}

.referral-link {
  cursor: pointer;
  text-decoration-line: underline;
}
</style>
