<template>
  <default-grid>
    <template v-slot:left-container>
      <left-bar show-user-menu></left-bar>
    </template>
    <template v-slot:main-container>
      <div class="feed-container">
        <bonus-prizes></bonus-prizes>
      </div>
    </template>
    <template v-slot:right-container>
      <right-bar></right-bar>
    </template>
  </default-grid>
</template>

<script>
import DefaultGrid from '@/views/DefaultGrid'
import RightBar from '@/components/right_bar/RightBar'
import LeftBar from '@/components/left_bar/LeftBar'
import BonusPrizes from '@/components/feed/bonuses/BonusPrizes'

export default {
  name: "PrizePoolView",
  components: {
    DefaultGrid,
    LeftBar,
    RightBar,
    BonusPrizes
  }
}
</script>
