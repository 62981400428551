import store from '@/store';

export default async (to, from, next) => {
    if (store.getters['auth/GUEST'] && store.getters['auth/AUTH_TOKEN']) {
        try {
            await store.dispatch('auth/FETCH_USER');
            setTimeout(() => {
                store.dispatch('auth/FETCH_NOTIFICATIONS');
            }, store.getters['auth/NOTIFICATIONS_REFRESH_DELAY']);
        } catch (e) {
            await store.dispatch('auth/LOGOUT');
        }
    }

    next();
}
