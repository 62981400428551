<template>
  <div class="my-3">
    <div class="text-center text-danger font-size-120">
      <i class="icon-check"></i>
    </div>

    <div class="text-danger text-center font-size-30" v-t="'auth.verified_step.label'"></div>

    <div class="p-2 my-3" v-html="$t('auth.verified_step.text')"></div>

    <el-button type="primary"
               class="w-100"
               @click="$emit('submit')">
      <span v-t="'auth.login'"></span>
    </el-button>
  </div>
</template>

<script>
export default {
  name: "verified-step"
}
</script>
