<template>
  <div class="my-2 overflow-hidden">
    <div class="bg-white p-2" style="border-radius: 20px;">
      <facebook-avatar v-if="comment.facebook_comment_id"
                       :unique-id="comment.facebook_comment_id"></facebook-avatar>
      <el-avatar v-else :src="comment.user.avatar_url" shape="circle"
                 class="comment-author-avatar"></el-avatar>

      <div class="comment-author">
        <div class="comment-author-name" v-text="comment.user.full_name"></div>
        <div class="comment-time" v-text="comment.time_ago"></div>
      </div>

      <div class="float-end d-flex align-items-center justify-content-center">
        <span class="mx-2 text-secondary" v-if="comment.hits.count > 0">{{comment.hits.count}}</span>

        <i class="icon-like-2 font-size-40 text-danger cursor-pointer" v-if="comment.is_hit" @click="tryHit"></i>
        <i class="icon-like font-size-40 text-danger cursor-pointer" v-else @click="tryHit"></i>
      </div>

      <div class="comment-body clear-both ms-2 pt-2">
          <dynamic-content :content="comment.content"></dynamic-content>
      </div>

      <div class="p-3" v-if="comment.image_url">
        <el-image
            style="width: 150px; height: 150px"
            :src="comment.image_url"
            :preview-src-list="[comment.image_url]"
            :initial-index="0"
            fit="cover"/>
      </div>
    </div>

    <div class="mt-2 ms-2" v-if="showReply">
      <div class="d-flex align-items-center justify-content-between font-size-13">
        <span class="cursor-pointer text-secondary text-decoration-underline danger-on-hover" @click="showChildren"
              v-if="childrenCount > 0">
          <i class="font-size-16"
             :class="{'el-icon-bottom-right':!isVisibleChildren,'el-icon-top-left':isVisibleChildren}"></i>
          {{ $t('comment.show_replies_btn', {total: childrenCount}) }}
        </span>

        <span v-else>&nbsp;</span>

        <span class="cursor-pointer font-weight-bold text-decoration-underline danger-on-hover" @click="showChildren">
          {{ $t('comment.reply_btn') }}
        </span>
      </div>

      <div class="mt-2 ms-4" v-if="isVisibleChildren">
        <comment
            v-for="entry in children"
            :comment="entry"
            :key="entry.id"
            :show-reply="false"
            @hit="hitComment(entry.id)">
        </comment>
        <comments-loading :loading="childrenLoading"></comments-loading>
        <comment-form
            @submit="pushComment"
        ></comment-form>
      </div>
    </div>
  </div>
</template>

<script>
import CommentForm from "@/components/feed/comment/CommentForm";
import CommentsLoading from "@/components/feed/comment/CommentsLoading";
import {needAuth} from "@/mixins/auth/needAuth";
import FacebookAvatar from "@/components/feed/comment/FacebookAvatar";
import DynamicContent from "@/components/feed/post/DynamicContent";

export default {
  name: "comment",
  components: {DynamicContent, FacebookAvatar, CommentsLoading, CommentForm},
  mixins: [needAuth],
  emits: ["hit"],
  props: {
    comment: {
      type: Object,
    },
    showReply: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      childrenCount: 0,
      children: [],

      childrenLoading: false,
      isVisibleChildren: false,
      isLiked: false
    }
  },
  mounted() {
    this.childrenCount = this.comment.children_count;
  },
  methods: {
    showChildren() {
      if (this.triggerLoginDialog()) {
        return;
      }
      this.isVisibleChildren = !this.isVisibleChildren;
      if (!this.isVisibleChildren) {
        return;
      }
      this.loadChildren()
    },
    loadChildren() {
      this.childrenLoading = true;
      axios.get('/comments/' + this.comment.id + '/children').then((response) => {
        this.childrenLoading = false;
        this.children = response.data.data;
        this.childrenCount = this.children.length;
      });
    },
    pushComment(form) {
      this.childrenLoading = true;
      return axios.post(`/comments/${this.comment.id}`, form).then((response) => {
        this.childrenLoading = false;
        this.children = response.data.data;
        this.childrenCount = this.children.length;
      });
    },
    tryHit() {
      if (this.triggerLoginDialog()) {
        return;
      }
      this.$emit('hit');
    },
    hitComment(commentId) {
      return axios.post(`/comments/${commentId}/hit`).then((response) => {
        this.children = response.data.data;
        this.childrenCount = this.children.length;
      });
    }
  }
}
</script>
