<template>
  <div class="online-promo">
    <div class="online-promo-header" ref="online_promo_header">
      <el-avatar :src="onlinePromo.webshop.image_url" shape="circle"
                 class="online-promo-author-avatar"></el-avatar>

      <div class="online-promo-author">
        <div class="online-promo-author-name">
          {{ onlinePromo.webshop.translation.title }}
        </div>
        <div class="online-promo-time" :class="{'text-danger': !onlinePromo.is_valid}">
          {{ onlinePromo.valid_text }}
        </div>
      </div>

      <div class="online-promo-actions">
        <div class="online-promo-prices" v-if="!xsOnly">
          <div class="old-price" v-if="onlinePromo.old_price">
            {{ onlinePromo.old_price }} €
          </div>
          <div class="new-price">
            {{ onlinePromo.new_price }} €
          </div>
        </div>

        <div class="online-promo-subscribe">
          <i class="icon-bell-2"></i>
        </div>
        <div class="online-promo-favourite">
          <i class="cursor-pointer"
             :class="{'active icon-heart-2':onlinePromo.is_favourite, 'icon-heart':!onlinePromo.is_favourite}"
             @click="processFavourite"></i>
        </div>
      </div>
    </div>

    <div class="online-promo-prices w-100 d-flex align-items-end justify-content-center" v-if="xsOnly">
      <div class="old-price" v-if="onlinePromo.old_price">
        {{ onlinePromo.old_price }} €
      </div>
      <div class="new-price">
        {{ onlinePromo.new_price }} €
      </div>
    </div>

    <div class="online-promo-content">
      {{ onlinePromo.title }}

      <hash-tags :tags="tags"></hash-tags>

      <div class="carousel-container" :style="carouselContainerStyle">
        <div class="online-promo-carousel" ref="online_promo_carousel">
          <el-carousel :height="carouselHeight + 'px'"
                       arrow="never">
            <el-carousel-item>
              <div class="online-promo-carousel-image">
                <img :src="onlinePromo.image_url" alt="" :style="`max-width:` + carouselWidth + `px`" @click="openPromoUrl">
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>

        <hit :container-width="carouselWidth"
                       :top="targetTop"
                       :hits-count="onlinePromo.hits.count"
                       :is-like="onlinePromo.is_hit"
                       :is-dislike="onlinePromo.is_dislike"
                       @hit="processHit"></hit>
      </div>

      <div class="folder-link my-3">
        <el-button type="primary"
                   :class="{'w-75':xsOnly,'w-50':!xsOnly}"
                   @click="openPromoUrl">
          <div>
            <span>
              <span class="font-size-16 font-weight-bold" v-t="'promo.go_to_webshop'"></span>
              <i class="icon-arrow-3"></i>
            </span>
          </div>
        </el-button>
      </div>
    </div>
    <div class="online-promo-footer">
      <div class="comments-wrapper mt-1">
        <comments-header :hidden-total="hiddenComments" :total="commentsCount" @show-more="showMoreComments"></comments-header>

        <comments-loading :loading="commentsLoading"></comments-loading>

        <comments :comments-data="visibleCommentsData" @hit="hitCommentProcess"></comments>

        <comments-loading :loading="commentsPushing"></comments-loading>

        <comment-form
            @submit="pushComment"
        ></comment-form>
      </div>
    </div>
  </div>
</template>

<script>
import Hit from '@/components/feed/Hit';
import CommentForm from '@/components/feed/comment/CommentForm';
import Comments from '@/components/feed/comment/Comments';
import {comments} from "@/mixins/comments";
import CommentsHeader from "@/components/feed/comment/CommentsHeader";
import CommentsLoading from "@/components/feed/comment/CommentsLoading";
import {slug} from "@/mixins/slug";
import {needAuth} from "@/mixins/auth/needAuth";
import HashTags from "@/components/feed/post/HashTags";

export default {
  name: "online-promo",
  components: {HashTags, CommentsLoading, CommentsHeader, Hit, Comments, CommentForm},
  props: {
    onlinePromo: Object,
    vuexModule: {
      type: String,
      default: 'promo',
    },
  },
  mixins: [comments, slug, needAuth],
  data() {
    return {
      carouselHeight: 0,
      carouselWidth: 0,
      targetTop: 0,
    }
  },
  computed: {
    carouselContainerStyle() {
      return {
        'height': this.carouselHeight + (this.xsOnly ? 100 : 90) + 'px'
      }
    },

    tags() {
      let result = [];
      result.push({
        text: this.convertToSlug(this.$t('post.promos_filter.online_promos')),
        link: this.$router.resolve({name: 'promos'}).href,
        entityType: 'type'
      });

      if (_.get(this.onlinePromo, 'webshop.id', false)) {
        result.push({
          text: this.convertToSlug(_.get(this.onlinePromo, 'webshop.translation.title')),
          link: this.$router.resolve({
            name: 'webshop.show',
            params: {webshopSlug: _.get(this.onlinePromo, 'webshop.translation.route')}
          }).href,
          entityType: 'webshop'
        });
      }

      if (_.get(this.onlinePromo, 'brand.id', false)) {
        result.push({
          text: this.convertToSlug(_.get(this.onlinePromo, 'brand.translation.title')),
          link: this.$router.resolve({
            name: 'brand.show',
            params: {brandSlug: _.get(this.onlinePromo, 'brand.translation.route')}
          }).href,
          entityType: 'brand'
        });
      }

      return result;
    }
  },
  mounted() {
    let thisElem = this;
    new ResizeObserver(function () {
      thisElem.calculateHeight();
    }).observe(this.$refs.online_promo_header);

    this.commentsCount = this.onlinePromo.comments_count;
    this.comments = this.onlinePromo.comments;
    this.visibleComments = _.min([3, this.onlinePromo.comments.length]);
  },
  methods: {
    calculateHeight() {
      let thisElem = this;

      if (!thisElem.$refs.online_promo_header) {
        return 0;
      }
      thisElem.carouselWidth = thisElem.$refs.online_promo_header.clientWidth - 20;
      thisElem.carouselHeight = 0;

      let currentImage = new Image();
      currentImage.src = thisElem.onlinePromo.image_url;
      currentImage.onload = function () {
        let currentHeight = 0;
        if (thisElem.carouselWidth < currentImage.width) {
          currentHeight = thisElem.carouselWidth / currentImage.width * currentImage.height;
        } else {
          currentHeight = currentImage.height;
        }
        if (thisElem.carouselHeight < currentHeight) {
          thisElem.carouselHeight = currentHeight;
          thisElem.targetTop = thisElem.carouselHeight + thisElem.$refs.online_promo_carousel.offsetTop - (thisElem.xsOnly ? 20 : 30);
        }
      };
    },
    processHit(value) {
      if (this.triggerLoginDialog()) {
        return;
      }
      let data = {
        morphable_type: 'OnlinePromo',
        morphable_id: this.onlinePromo.id,
      };
      if (value) {
        this.$store.dispatch(this.vuexModule + '/SET_HIT', data);
      } else {
        this.$store.dispatch(this.vuexModule + '/SET_DISLIKE', data);
      }
    },
    processFavourite() {
      if (this.triggerLoginDialog()) {
        return;
      }
      let data = {
        morphable_type: 'OnlinePromo',
        morphable_id: this.onlinePromo.id,
      };
      if (this.onlinePromo.is_favourite) {
        this.$store.dispatch(this.vuexModule + '/REMOVE_FAVORITE', data);
      } else {
        this.$store.dispatch(this.vuexModule + '/SET_FAVORITE', data);
      }
    },
    showMoreComments() {
      this.showMoreCommentsProcess('OnlinePromo', this.onlinePromo.id);
    },
    pushComment(form) {
      this.pushCommentProcess('OnlinePromo', this.onlinePromo.id, form);
    },
    openPromoUrl(){
      window.open(this.onlinePromo.url, '_blank');
    }
  }
}
</script>
