<template>
  <el-col :lg="layout.col_lg" :xl="layout.col_xl">
    <div class="position-relative article-wrap">
      <a v-if="article.is_external" class="article-preview" v-bind:href="article.translation.external_link"
         target="_blank" rel="noopener noreferrer">
        <div :class="[layout.container_class, 'article-image-container']">
          <img v-if="article.translation.cover_image" :src="article.translation.cover_image"
               :class="[layout.img_class, 'article-image']">
          <img v-else src="@/assets/images/promo-blog.png"
               class="article-image">
        </div>
        <div :class="[layout.title_class, 'article-title']">{{ article.translation.title }}</div>
      </a>
      <router-localized-link class="article-preview" v-else
                             :to="{name: 'news.article', params: {categorySlug: String(article.category.translation.slug), articleSlug: String(article.translation.slug)}}">
        <div :class="[layout.container_class, 'article-image-container']">
          <img v-if="article.translation.cover_image" :src="article.translation.cover_image"
               :class="[layout.img_class, 'article-image']">
          <img v-else src="@/assets/images/promo-blog.png"
               class="article-image">
        </div>
        <div :class="[layout.title_class, 'article-title']">{{ article.translation.title }}</div>
      </router-localized-link>
      <div class="position-absolute article-category-image-container" v-if="showCategory">
        <img v-if="article.category.image_url"
             :src="article.category.image_url">
      </div>
    </div>
  </el-col>
</template>

<script>

import RouterLocalizedLink from "@/components/common/RouterLocalizedLink";

export default {
  name: "news-preview",
  components: {RouterLocalizedLink},
  props: {
    article: Object,
    layout: Object,
    showCategory: {
      type: Boolean,
      default: false
    }
  },
}
</script>
