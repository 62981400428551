<template>
  <div class="folders-carousel-container w-100" :class="childClass">
    <div class="folders-carousel-header" ref="top_folders_header">
      <h3 class="font-weight-normal font-size-20 m-0" v-text="title"></h3>

      <div class="nav-buttons float-end text-danger">
        <i v-if="currentScrollPx > 0"
           class="icon-arrow-4 float-start cursor-pointer"
           style="transform: scaleX(-1);"
           @click="scrollPrev"></i>

        <i v-if="currentScrollPx < maxScrollPx + 230"
           class="icon-arrow-4 float-end cursor-pointer"
           @click="scrollNext"></i>
      </div>

    </div>

    <el-scrollbar class="w-100" :native="false" ref="scrollbar_ref" :min-size="40" @scroll="scrolling" v-loading="isLoading">
      <div class="scrollbar-flex-content">
        <div class="scrollbar-item"
             v-for="(folder, i) in folders"
             :key="i">
          <div :style="previewItemContainerStyle">
            <folder-preview :folder="folder" :vuex-module="vuexModule"></folder-preview>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import FolderPreview from "@/components/feed/folder/FolderPreview";

export default {
  name: "folders-carousel",
  emits: ["load"],
  components: {FolderPreview},
  props: {
    title: String,
    folders: Array,
    childClass: String,
    vuexModule: {
      type: String,
      default: 'folder',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      folderWidth: 230,
      containerWidth: 0,
      currentScroll: 0,
      currentScrollPx: 0,
      loadingEmitted: false,
    }
  },
  computed: {
    maxScrollPx() {
      return this.folders.length * this.folderWidth - this.containerWidth;
    },
    previewItemContainerStyle() {
      return {
        'width': this.folderWidth + 'px'
      }
    }
  },
  mounted() {
    let thisElem = this;
    new ResizeObserver(function () {
      thisElem.calculateContainerWidth();
    }).observe(this.$refs.top_folders_header);
  },
  methods: {
    scrolling(value) {
      this.currentScrollPx = value.scrollLeft;
    },
    calculateContainerWidth() {
      if (this.$refs.top_folders_header) {
        this.containerWidth = this.$refs.top_folders_header.clientWidth;
      }
    },
    scrollNext() {
      this.currentScroll++;
    },
    scrollPrev() {
      if (this.currentScroll > 0) {
        this.currentScroll--;
      }
    }
  },
  watch: {
    currentScroll(value) {
      this.currentScrollPx = this.folderWidth * value;
      this.$refs.scrollbar_ref.setScrollLeft(this.folderWidth * value);
    },
    currentScrollPx(value) {
      if (value > this.maxScrollPx && !this.loadingEmitted) {
        this.$emit('load');
        this.loadingEmitted = true;
        setTimeout(() => {
          this.loadingEmitted = false;
        }, 3000);
      }
    }
  }
}
</script>
