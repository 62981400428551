<template>
  <div>
    <search-filter v-if="ACTIVE_FILTERS.search_filter"></search-filter>
    <types-filter v-if="ACTIVE_FILTERS.types_filter"></types-filter>
    <promos-filter v-if="ACTIVE_FILTERS.promos_filter"></promos-filter>
    <categories-filter v-if="ACTIVE_FILTERS.categories_filter"></categories-filter>
    <webshops-filter v-if="ACTIVE_FILTERS.webshops_filter"></webshops-filter>
    <stores-filter v-if="ACTIVE_FILTERS.stores_filter"></stores-filter>
    <brands-filter v-if="ACTIVE_FILTERS.brands_filter"></brands-filter>
    <price-filter v-if="ACTIVE_FILTERS.price_filter"></price-filter>

    <el-divider class="bold-divider my-3" v-if="xsOnly"></el-divider>
  </div>
</template>

<script>
import PriceFilter from "@/components/left_bar/filters/PriceFilter";
import BrandsFilter from "@/components/left_bar/filters/BrandsFilter";
import StoresFilter from "@/components/left_bar/filters/StoresFilter";
import WebshopsFilter from "@/components/left_bar/filters/WebshopsFilter";
import CategoriesFilter from "@/components/left_bar/filters/CategoriesFilter";
import PromosFilter from "@/components/left_bar/filters/PromosFilter";
import TypesFilter from "@/components/left_bar/filters/TypesFilter";
import SearchFilter from "@/components/left_bar/filters/SearchFilter";
import {mapGetters} from "vuex";

export default {
  name: "filters-list",
  components: {
    PriceFilter,
    BrandsFilter,
    StoresFilter,
    WebshopsFilter,
    CategoriesFilter,
    PromosFilter,
    TypesFilter,
    SearchFilter
  },
  computed: {
    ...mapGetters('filter', [
      'ACTIVE_FILTERS',
    ])
  },
}
</script>
