<template>
  <el-divider class="bold-divider my-3"></el-divider>
  <div class="filter-container folders-stores-filter">
    <div class="filter-header" @click="filterActive = !filterActive">
      <i :class="filterActive ? 'icon-minus' : 'icon-plus-11'"></i>
      <span class="cursor-pointer" v-t="'folder.interested_stores'"></span>
    </div>
    <div class="filter-options mt-3" v-if="filterActive" ref="stores_container">
      <div class="d-flex justify-content-center align-items-center flex-wrap">
        <template v-for="(store, i) in storesList" :key="i">
          <div class="store-image-item">
            <router-localized-link :to="{name: 'folders.store', params: {storeSlug: String(store.translation.route)}}">
              <img :src="store.image_url" alt="" :title="store.translation.title">
            </router-localized-link>
          </div>
        </template>
      </div>

      <div class="mt-2">
        <span class="text-danger cursor-pointer"
              v-text="shortFilters ? $t('filters.show_more') : $t('filters.show_less')"
              @click="shortFilters = !shortFilters"></span>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import RouterLocalizedLink from "@/components/common/RouterLocalizedLink";

export default {
  name: "folders-stores",
  components: {RouterLocalizedLink},
  data() {
    return {
      shortFilters: true,
      shortLength: 6,
      filterActive: true,
    }
  },
  computed: {
    ...mapGetters('store', [
      'STORES',
    ]),

    storesList() {
      let result = _.filter(this.STORES, (need) => need.is_paid_client);
      result = _.sortBy(result, 'folders_count').reverse();
      if (this.shortFilters) {
        return _.take(result, this.shortLength);
      }
      return result;
    },
  },
  mounted() {
    //
  },
  methods: {
    //
  }
}
</script>
