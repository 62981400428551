// plugins
import "@/plugins/cookies"
import "@/plugins/lodash"
import "@/plugins/axios"
import installElementPlus from '@/plugins/element'
import installRouter from '@/plugins/router'
import vue3GoogleLogin from 'vue3-google-login'
import InfiniteLoading  from 'v3-infinite-loading'

// modules
import VueAxios from 'vue-axios'
import {VueReCaptcha} from 'vue-recaptcha-v3'
import VueClipboard from 'vue3-clipboard'
import mitt from 'mitt'

// app
import {createApp} from 'vue'
import {createHead} from "@vueuse/head"

// vuex
import store from '@/store'

// mixins
import {display} from "@/mixins/display"
import {routing} from "@/mixins/routing"

// scss
import '@/assets/sass/main.scss'

// components
import App from '@/components/App.vue';
import MinApp from '@/components/MinApp.vue';
import SpanLink from "@/components/feed/post/SpanLink";
import MainHeader from "@/components/header/MainHeader";
import Bookmarks from "@/components/left_bar/Bookmarks";
import Tabs from "@/components/feed/Tabs";
import CommentForm from "@/components/feed/comment/CommentForm";
import LeftBar from "@/components/left_bar/LeftBar";
import RightBar from "@/components/right_bar/RightBar";
import FacebookAvatar from "@/components/feed/comment/FacebookAvatar";
import AdSense from '@/components/feed/news/AdSense';
import SingleAdvertiser from "@/components/feed/news/SingleAdvertiser";
import InitSocialRegister from "@/components/header/auth/InitSocialRegister";

localStorage.setItem('VueAppLanguage', cookies.get('locale') || process.env.VUE_APP_FALLBACK_LOCALE);

const app = createApp({});
const emitter = mitt()
const head = createHead()
app.config.globalProperties.emitter = emitter;
installElementPlus(app)
installRouter(app)
app // seo
    .use(head)
    // vuex
    .use(store)
    // uses
    .use(VueReCaptcha, {siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHAV3_SITEKEY})
    .use(vue3GoogleLogin, {clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID})
    .use(VueClipboard, {
        autoSetContainer: true,
        appendToBody: true,
    })
    .use(VueAxios, axios)
    // mixins
    .mixin(display)
    .mixin(routing)
    .component('app', App)
    .component('min-app', MinApp)
    .component('main-header', MainHeader)
    .component('left-bar', LeftBar)
    .component('right-bar', RightBar)
    .component('bookmarks', Bookmarks)
    .component('tabs', Tabs)
    .component('span-link', SpanLink)
    .component('comment-form', CommentForm)
    .component('facebook-avatar', FacebookAvatar)
    .component('ad-sense', AdSense)
    .component('single-advertiser', SingleAdvertiser)
    .component('init-social-register', InitSocialRegister)
    .component('infinite-loading', InfiniteLoading )
    // mount
    .mount('#app')
