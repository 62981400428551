<template>
  <el-dialog
      :title="$t('prizes.collected_coins')"
      v-model="show"
      :show-close="false"
      :close-on-click-modal="true"
      :close-on-press-escape="true"
      :destroy-on-close="true"
      :lock-scroll="false"
      width="100vw"
      class="transactions-dialog danger-header-dialog"
      @close="closeDialog"
      center>

    <el-table :data="tableData" border class="w-100">
      <el-table-column prop="date" :label="$t('prizes.transactions_table_headers.date')" width="100"/>
      <el-table-column prop="points" :label="$t('prizes.transactions_table_headers.total')" width="75"/>
      <el-table-column prop="info" :label="$t('prizes.transactions_table_headers.info')"/>
    </el-table>

    <el-pagination layout="prev, pager, next"
                   :page-count="TRANSACTIONS_PAGES_TOTAL"
                   :pager-count="5"
                   @current-change="pageChanged"/>

    <el-button type="primary"
               class="w-100"
               @click="closeDialog">
      <span v-t="'prizes.back_to_prizes'"></span>
    </el-button>

    <template v-slot:header>
      <div>
        <i class="icon-arrow_back float-start cursor-pointer" @click="closeDialog"></i>
        <span v-t="'prizes.collected_coins'"></span>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "bonus-transactions-dialog",
  props: ['showDialog'],
  data() {
    return {
      show: this.showDialog,
      currentPage: 1,
      perPage: 5,
    }
  },
  mounted() {
    this.updateTransactions();
  },
  computed: {
    ...mapGetters('transaction', [
      'TRANSACTIONS',
      'TRANSACTIONS_PAGES_TOTAL'
    ]),

    tableData() {
      return _.map(this.TRANSACTIONS, function (transaction) {
        let d = new Date(transaction.created_at.timestamp * 1000),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        day = ('0' + day).slice(-2);
        month = ('0' + month).slice(-2);
        return {
          points: transaction.amount,
          date: [day, month, year].join('/'),
          info: transaction.description
        };
      });
    }
  },
  methods: {
    ...mapActions('transaction', [
      'FETCH_TRANSACTIONS'
    ]),
    updateTransactions() {
      this.FETCH_TRANSACTIONS({
        per_page: this.perPage,
        page: this.currentPage,
      });
    },
    closeDialog() {
      this.$emit('update:show-dialog', false);
    },
    pageChanged(newPage) {
      this.currentPage = newPage;
      this.updateTransactions();
    }
  },
  watch: {
    showDialog(newShowDialog) {
      this.show = newShowDialog;
    }
  },
}
</script>
