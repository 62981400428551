<template>
  <div class="discount-code-preview">
    <div class="discount-code-header" ref="discount_code_header">
      <div class="discount-code-actions">
        <div class="discount-code-favourite">
          <div style="position: relative">
            <i class="cursor-pointer"
               :class="{'active icon-heart-2':post.is_favourite, 'icon-heart':!post.is_favourite}"
               @click="processFavourite"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="discount-code-content">

      <div class="position-relative">
        <div class="discount-code-image-container">
          <img v-if="imageLink" :src="imageLink"
               class="discount-code-image"
               :style="`max-width:`+imageWidth+`px`">
          <img v-else src="@/assets/images/promo_code.jpg"
               class="discount-code-image"
               :style="`max-width:`+imageWidth+`px`">
        </div>

        <hit :container-width="imageWidth"
                       :top="targetTop"
                       :hits-count="hitsCount"
                       :is-like="post.is_hit"
                       :is-dislike="post.is_dislike"
                       @hit="processHit"
                       size="small"></hit>
      </div>

      <div class="mt-2">
        <div class="store-title font-weight-bold w-100">
          <span v-if="post.webshop">
            {{post.webshop.translation.title}}
          </span>
          <span v-else-if="post.webshops.length">
            {{post.webshops[0].translation.title}}
          </span>
          <span v-else></span>
        </div>

        <div class="discount-text w-100">
          <el-popover
              placement="bottom-start"
              :width="imageWidth"
              trigger="hover">
            <dynamic-content :content="post.content"></dynamic-content>
            <template #reference>
              <div class="text-truncate overflow-hidden">
                <dynamic-content :content="post.content"></dynamic-content>
              </div>
            </template>
          </el-popover>
        </div>

        <div class="expire-text w-100">
          <div class="float-start text-secondary font-size-14">{{ post.valid_text }}</div>
        </div>
      </div>
    </div>
    <div class="discount-code-footer">
      <div class="discount-code-price">
        <el-button type="primary"
                   class="w-100"
                   @click="copyDiscountCode(this.post)">
          <div class="d-flex justify-content-center align-items-center">
            <span v-t="'post.discount_btn'"></span> <i class="icon-arrow-3"></i>
          </div>
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Hit from "@/components/feed/Hit";
import {needAuth} from "@/mixins/auth/needAuth";
import DynamicContent from "@/components/feed/post/DynamicContent";
import {copyDiscountCode} from "@/mixins/post/copyDiscountCode";
import {randomHits} from "@/mixins/post/randomHits";

export default {
  name: "discount-code",
  components: {DynamicContent, Hit},
  mixins: [needAuth, copyDiscountCode, randomHits],
  props: {
    post: Object,
    vuexModule: {
      type: String,
      default: 'promo',
    },
  },
  data() {
    return {
      imageWidth: 0,
    }
  },
  computed: {
    targetTop() {
      return this.xsOnly ? 150 : 180;
    },
    imageLink() {
      if (this.post.images.length) {
        return this.post.images[0].src;
      }
      if (this.post.webshop) {
        return this.post.webshop.image_url;
      }
      return null;
    }
  },
  mounted() {
    let thisElem = this;
    new ResizeObserver(function () {
      thisElem.calculateHeight();
    }).observe(this.$refs.discount_code_header);
  },
  methods: {
    calculateHeight() {
      let thisElem = this;

      if (!thisElem.$refs.discount_code_header) {
        return 0;
      }
      thisElem.imageWidth = thisElem.$refs.discount_code_header.clientWidth - 20;
    },
    processHit(value) {
      if (this.triggerLoginDialog()) {
        return;
      }
      let data = {
        morphable_type: 'Post',
        morphable_id: this.post.id,
      };
      if (value) {
        this.$store.dispatch(this.vuexModule + '/SET_HIT', data);
      } else {
        this.$store.dispatch(this.vuexModule + '/SET_DISLIKE', data);
      }
    },
    processFavourite() {
      if (this.triggerLoginDialog()) {
        return;
      }
      let data = {
        morphable_type: 'Post',
        morphable_id: this.post.id,
      };
      if (this.post.is_favourite) {
        this.$store.dispatch(this.vuexModule + '/REMOVE_FAVORITE', data);
      } else {
        this.$store.dispatch(this.vuexModule + '/SET_FAVORITE', data);
      }
    }
  }
}
</script>
